//
//  HEIGHT
//
.h {
  &--0 {
    height: 0% !important;
  }
  &--10 {
    height: 10% !important;
  }
  &--20 {
    height: 20% !important;
  }
  &--30 {
    height: 30% !important;
  }
  &--40 {
    height: 40% !important;
  }
  &--50 {
    height: 50% !important;
  }
  &--60 {
    height: 60% !important;
  }
  &--70 {
    height: 70% !important;
  }
  &--80 {
    height: 80% !important;
  }
  &--90 {
    height: 90% !important;
  }
  &--100 {
    height: 100% !important;
  }

  &--1-8 {
    height: 12.5% !important;
  }
  &--1-4 {
    height: 25% !important;
  }
  &--1-3 {
    height: 33.333% !important;
  }
  &--1-2 {
    height: 50% !important;
  }
  &--2-3 {
    height: 66.666% !important;
  }
  &--3-4 {
    height: 75% !important;
  }
}

// Viewport Height
.vh {
  &--0 {
    height: 0vh !important;
  }
  &--10 {
    height: 10vh !important;
  }
  &--20 {
    height: 20vh !important;
  }
  &--30 {
    height: 30vh !important;
  }
  &--40 {
    height: 40vh !important;
  }
  &--50 {
    height: 50vh !important;
  }
  &--60 {
    height: 60vh !important;
  }
  &--70 {
    height: 70vh !important;
  }
  &--80 {
    height: 80vh !important;
  }
  &--90 {
    height: 90vh !important;
  }
  &--100 {
    height: 100vh !important;
  }

  &--1-8 {
    height: 12.5vh !important;
  }
  &--1-4 {
    height: 25vh !important;
  }
  &--1-3 {
    height: 33.333vh !important;
  }
  &--1-2 {
    height: 50vh !important;
  }
  &--2-3 {
    height: 66.666vh !important;
  }
  &--3-4 {
    height: 75vh !important;
  }
}
