//
//  z-index
//
/*

  usage
  -----
  .modal {
    z-index: z-index(modal);
  }

*/

$zindex: (
  heaven: 9369,
  cooltip: 9003,
  // FR > refuck?
  modal: 9000,
  overlay: 8000,
  dropdown: 7000,
  header: 6000,
  footer: 5000,
);

////////////////////////////////////
//
//  Typography
//
////////////////////////////////////

//
//  Font family
//
$ff--default: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
$ff--yummo: 'direct_yummosemibold', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
$ff--calibri: Calibri, sans-serif;
$ff--inter: 'Inter', sans-serif;
$ff--monospace: Courier, monospace !important;
$ff--arial: Arial, sans-serif;
$ff--icons: 'direct-core-icons' !important;
$ff--icons--fontAwesome: 'FontAwesome' !important; // FR > Font Awasome will be completeley replaced by Google material Icons

//
//  font-size
//
$fs--1: 1rem;

//
//  headings
//
$h1: 2.5rem;
$h2: 2rem;
$h3: 1.75rem;
$h4: 1.5rem;
$h5: 1.25rem;
$h6: 1rem;

//
//  line-height
//
$lh--small: 1.2;
$lh--medium: 1.4;
$lh--large: 1.6;

//
//  status icons (Font-awesome)
//
$status-icon--progress: '\f017'; // fa-clock-o
$status-icon--on-hold: '\f256'; // fa-hand-paper-o
$status-icon--error: '\f00d'; // fa-times
$status-icon--error: '\f071'; // fa-exclamation-triangle
$status-icon--success: '\f00c'; // fa-check

//
//  A N I M A T I O N  variables
//
$speed: 150ms; // default;
$speed--slow: 300ms;
$speed--normal: $speed;
$speed--fast: 100ms;

$effect: 'ease';
// FR > add more effects

//
//  Breakpoints
//
$breakpoint__xs: 320px;
$breakpoint__sm: 768px;
$breakpoint__md: 1024px;
$breakpoint__lg: 1170px;
$breakpoint__xl: 1366px;
$breakpoint__xxl: 1600px;
$breakpoint__half-screen: 960px;
$breakpoint__indigo: 320px; // FR > remove
//
//  Min
//
$breakpoint__xs--min: $breakpoint__xs - 1;
$breakpoint__sm--min: $breakpoint__sm - 1;
$breakpoint__md--min: $breakpoint__md - 1;
$breakpoint__lg--min: $breakpoint__lg - 1;
$breakpoint__xl--min: $breakpoint__xl - 1;
$breakpoint__xxl--min: $breakpoint__xxl - 1;
$breakpoint__half-screen--min: $breakpoint__half-screen - 1;

//
//  Containers
//
$container--padding-left: _space(1);
$container--padding-right: _space(1);

//
//  Width
//
$width--mobile: calc(#{$breakpoint__xs} - #{$container--padding-left} - #{$container--padding-right});

//
//  Border radius
//
$radius: _space(0.25);

$radius--sm: _space(0.125);
$radius--md: _space(0.25);
$radius--lg: _space(0.5);
$radius--xl: _space(1);

////////////////////////////////////
//
//  F O R M
//
////////////////////////////////////
$form__fieldset--margin: _space(2);
$form__row--margin: _space(0.5);
$form__group--margin: $form__row--margin * 2;

$form__legend--margin: $form__group--margin;
$form__legend__border--width: 2px;

$form__element--max-width: $width--mobile;
$form__label-input--margin: _space(0.25);
$form__checkbox--width: 16px;

//
//  Spinner
//
$ui-spinner--size: 12px;
$ui-spinner--margin: _space(0.25);
$ui-spinner__login-page--size: 16px;

//
//  Process tree
//
$dc-process__step--padding: _space(1.5);
$dc-process__date--width: 130px;
$dc-process__ball--width: _space(2);
$dc-process__ball--width-half: (#{$dc-process__ball--width} / 2);
$dc-process__line--width: 3px;

////////////////////////////////////
//
//  ⚜ ⚜ ⚜  PAGES  ⚜ ⚜ ⚜
//
////////////////////////////////////

//
//  ☑ ☑ ☑  Tasks  ☑ ☑ ☑
//
// FR > $ui-tasks__min-height - use it as a main $variable for $content__height--min;
// DEV NOTE: VARIABLES TEMPORARY MOVED TO APP.SCSS

//
//  ❶ ❶ ❶  Partner  ❶ ❶ ❶
//
// Manual Communication
$temp__87687695__input-width--A: 209px;
$temp__87687695__input-width--C: 80px;

//
//  Skeleton loader
//
$dc-skeleton__height: 120px;
