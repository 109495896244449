/*
---
name: $_base-font-size-px
category:
  - core/variable
  - core/variable/typography
---
Default font size

* If html is `font-size: 62.5%`, set `10px`
* If you change $_base-font-size-px, you must also change $_base-font-size.

### scss
```scss
$_base-font-size-px: 16px;
```
*/

$_base-font-size-px: 16px !default;

/*
---
name: $_half-leading
category:
  - core/variable
  - core/variable/typography
---
Between the character and line of line-height

### scss
```scss
$_half-leading: .4;
```
*/

$_between-lines: _px2rem($_base-font-size-px) / 3 !default;
$_half-leading: _strip-unit($_between-lines) !default;

/*
---
name: $_base-line-height
category:
  - core/variable
  - core/variable/typography
---
Default line height rem

### scss
```scss
$_base-line-height: 1 + $_half-leading * 2rem;
```
*/

$_base-line-height: 1 + $_half-leading * 2rem;

/*
---
name: $_margin-coefficient
category:
  - core/variable
  - core/variable/typography
---
Margin coefficient

### scss
```scss
$_margin-coefficient: 1;
```
*/

$_margin-coefficient: 1 !default;

/*
---
name: $_base-margin-bottom
category:
  - core/variable
  - core/variable/typography
---
HTML element margin coefficient

### scss
```scss
$_base-margin-bottom: 1
```
*/

$_base-margin-bottom: 1 !default;

/*
---
name: $_h1-font-size-scale
category:
  - core/variable
  - core/variable/typography
---
`h1` font size scale

### scss
```scss
$_h1-font-size-scale: 2.5;
```
*/

$_h1-font-size-scale: 2.5 !default;

/*
---
name: $_h2-font-size-scale
category:
  - core/variable
  - core/variable/typography
---
`h2` font size scale

### scss
```scss
$_h2-font-size-scale: 2;
```
*/

$_h2-font-size-scale: 2 !default;

/*
---
name: $_h3-font-size-scale
category:
  - core/variable
  - core/variable/typography
---
`h3` font size scale

### scss
```scss
$_h3-font-size-scale: 1.5;
```
*/

$_h3-font-size-scale: 1.5 !default;

/*
---
name: $_h4-font-size-scale
category:
  - core/variable
  - core/variable/typography
---
`h4` font size scale

### scss
```scss
$_h4-font-size-scale: 1.25;
```
*/

$_h4-font-size-scale: 1.25 !default;

/*
---
name: $_h5-font-size-scale
category:
  - core/variable/typography
---
`h5` font size scale

### scss
```scss
$_h5-font-size-scale: 1;
```
*/

$_h5-font-size-scale: 1 !default;

/*
---
name: $_h6-font-size-scale
category:
  - core/variable
  - core/variable/typography
---
`h6` font size scale

### scss
```scss
$_h6-font-size-scale: 1;
```
*/

$_h6-font-size-scale: 1 !default;

/*
---
name: $_h1-margin-top
category:
  - core/variable
  - core/variable/typography
---
`h1` margin top coefficient

### scss
```scss
$_h1-margin-top: 0;
```
*/

$_h1-margin-top: 0 !default;

/*
---
name: $_h1-margin-bottom
category:
  - core/variable
  - core/variable/typography
---
`h1` margin bottom coefficient

### scss
```scss
$_h1-margin-bottom: $_base-margin-bottom;
```
*/

$_h1-margin-bottom: $_base-margin-bottom !default;

/*
---
name: $_h2-margin-top
category:
  - core/variable
  - core/variable/typography
---
`h2` margin top coefficient

### scss
```scss
$_h2-margin-top: $_base-margin-bottom * 2;
```
*/

$_h2-margin-top: $_base-margin-bottom * 2 !default;

/*
---
name: $_h2-margin-bottom
category:
  - core/variable
  - core/variable/typography
---
`h2` margin bottom coefficient

### scss
```scss
$_h2-margin-bottom: $_base-margin-bottom;
```
*/

$_h2-margin-bottom: $_base-margin-bottom !default;

/*
---
name: $_h3-margin-top
category:
  - core/variable
  - core/variable/typography
---
`h3` margin top coefficient

### scss
```scss
$_h3-margin-top: $_base-margin-bottom * 1.5;
```
*/

$_h3-margin-top: $_base-margin-bottom * 1.5 !default;

/*
---
name: $_h3-margin-bottom
category:
  - core/variable
  - core/variable/typography
---
`h3` margin bottom coefficient

### scss
```scss
$_h3-margin-bottom: $_base-margin-bottom;
```
*/

$_h3-margin-bottom: $_base-margin-bottom !default;

/*
---
name: $_h4-margin-top
category:
  - core/variable
  - core/variable/typography
---
`h4` margin top coefficient

### scss
```scss
$_h4-margin-top: $_base-margin-bottom * 1.25;
```
*/

$_h4-margin-top: $_base-margin-bottom * 1.25 !default;

/*
---
name: $_h4-margin-bottom
category:
  - core/variable
  - core/variable/typography
---
`h4` margin bottom coefficient

### scss
```scss
$_h4-margin-bottom: $_base-margin-bottom;
```
*/

$_h4-margin-bottom: $_base-margin-bottom !default;

/*
---
name: $_h5-margin-top
category:
  - core/variable
  - core/variable/typography
---
`h5` margin top coefficient

### scss
```scss
$_h5-margin-top: $_base-margin-bottom;
```
*/

$_h5-margin-top: $_base-margin-bottom !default;

/*
---
name: $_h5-margin-bottom
category:
  - core/variable
  - core/variable/typography
---
`h5` margin bottom coefficient

### scss
```scss
$_h5-margin-bottom: $_base-margin-bottom * .5;
```
*/

$_h5-margin-bottom: $_base-margin-bottom * .5 !default;

/*
---
name: $_h6-margin-top
category:
  - core/variable
  - core/variable/typography
---
`h6` margin top coefficient

### scss
```scss
$_h6-margin-top: $_base-margin-bottom;
```
*/

$_h6-margin-top: $_base-margin-bottom !default;

/*
---
name: $_h6-margin-bottom
category:
  - core/variable
  - core/variable/typography
---
`h6` margin bottom coefficient

### scss
```scss
$_h6-margin-bottom: $_base-margin-bottom * .5;
```
*/

$_h6-margin-bottom: $_base-margin-bottom * .5 !default;

/*
---
name: $_font-family
category:
  - core/variable
  - core/variable/typography
---
Default font family

### scss
```scss
$_font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
```
*/

$_font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
