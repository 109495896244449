.c-section {
  @include _padding(3, 0);

  &--bg {
    background-color: $temp__sedivka-9;
    border-top: 1px solid $temp__sedivka-7;
    border-bottom: 1px solid $temp__sedivka-7;
  }

  &__title {
    @include _font-size-line-height($_base-font-size * 2);
    @include _margin(0, 0, 1);
    font-weight: normal;
    text-align: center;

    &--paragraph {
      @include _font-size-line-height($_base-font-size * 1.5);
      text-align: left;
      margin-bottom: _space(0.25);
    }
  }

  //
  //  Title
  //
  &__title--page {
    @include _margin(0, 0, 1);
    @include _font-size-line-height(2rem);
    text-align: left;
  }

  &__title--section {
    @include _margin(0, 0, 1);
    @include _font-size-line-height(2rem);
  }

  &__title--column {
    @include _margin(0.5, 0, 0.5);
    @include _font-size-line-height(1.8rem);
  }

  &__title--chapter {
    @include _margin(0, 0, 1);
    @include _font-size-line-height(2rem);
    text-align: left;
  }

  //
  //  Headings
  //
  .c-section__h1 {
    @include _margin(0, 0, 1.5);
    @include _font-size-line-height(2rem);
  }

  .c-section__h2 {
    @include _margin(1.5, 0, 1);
    @include _font-size-line-height(1.5rem);
  }

  .c-section__h3 {
    @include _margin(1.5, 0, 1);
    @include _font-size-line-height(1.25rem);
  }

  //
  //  Style - Bordered
  //
  &--border {
    border-bottom: 1px solid _lighter($temp__sedivka-11);
    @include _padding(2, 0);
  }
}
