// > create color variant @mixin ( .dotted--grey-1 -- &.dotted--grey-5)
.divider {
  border: 0;

  width: 100%;
  height: 1px;
  line-height: 1;

  margin-top: _space(1);
  margin-bottom: _space(1);

  background: rgba($black, 0.125);

  // size
  &.mini {
    margin-top: _space(0.5);
    margin-bottom: _space(0.5);
  }
  &.maxi {
    margin-top: _space(2);
    margin-bottom: _space(2);
  }

  // FR > rename ( mini > sm  |   medium > md   |   maxi > lg )
  &.sm {
    margin-top: _space(0.5);
    margin-bottom: _space(0.5);
  }
  &.md {
    margin-top: _space(1);
    margin-bottom: _space(1);
  }
  &.lg {
    margin-top: _space(1.5);
    margin-bottom: _space(1.5);
  }

  //  color variants
  //
  &.--info {
    background: $info;
  }
  &.--warning {
    background: $warning;
  }
  &.--danger {
    background: $danger;
  }
  &.--success {
    background: $success;
  }

  // usage: on blocks with background color = $white
  &.dotted {
    background-image: linear-gradient(
      90deg,
      rgba($black, 0.5) 10%,
      $white 10%,
      $white 50%,
      rgba($black, 0.5) 50%,
      rgba($black, 0.5) 60%,
      $white 60%,
      $white 100%
    );
    background-size: 10px 10px;
  }

  // usage: on blocks with background color = $grey-1
  &.dotted--grey-1 {
    background-image: linear-gradient(
      90deg,
      rgba($black, 0.5) 10%,
      $grey-1 10%,
      $grey-1 50%,
      rgba($black, 0.5) 50%,
      rgba($black, 0.5) 60%,
      $grey-1 60%,
      $grey-1 100%
    );
    background-size: 10px 10px;
  }

  // usage: on blocks with background color = $grey-2
  &.dotted--grey-2 {
    background-image: linear-gradient(
      90deg,
      rgba($black, 0.5) 10%,
      $grey-2 10%,
      $grey-2 50%,
      rgba($black, 0.5) 50%,
      rgba($black, 0.5) 60%,
      $grey-2 60%,
      $grey-2 100%
    );
    background-size: 10px 10px;
  }

  // usage: on blocks with background color = $grey-2
  &.dotted--grey-3 {
    background-image: linear-gradient(
      90deg,
      rgba($black, 0.5) 10%,
      $grey-3 10%,
      $grey-3 50%,
      rgba($black, 0.5) 50%,
      rgba($black, 0.5) 60%,
      $grey-3 60%,
      $grey-3 100%
    );
    background-size: 10px 10px;
  }

  // usage: background color = $milk
  &.dotted--milk {
    background-image: linear-gradient(
      90deg,
      rgba($black, 0.125) 10%,
      $milk 10%,
      $milk 50%,
      rgba($black, 0.125) 50%,
      rgba($black, 0.125) 60%,
      $milk 60%,
      $milk 100%
    );
    background-size: 10px 10px;
  }

  // usage: background color = $concrete
  &.dotted--concrete {
    background-image: linear-gradient(
      90deg,
      rgba($black, 0.125) 10%,
      $concrete 10%,
      $concrete 50%,
      rgba($black, 0.125) 50%,
      rgba($black, 0.125) 60%,
      $concrete 60%,
      $concrete 100%
    );
    background-size: 10px 10px;
  }

  // theme
  //&.white { background:     rgba($white, .25); }
}

.divider {
  &--vertical {
    display: inline-block;
    width: 1px;
    height: 60%;
    align-self: center;
    @include _margin(0, 1);

    //background-color: lighten($core, 15%);
    background-color: rgba($white, 0.25);
  }
}
