/*
---
name: .u-visible
category:
  - object/utility
  - object/utility/visible
  - object/utility/visible/visible
---
Visible utility

### html
```html
<div class="u-visible">
  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
</div>
```
```html
<div class="u-hidden u-visible-sm">
  u-hidden u-visible-sm
</div>
```
```html
<div class="u-hidden u-visible-md">
  u-hidden u-visible-md
</div>
```
```html
<div class="u-hidden u-visible-md-up">
  u-hidden u-visible-md-up
</div>
```
```html
<div class="u-hidden u-visible-lg">
  u-hidden u-visible-lg
</div>
```
```html
<div class="u-hidden u-visible-lg-up">
  u-hidden u-visible-lg-up
</div>
```
```html
<div class="u-hidden u-visible-xl">
  u-hidden u-visible-xl
</div>
```
*/

.#{$_prefix}u-visible {
  @include _visible();
}

$sizes-keys: map-keys($_sizes);
$min-key: nth($sizes-keys, 1);
$max-key: nth($sizes-keys, -1);
@each $size, $screen-min in $_sizes {
  @if $size != $min-key and $size != $max-key {
    .#{$_prefix}u-visible-#{$size}-up {
      @include _media-min($size) {
        @include _visible();
      }
    }
  }

  .#{$_prefix}u-visible-#{$size} {
    @include _media-only($size) {
      @include _visible();
    }
  }
}
