/*
---
name: .u-content
category:
  - object/utility
  - object/utility/content
---
Content utility

### html
```html
<div class="u-content">
  <h1>Heading 1</h1>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
  </p>
  <h2>Heading 2</h2>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
  </p>
  <h3>Heading 3</h3>
  <ul>
    <li>Lorem ipsum dolor sit amet</li>
    <li>consectetur adipiscing elit</li>
    <li>Nulla accumsan</li>
    <li>metus ultrices eleifend gravida</li>
  </ul>
  <ol>
    <li>Lorem ipsum dolor sit amet</li>
    <li>consectetur adipiscing elit</li>
    <li>Nulla accumsan</li>
    <li>metus ultrices eleifend gravida</li>
  </ol>
  <dl>
    <dt>Lorem ipsum dolor sit amet</dt>
    <dd>consectetur adipiscing elit</dd>
    <dt>Nulla accumsan</dt>
    <dd>metus ultrices eleifend gravida</dd>
  </dl>
  <h4>Heading 4</h4>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
  </p>
  <h5>Heading 5</h5>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
  </p>
  <h6>Heading 6</h6>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
  </p>
</div>
```
*/

.#{$_prefix}u-content {
  @include _content();
}
