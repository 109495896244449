// FR > refuck

//
//  mock skeleton
//
.mock__skeleton {
  display: block;
  background: $grey-2;
  border-radius: $radius--sm;
}

//
//  ui-skeleton-legacy
//
ui-skeleton-legacy {
  &.push--auto {
    > div {
      @extend .push--auto;
    }
  }

  div:empty {
    max-width: 500px;
    height: 120px;
    overflow: hidden;

    background-image: linear-gradient($grey-4 _space(0.75), transparent 0),
      linear-gradient($grey-2 _space(0.75), transparent 0), linear-gradient($grey-2 _space(0.75), transparent 0),
      linear-gradient($grey-2 _space(0.75), transparent 0);
    background-repeat: no-repeat;
    background-repeat: repeat-y;

    background-size:
      40% $dc-skeleton__height,
      // 150px $dc-skeleton__height
      100% $dc-skeleton__height,
      // 350px $dc-skeleton__height
      80% $dc-skeleton__height,
      // 300px $dc-skeleton__height
      70% $dc-skeleton__height; // 250px $dc-skeleton__height

    background-position:
      0 0,
      0 _space(1),
      0 _space(2),
      0 _space(3);
  }
}

//
//  skeleton-loader
//
.skeleton-loader {
  // inherited from .dc-contact-history__list-item
  margin-left: _space(-0.5);
  margin-bottom: _space(0.5);
  padding: _space(0.5);

  background: $concrete;
  border-radius: $radius--md;

  cursor: wait;

  clear: both;

  &__animated-background {
    position: relative;
    min-height: 15px;
    height: 90px;

    background-color: darken($white, 10%);
    background: linear-gradient(to right, darken($white, 10%) 8%, darken($white, 15%) 18%, darken($white, 10%) 33%);
    border-radius: $radius--sm;

    animation-name: animation--skeleton-loader;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    -webkit-background-size: 800px 104px;
  }

  .gap {
    position: absolute;
    background: $concrete; // same background as card background

    &-01 {
      top: 0;
      left: 15px;
      width: 5px;
      height: 15px;
    }
    &-02 {
      top: 0;
      left: 35px;
      width: 130px;
      height: 15px;
    }
    &-03 {
      top: 20px;
      right: 0;
      width: 50%;
      height: 15px;
    }
    &-04 {
      bottom: 0;
      right: 0;
      width: 70%;
      height: 15px;
    }

    &__row {
      width: 100%;
      height: 5px;

      &-01 {
        top: 15px;
      }
      &-02 {
        top: 35px;
        height: 10px;
      }
      &-03 {
        top: 45px;
        height: 10px;
      }
      &-04 {
        top: 70px;
        height: 5px;
      }
    }
  }
}

// Přehled smluv a pojistných událostí
dc-partner-overview,
dc-partner-detail,
dc-skeleton-loader {
  .skeleton-loader {
    &__row {
      margin-bottom: _space(0.75);
      padding-bottom: _space(0.75);

      border-bottom: 1px solid rgba($black, 0.1);
    }

    &__icon {
      width: 20px;
      height: 20px;
      background: darken($white, 15%);
      display: inline-block;
      border-radius: 50%;
    }

    &__animated-background {
      float: right;
      width: calc(50% - 30px);
      height: 18px;
      top: 1px;
      margin-right: 50%;
    }

    // randomize element's width
    &__row {
      &:nth-child(1) .skeleton-loader__animated-background {
        width: calc(50% - 30px);
        margin-right: 50%;
      }
      &:nth-child(2) .skeleton-loader__animated-background {
        width: calc(45% - 30px);
        margin-right: 55%;
      }
      &:nth-child(3) .skeleton-loader__animated-background {
        width: calc(40% - 30px);
        margin-right: 60%;
      }
      &:nth-child(4) .skeleton-loader__animated-background {
        width: calc(50% - 30px);
        margin-right: 50%;
      }
      &:nth-child(5) .skeleton-loader__animated-background {
        width: calc(50% - 30px);
        margin-right: 50%;
      }
      &:nth-child(6) .skeleton-loader__animated-background {
        width: calc(45% - 30px);
        margin-right: 55%;
      }
    }
  }
}

// Detail partnera ~ LEFT column
dc-partner-detail {
  .skeleton-loader {
    background: transparent;

    &__row {
      border-bottom: 0;
      margin-bottom: _space(0.5);
      padding-bottom: 0;

      &:nth-child(1) {
        margin-bottom: _space(1);
        .skeleton-loader__animated-background {
          width: 50%;
          margin-right: 40%;
        }
      }
      &:nth-child(2) .skeleton-loader__animated-background {
        width: 50%;
        margin-right: 0;
        float: none;
      }
      &:nth-child(3) .skeleton-loader__animated-background {
        width: 35%;
        margin-right: 0;
        float: none;
        height: 14px;
        min-height: 14px;
      }
      &:nth-child(4) {
        margin-bottom: _space(1);
        .skeleton-loader__animated-background {
          width: 45%;
          margin-right: 0;
          float: none;
          height: 14px;
          min-height: 14px;
        }
      }
      &:nth-child(5) .skeleton-loader__animated-background {
        width: 48%;
        margin-right: 0;
        float: none;
        height: 25px;
        border-radius: 15px;
      }
    }
  }
}
