/*
---
name: _media-min()
category:
  - core/mixin
  - core/mixin/media-query
---
min-width media query

### scss
```scss
//
// @param  size|length  $size  width
//

.c-foo {
  @include _media-min(md) {
    ...
  }
}
```
*/

@mixin _media-min($size) {
  $sizes-keys: map-keys($_sizes);
  $min-key: nth($sizes-keys, 1);

  @if (0 == $size or $min-key == $size or false == $size) {
    @content;
  } @else {
    @media (min-width: _min($size)) {
      @content;
    }
  }
}

/*
---
name: _media-max()
category:
  - core/mixin
  - core/mixin/media-query
---
max-width media query

### scss
```scss
//
// @param  size|length  $size  width
//

.c-foo {
  @include _media-max(md) {
    ...
  }
}
```
*/

@mixin _media-max($size) {
  $sizes-keys: map-keys($_sizes);
  $max-key: nth($sizes-keys, -1);

  @if $max-key == $size {
    @content;
  } @else {
    @media (max-width: _max($size)) {
      @content;
    }
  }
}

/*
---
name: _media-only()
category:
  - core/mixin
  - core/mixin/media-query
---
Specific size only media query

### scss
```scss
//
// @param  size|length  $size  width
//

.c-foo {
  @include _media-only(md) {
    ...
  }
}
```
*/

@mixin _media-only($size) {
  $sizes-keys: map-keys($_sizes);
  $min-key: nth($sizes-keys, 1);
  $max-key: nth($sizes-keys, -1);

  @if $min-key == $size {
    @include _media-max($size) {
      @content;
    }
  } @else if $max-key == $size {
    @include _media-min($size) {
      @content;
    }
  } @else {
    @media (min-width: _min($size)) and (max-width: _max($size)) {
      @content;
    }
  }
}
