/*
---
name: _media()
category:
  - core/abstract
---
Abstract media component

### scss
```scss
.c-media {
  @include _media();

  &__figure {
    @include _margin(0, 1, 0, 0);
  }
}
```

### html
```ejs
<div class="c-media">
  <div class="c-media__figure">
    <img src="/basis/aigis_assets/images/dummy.jpg" style="width: 150px">
  </div>
  <div class="c-media__body">
    ...
  </div>
</div>
```
*/

@mixin _media() {
  display: flex;

  &__figure {
    flex: none;
    @include _background-image-cover();

    > * {
      vertical-align: top;
      margin-top: calc(#{ _var-half-leading() } * 1rem);
    }
  }

  &__body {
    flex: 1;
  }
}
