/*
---
name: _form-control-base-padding()
category:
  - core/mixin
  - core/mixin/form
---
Sets padding for form controls

### scss
```scss
input[type="text"],
select {
  @include _form-control-base-padding();
}
```
*/

@mixin _form-control-base-padding() {
  @include _padding(.25, .5);
}

/*
---
name: _form-control-base-border()
category:
  - core/mixin
  - core/mixin/form
---
Sets border for form controls

### scss
```scss
input[type="text"],
select {
  @include _form-control-base-border();
}
```
*/

@mixin _form-control-base-border() {
  border: 1px solid #{ _var-form-control-border-color() };
  border-radius: _var-border-radius();
  outline: 0;
  @include _transition(border);

  &:hover {
    border-color: _var-form-control-border-color-hover();
  }

  &:active,
  &:focus,
  &:focus-within,
  &[aria-selected="true"] {
    border-color: _var-form-control-border-color-focus();
  }

  > input,
  > textarea {
    border: none;
    outline: none;
  }
}
