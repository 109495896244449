p-columnfilter {
  .p-column-filter-menu-button,
  .p-column-filter-menu-button.p-column-filter-menu-button-open {
    @apply bg-transparent hover:bg-transparent focus:shadow-none;
  }
}

.p-column-filter-overlay-menu {
  .p-column-filter-buttonbar {
    @apply gap-3 pt-0;

    &:empty {
      @apply p-0;
    }

    button {
      @apply max-w-[50%] grow rounded-lg bg-primary-500 hover:bg-primary-600;

      &.p-button-outlined {
        @apply border-transparent bg-transparent text-gray-600 hover:border-transparent hover:bg-neutral-100 hover:text-gray-700;
      }

      .p-button-label {
        @apply font-semibold;
      }
    }
  }

  .p-column-filter-buttons {
    @apply p-4 pt-0;
  }
}
