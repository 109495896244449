////////////////////////////////////
//
//  Typography
//
////////////////////////////////////

h1 {
  font-family: $ff--yummo;
  color: $core;
}

a {
  cursor: pointer;
  color: $action;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }
}

//
//  LIST
//

//  Definition list
//
dl {
  margin-bottom: _space(1);
}

dt {
  font-style: italic;
  font-weight: bold;
}

dd {
  margin-left: _space(1);
}

//  RESET list style
//
ul.nostyle,
ol.nostyle {
  margin-left: 0;
  list-style-type: none;
}

figcaption {
  padding-top: _space(0.25);
  font-style: italic;
  color: $antracit;
}
