//
//  WIDTH
//

// Percents
.w {
  &--0 {
    width: 0% !important;
  }
  &--10 {
    width: 10% !important;
  }
  &--20 {
    width: 20% !important;
  }
  &--30 {
    width: 30% !important;
  }
  &--40 {
    width: 40% !important;
  }
  &--50 {
    width: 50% !important;
  }
  &--60 {
    width: 60% !important;
  }
  &--70 {
    width: 70% !important;
  }
  &--80 {
    width: 80% !important;
  }
  &--90 {
    width: 90% !important;
  }
  &--100 {
    width: 100% !important;
  }

  &--1-8 {
    width: 12.5% !important;
  }
  &--1-4 {
    width: 25% !important;
  }
  &--1-3 {
    width: 33.333% !important;
  }
  &--1-2 {
    width: 50% !important;
  }
  &--2-3 {
    width: 66.666% !important;
  }
  &--3-4 {
    width: 75% !important;
  }
}

// Viewport Width
.vw {
  &--0 {
    width: 0vw !important;
  }
  &--10 {
    width: 10vw !important;
  }
  &--20 {
    width: 20vw !important;
  }
  &--30 {
    width: 30vw !important;
  }
  &--40 {
    width: 40vw !important;
  }
  &--50 {
    width: 50vw !important;
  }
  &--60 {
    width: 60vw !important;
  }
  &--70 {
    width: 70vw !important;
  }
  &--80 {
    width: 80vw !important;
  }
  &--90 {
    width: 90vw !important;
  }
  &--100 {
    width: 100vw !important;
  }

  &--1-8 {
    width: 12.5vw !important;
  }
  &--1-4 {
    width: 25vw !important;
  }
  &--1-3 {
    width: 33.333vw !important;
  }
  &--1-2 {
    width: 50vw !important;
  }
  &--2-3 {
    width: 66.666vw !important;
  }
  &--3-4 {
    width: 75vw !important;
  }
}

// max-width
.maxW {
  @for $part from 0 through 1000 {
    &--#{$part*5} {
      max-width: #{$part * 5px};
    }
  }
}

.minW {
  @for $part from 0 through 1000 {
    &--#{$part*5} {
      min-width: #{$part * 5px};
    }
  }
}
