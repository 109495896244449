.pd-card {
  margin-bottom: _space(1.5);
  transition: all 200ms linear;

  &__dueDate {
    display: flex;
    flex-direction: column;
    width: 110px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    padding-top: _space(1);

    > span {
      position: relative;
      top: -6px;
    }

    .pd-card__dueDate--ico {
      align-self: center;
      transition: all 150ms ease-out;
      background-color: #fff;
      margin-bottom: _space(0.5);
      width: 4rem;
      height: 4rem;
      border-radius: 200px;

      img {
        height: 3rem;
        border-radius: 200px;
      }

      &:hover {
        z-index: 2;
        transform: scale(2.2);
        box-shadow: 0 2px 8px #757575;
      }
    }
  }

  &__content {
    width: 100%;
    background-color: #fff;
    border-radius: $radius--lg;
    box-shadow:
      0 4px 4px rgb(51 51 51 / 4%),
      0 4px 16px rgb(51 51 51 / 8%);
  }

  &__header {
    padding: _space(1) _space(2);

    .pd-card__initials {
      line-height: 1.4;
      margin-bottom: _space(1);

      .name {
        display: block;
        font-size: 1rem;
        color: #808080;
        font-weight: 500;
      }

      .date {
        display: block;
        color: #808080;
        font-size: 0.9rem;
      }
    }
  }

  &__body {
    font-size: 1.2rem;
    padding: _space(0.25) _space(2) _space(1) _space(2);

    ul.lst--none {
      li {
        .dueDate {
          width: 100px;
          text-align: right;
        }
        > .fa {
          width: 10px;
          align-self: flex-start;
          position: relative;
          top: 5px;
        }

        > .text {
          width: 100%;
        }
      }
    }
  }

  &__footer {
    padding: 0 _space(2) _space(0.75) _space(2);
  }

  h5 {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    position: relative;
    top: 2px;

    a {
      text-decoration: none;
      color: #000;
      transition: all 150ms linear;

      .ico--success {
        display: none;
        width: 26px;
        height: 26px;
        fill: #becd00;
      }

      .fa {
        font-size: 1.5rem;
        color: #75757580;
      }

      &:hover {
        text-decoration: none;

        span {
          text-decoration: underline;
        }

        .fa {
          color: #757575;
        }
      }
    }
  }

  .label-value {
    display: flex;
    flex-direction: column;
    margin-bottom: _space(1.5);

    .label {
      font-size: 1rem;
      line-height: 1;
      color: #808080;
      margin-bottom: _space(0.25);
    }
  }

  &.done {
    .pd-card__dueDate {
      > div {
        opacity: 0.5;
      }
    }

    .pd-card__content {
      background-color: #ffffff80;
    }

    h5 {
      a {
        .ico--success {
          display: inline;
        }

        > span > span {
          opacity: 0.5;
        }
      }
    }
  }
}
