// hardware (GPU) acceleration
[class*=' animation--'] {
  transform: translateZ(0);
  will-change: transform;
}

//
//  Transitions
//
.transition {
  @include transition();
}

//
//  Rotate 360 degree ( good for Spinners )
//
.animation--rotate {
  animation-name: animation--rotate;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes animation--rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//
//  Pulsating gradient
//
.animation--pulsating-gradient {
  animation-name: animation--pulsating-gradient;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes animation--pulsating-gradient {
  0% {
    box-shadow: 0 0 _space(1) 3px $danger;
  }
  50% {
    box-shadow: 0 0 _space(2) 3px $danger;
  }
  100% {
    box-shadow: 0 0 _space(1) 3px $danger;
  }
}

//
//  Pulsating box shadow
//
@keyframes animation--pulse-box-shadow {
  0% {
    box-shadow: 0 0;
  }
  70% {
    box-shadow: 0 0 4px 8px rgba($white, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($white, 0);
  }
}

//
//  Form stepper
//
@keyframes animation--stepper {
  0% {
    box-shadow: 0 0;
  }
  70% {
    box-shadow: 0 0 2px 4px #f2f2f2bf;
  }
  100% {
    box-shadow: 0 0 0 0 #f2f2f2bf;
  }
}

//
//  Skeleton loader
//
@keyframes animation--skeleton-loader {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

//
//  Spotlight search
//
@keyframes animation--search-progress {
  from {
    transform: rotate(0deg) translate(-3px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(-3px) rotate(-360deg);
  }
}

//
//  SASS Basis Spinner
//
@keyframes ui-spinner-legacy {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

//
//  Badge animation (when Badge appears, get user's attention)
//
@keyframes scale--up-and-down {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

//
//  .animation--fade--in-out
//
.animation--fade--in-out {
  animation-name: fade--in-out;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes fade--in-out {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 1s ease-in-out 100ms;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
