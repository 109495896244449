.review-detail__deprecated {
  background-color: #fff;
  margin-bottom: _space(1.5);

  &__header {
    transition: all 200ms ease-in-out;
    padding: _space(1);
    background-color: #f2f5cc;
    border-top-left-radius: $radius--lg;
    border-top-right-radius: $radius--lg;

    .d--flex {
      gap: 0.5rem;
    }

    span:not(.badge) {
      font-size: 1.6rem;
      font-weight: 500;
      opacity: 0.8;
    }

    .sender {
      .ico {
        color: #453e6c70;
        font-size: 1.2rem;
      }

      span {
        font-size: 1rem;
        color: #757575cc;
      }

      .fa {
        color: #757575cc;
      }
    }

    .review-detail__ico {
      transition: all 150ms ease-out;
      background-color: #fff;
      border-radius: 200px;

      img {
        border: 2px solid #e2e2e5;
      }

      &:hover {
        z-index: 2;
        transform: scale(2.2);
        box-shadow: 0 2px 8px #757575;

        img {
          border: 1px solid #e2e2e5;
        }
      }
    }

    .ico {
      font-size: 2.4rem;
    }

    a {
      color: #15074e;
    }
  }

  &__content {
    padding: _space(1);
  }

  &__body {
    flex-basis: 60%;

    textarea {
      max-width: 100%;
      height: 200px;
    }
  }

  &__history {
    flex-basis: 40%;
    border-radius: 6px;
    padding: _space(1);
    background: #f6effb;
    color: #5a2382;

    .h5.muted {
      font-weight: 400;
      font-size: 1rem;
      color: #5a238280;
    }

    fb-review-history-card:not(:last-child) {
      margin-bottom: _space(1);
    }
  }

  &__footer {
    padding: _space(0.5) 0;
  }

  &:hover {
    .review-detail {
      &--header {
        span {
          opacity: 1;
        }
      }
    }
  }
}
