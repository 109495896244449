//
//  < label >
//
label {
  display: block;

  line-height: 1.2;
  color: $antracit;
  cursor: default;

  // label followed by:
  + input,
  + output,
  + select,
  + textarea,
  + progress,
  + ui-date-field-legacy,
  + ui-date-time-field-legacy,
  + .c-input-group {
    margin-top: $form__label-input--margin;
  }
}

//  optional label addon
//  behaviour: display text on :focus
// FR > clean-up
label .c-label__note {
  float: right;
}

.c-label__note {
  font-style: italic;
  color: $antracit;

  &::before {
    // FR > create $variables with UTF-8 symbols
    content: 'ℹ';
    display: inline-block;

    width: 17px;
    height: 12px;
    margin-right: _space(0.125);

    text-align: center !important;
    // FR > replace "bold" by  $bold
    font-weight: bold;
    font-style: normal;
    line-height: 1;
    color: $white;

    background: rgba($info, 0.5);
    box-shadow: 0 2px 0 0 rgba($info, 0.5);

    border-radius: $radius--md;
  }
}

//
//  SHARED
//
// use this class on every input (Mandatory!)
.c-form-control {
  width: 100%;
  background: $white;
}

// set maximum input width
.c-form-control,
.c-input-group,
progress,
input[type='range'] {
  max-width: $form__element--max-width;
}

.ui-form-row--max-width {
  max-width: $form__element--max-width;
}

//
//  input with addon
//
.c-input-group__addon {
  background: $white;

  &:hover {
    // FR > remove !important
    z-index: 0 !important;
  }
}

// addon on the right side of the input
.c-input-group__field {
  .c-form-control {
    padding-right: 0;
    border-right: 0;
  }

  + .c-input-group__addon {
    user-select: none;
    padding-left: _space(0.25);

    color: $antracit;
    border-left-color: $white;
  }
}

//
//  basic form elements
//
$all-text-inputs: 'input, output, textarea';

#{$all-text-inputs} {
  //-webkit-appearance: none;
  //font-family: $base-font-family;
  //border: 2px solid blueviolet !important;
  //background: blueviolet !important;
}

input,
select,
textarea {
  //appearance: none;
  //width: 100%;

  &::placeholder {
    color: lighten($antracit, 15%);
  }

  //
  // states
  //LVFHA : :link :visited :focus :hover :active : when it is desirable for focus to display hover behaviour.
  //LVHFA : :link :visited :hover :focus :active : when it is not desirable for focus to display hover behaviour.
  &:focus {
  }

  //https://css-tricks.com/almanac/selectors/f/focus-within/
  &:focus-within {
  }

  &:hover {
    //outline: none;
  }
  &:active {
  }
  &:disabled {
    //@extend .--disabled;
  }
}

// .............. FR > all bellow > clean-up
// .............. FR > all bellow > clean-up
// .............. FR > all bellow > clean-up
// .............. FR > all bellow > clean-up
// .............. FR > all bellow > clean-up
// .............. FR > all bellow > clean-up

.c-label__note {
  //  opacity: 0;
  transition: opacity $speed--normal ease;
}

.c-input-group__addon {
  transition: all $speed--normal ease;
}

ui-form-element-legacy {
  &:focus-within {
    .c-label__note {
      opacity: 1;
    }
    .c-input-group__addon {
      border-color: $temp__nejaka-modra;
      border-left-color: $white;
    }
  }
}

//
//  Lookup field
//
// FR > relocate?
ui-lookup-field-legacy {
  select {
    min-width: 100%;
    width: 153px;
    background-image: none !important;
    display: block;
    margin-top: -1px;
  }
}
