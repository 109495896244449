.dc-icon {
  display: inline-block; // this makes animations works
}

// >>>>> FR > clean-up (all below)

// FR > create a webfont icon
.icon--koka {
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: $text;
  border-radius: 50%;

  &::after {
    content: 'K';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    font-size: 10px;
    line-height: 1rem;
    text-align: center;
    font-weight: bold;
    color: $white;
  }
}

//
// Partner detail - make fleet icon bigger
//
dc-partner-policy-card .icon--fleet {
  padding: _space(0.25);
}

// FR > refuck

//
// Temporary image icons
//----------------------
.tmp-dc-icon {
  width: 14px;
  height: 14px;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  margin-left: -5px;
  margin-right: -5px;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px;

  &.i--eye {
    background-image: url('/assets/images/icons/dc-icon.eye.png');
  }
  &.i--eye-alt {
    background-image: url('/assets/images/icons/dc-icon.eye-alt.png');
  }
  &.i--documents {
    background-image: url('/assets/images/icons/dc-icon.documents.png');
    background-size: 20px;
  }
  &.i--documents-alt {
    background-image: url('/assets/images/icons/dc-icon.documents-alt.png');
    background-size: 20px;
  }
  &.i--history {
    background-image: url('/assets/images/icons/dc-icon.history.png');
  }
  &.i--history-email {
    background-image: url('/assets/images/icons/dc-icon.history-email.png');
  }
  &.i--history-phone {
    background-image: url('/assets/images/icons/dc-icon.history-phone.png');
  }
}

//
//  Toggle inactive items (BUTTON)
//
.btn--toggle-inactive-items {
  .dc-btn {
    width: 26px;
    height: 26px;
    padding: 0;
    outline: none;
  }

  .tmp-dc-icon {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px;
  }
}
