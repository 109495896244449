p-paginator {
  .p-paginator {
    @apply flex justify-start gap-2 border-none bg-transparent;

    .p-paginator-prev,
    .p-paginator-next {
      @apply h-auto rounded-lg border border-solid border-gray-300 p-1 pl-4 pr-2 font-medium text-gray-600 focus:ring-0;
    }

    .p-paginator-prev {
      @apply pr-4;
    }

    .p-paginator-pages {
      @apply order-1;

      .p-paginator-page {
        @apply rounded-full;
        &.p-highlight {
          @apply bg-primary-50 font-medium text-primary-600;
        }
      }
    }
    .p-paginator-rpp-options .p-dropdown-label {
      @apply inline-flex items-center;
    }

    .p-paginator-icon {
      @apply inline-flex items-center gap-1;
    }
  }
}
