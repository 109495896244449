/*
---
name: $_border-radius
category:
  - core/variable
---
Border radius

### scss
```scss
$_border-radius: 3px;
```
*/

$_border-radius: 3px !default;
