.p-dialog.fb-dialog {
  &.p-component {
    font-size: 1.1rem;

    .p-dialog-header {
      .p-dialog-title {
        font-weight: 500;
      }
    }

    p {
      max-width: 100%;
      font-weight: inherit;
      color: inherit;
    }

    p,
    ul {
      li > ul {
        margin-bottom: inherit;
      }
    }

    strong {
      font-weight: 500;
      color: #000;
    }
  }

  .p-dialog-header {
    h4 {
      font-size: 2rem;
      font-weight: 400;
      color: #333;
    }
  }

  &.fb-dialog {
    background-color: #fbfcf0;
    background-size: 100%;
    background-repeat: repeat-y;
    background-image: url('/assets/images/feedback/ui/stacked-waves.svg');

    .p-dialog-header,
    .p-dialog-content {
      background-color: transparent;
    }

    .p-dialog-header {
      .p-dialog-title {
        font-family: $ff--yummo;
        font-size: 2rem;
        font-weight: 400;
        color: #333;
      }
    }

    &.instant {
      max-width: 900px;
      width: 80%;
    }
  }

  h5 {
    &.tip {
      font-family: $ff--yummo;
      font-size: 2rem;
      font-weight: 400;
      color: #333;
      margin-bottom: 2rem;

      .fb__help {
        display: block;
        width: max-content;
        padding-left: 1.4rem;
        font-size: 1rem;
        color: #757575c2;
        text-decoration: underline;
        letter-spacing: 0.1px;
        font-weight: 400;
        position: relative;
        top: -3px;

        &:hover {
          text-decoration: none;
        }

        &:before {
          content: '';
          background: url('/assets/images/feedback/icons/question-mark.svg');
          opacity: 0.3;
          position: absolute;
          left: 0;
          top: 0.27rem;
          height: 1rem;
          width: 1rem;
        }
      }
    }
  }

  .dc-btn {
    transition: all 200ms linear;

    &:not(.dc-btn--circled) {
      font-size: 1.2rem;
      padding: 0.8rem 1.6rem;

      &.dc-btn--primary {
        background-color: #5a2382;
        border-color: #5a2382;
        color: #fff;

        &:hover {
          background: #9041c9;
          border-color: #9041c9;
          color: #fff;
        }
      }

      &.dc-btn--secondary {
        color: #5a2382;
        background: #fff;
        border-color: #5a2382;

        &:hover {
          background: #fff;
          color: #9041c9;
          border-color: #9041c9;
        }
      }

      &:hover {
        box-shadow:
          0 4px 4px rgb(51 51 51 / 4%),
          0 4px 24px rgb(51 51 51 / 24%);
      }
    }

    &.dc-btn--small {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }

    &.dc-btn--tertiary {
      background-color: #333;
      color: #fff;

      &:hover {
        box-shadow:
          0 4px 4px rgb(51 51 51 / 4%),
          0 4px 24px rgb(51 51 51 / 24%);
      }
    }
  }

  .p-button.cta {
    background: #5a2382;
    border-color: #5a2382;

    &:enabled {
      &:hover {
        background: #9041c9;
        border-color: #9041c9;
      }
    }

    &:active,
    &:active:focus {
      background: #5a2382;
      border-color: #5a2382;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem #be88e5;
    }
  }

  @include quill-editor;
}
