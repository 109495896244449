p-calendar {
  .p-calendar {
    &.p-calendar-w-btn {
      button {
        @apply bg-primary-400;
      }
    }
  }
}

label:hover + ui-calendar .p-inputtext:not([disabled]) {
  @apply border-[color:var(--input-border-hover)];
}
