//
//
//
// FR > clean-up whole file

//
//  disabled items
//
//
//  item state: Disabled
//
// usage: add to any disabled element
.--disabled,
.--DISABLED {
  cursor: default !important;
  pointer-events: none !important;
  filter: grayscale(100%);
  opacity: 0.3;
}

// FR > replace .disabled by .--disabled
.disabled {
  &,
  * {
    color: gray !important;
    cursor: not-allowed !important;
  }

  &:hover {
    &,
    a {
      text-decoration: none;
    }
  }

  .badge {
    background: darken($milk, 5%);
    color: $antracit;
  }
}

// do not change text color on :hover for disabled button
.dc-btn--disabled {
  &.dc-btn--full-faced:hover {
    background-color: $action;
    border-color: $action;
    color: $white;
  }
}

//
//  disable
//
.disable {
  &--pointer-events {
    pointer-events: none;
  }
  &--max-width {
    max-width: none;
  }
}
