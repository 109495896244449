//
//  number of columns
//
.columns {
  &--1 {
    display: block !important;
  }
  &--2 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  &--3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  &--4 {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  &--5 {
    grid-template-columns: repeat(5, 1fr) !important;
  }
  &--6 {
    grid-template-columns: repeat(6, 1fr) !important;
  }
  &--7 {
    grid-template-columns: repeat(7, 1fr) !important;
  }
  &--8 {
    grid-template-columns: repeat(8, 1fr) !important;
  }
  &--9 {
    grid-template-columns: repeat(9, 1fr) !important;
  }
  &--10 {
    grid-template-columns: repeat(10, 1fr) !important;
  }
  &--11 {
    grid-template-columns: repeat(11, 1fr) !important;
  }
  &--12 {
    grid-template-columns: repeat(12, 1fr) !important;
  }
  &--auto {
    grid-template-columns: repeat(auto-fill, minmax(#{$width--mobile}, 1fr)) !important;
  }
  //&--auto { grid-auto-flow: column !important; }
}

//
//  number of rows
//
.rows {
  &--1 {
    display: block;
  }
  &--2 {
    grid-template-rows: repeat(2, 1fr) !important;
  }
  &--3 {
    grid-template-rows: repeat(3, 1fr) !important;
  }
  &--4 {
    grid-template-rows: repeat(4, 1fr) !important;
  }
  &--5 {
    grid-template-rows: repeat(5, 1fr) !important;
  }
  &--6 {
    grid-template-rows: repeat(6, 1fr) !important;
  }
  &--7 {
    grid-template-rows: repeat(7, 1fr) !important;
  }
  &--8 {
    grid-template-rows: repeat(8, 1fr) !important;
  }
  &--9 {
    grid-template-rows: repeat(9, 1fr) !important;
  }
  &--10 {
    grid-template-rows: repeat(10, 1fr) !important;
  }
  &--11 {
    grid-template-rows: repeat(11, 1fr) !important;
  }
  &--12 {
    grid-template-rows: repeat(12, 1fr) !important;
  }
  &--auto {
    grid-template-rows: repeat(auto-fill, minmax(#{$width--mobile}, 1fr)) !important;
  }
  //&--auto { grid-auto-flow: column !important; }
}

//
//  columns width
//
// note: parent has to have class "columns--12"
.col {
  &--1-12 {
    grid-column: span 1 !important;
  }
  &--2-12 {
    grid-column: span 2 !important;
  }
  &--3-12 {
    grid-column: span 3 !important;
  }
  &--4-12 {
    grid-column: span 4 !important;
  }
  &--5-12 {
    grid-column: span 5 !important;
  }
  &--6-12 {
    grid-column: span 6 !important;
  }
  &--7-12 {
    grid-column: span 7 !important;
  }
  &--8-12 {
    grid-column: span 8 !important;
  }
  &--9-12 {
    grid-column: span 9 !important;
  }
  &--10-12 {
    grid-column: span 10 !important;
  }
  &--11-12 {
    grid-column: span 11 !important;
  }
  &--12-12 {
    grid-column: span 12 !important;
  }
}

//
//  gap sizes
//
.gap {
  &--1 {
    grid-gap: _space(1) !important;
  }
  &--2 {
    grid-gap: _space(2) !important;
  }
  &--3 {
    grid-gap: _space(3) !important;
  }
  &--4 {
    grid-gap: _space(4) !important;
  }
  &--1-4 {
    grid-gap: _space(0.25) !important;
  }
  &--1-3 {
    grid-gap: _space(0.33) !important;
  }
  &--1-2 {
    grid-gap: _space(0.5) !important;
  }
  &--2-3 {
    grid-gap: _space(0.66) !important;
  }
  &--3-4 {
    grid-gap: _space(0.75) !important;
  }
}
