dc-direct-file-uploader {
  .dc-direct-file-uploader__select-button {
    position: relative;
    cursor: pointer !important;

    input {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      cursor: pointer !important;
    }
  }
}
