/*
---
name: _hero()
category:
  - core/abstract
---
Abstract hero component

### scss
```scss
.c-hero {
  @include _hero();
  @include _padding(1);

  &__header {
    @include _margin(0, 0, 1);
  }

  &__footer {
    @include _margin(1, 0, 0);
  }
}
```

### html
```ejs
<div class="c-hero">
  <div class="c-hero__header">
    ...
  </div>
  <div class="c-hero__content">
    ...
  </div>
  <div class="c-hero__footer">
    ...
  </div>
</div>
```
*/

@mixin _hero() {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  &__header {
    @include _hero__col();
    align-self: flex-start;
  }

  &__content {
    @include _hero__col();
    align-self: center;
  }

  &__footer {
    @include _hero__col();
    align-self: flex-end;
  }
}

@mixin _hero__col() {
  flex: none;
  width: 100%;
  position: relative;
}
