.rotate {
  &--15 {
    transform: rotate(15deg);
  }
  &--30 {
    transform: rotate(30deg);
  }
  &--45 {
    transform: rotate(45deg);
  }
  &--90 {
    transform: rotate(90deg);
  }

  &--180 {
    transform: rotate(180deg);
  }
  &--270 {
    transform: rotate(270deg);
  }
  &--360 {
    transform: rotate(360deg);
  }
}
