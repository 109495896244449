/*
---
name: .c-hamburger-btn
category:
  - object/component
  - object/component/hamburger-btn
---
Hamburger button component

### html
```html
<button id="hamburger-btn" class="c-hamburger-btn" role="button" aria-expanded="false" aria-controls="drawer">
  <span class="c-hamburger-btn__bars">
    <span class="c-hamburger-btn__bar"></span>
    <span class="c-hamburger-btn__bar"></span>
    <span class="c-hamburger-btn__bar"></span>
  </span>
  <span class="c-hamburger-btn__label">MENU</span>
</button>

<nav id="drawer" aria-labelledby="hamburger-btn">...</nav>
```
*/

.#{$_prefix}c-hamburger-btn {
  $height: 16px;
  $bar-height: 2px;

  @include _hamburger-btn((
    height: $height,
    bar-height: $bar-height,
  ));

  &[aria-expanded="true"] > &__bars > &__bar {
    &:nth-of-type(1), &:nth-of-type(3) {
      top: ($height / 2 - $bar-height / 2);
    }

    &:nth-of-type(1) {
      transform: rotate(45deg);
    }

    &:nth-of-type(2) {
      background-color: transparent;
    }

    &:nth-of-type(3) {
      transform: rotate(-45deg);
    }
  }
}
