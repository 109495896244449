/*
---
name: _form-control()
category:
  - core/abstract
  - core/abstract/form
---
Abstract form control component

### scss
```scss
.c-form-control {
  @include _form-control();
}
```

### html
```ejs
<input type="text" class="c-form-control">
```
*/

@mixin _form-control() {
  @include _form-control-base-border();
  @include _form-control-base-padding();
  background-color: _var-color-white();
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .035);
  max-width: 100%;

  &:disabled {
    background-color: _var-lightest-color-gray();
  }

  @at-root {
    textarea#{&} {
      height: auto;
      width: 100%;
    }
  }
}
