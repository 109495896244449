//
//  Responsivity
//
.grid--cards {
  display: grid;
  grid-template-columns: 1fr; // mobile first: one card per line
  grid-gap: _space(0.5);
}

@media screen and (min-width: $breakpoint__half-screen--min) {
  .grid--cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .sidebar-is--opened .grid--cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (min-width: $breakpoint__lg--min) {
  .grid--cards {
  }
  .sidebar-is--opened .grid--cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: $breakpoint__xl--min) {
  .grid--cards {
    grid-template-columns: repeat(3, 1fr);
  }
  .sidebar-is--opened .grid--cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: $breakpoint__xxl--min) {
  .grid--cards {
    grid-template-columns: repeat(4, 1fr);
  }
  .sidebar-is--opened .grid--cards {
    grid-template-columns: repeat(3, 1fr);
  }
}
