/*


usage
-----

.element {
  @include pseudo();
}


*/

@mixin pseudo($display: block, $position: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $position;
}
