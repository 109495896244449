.node-list {
  margin: 0;
  list-style-type: none;

  ul {
    list-style-type: none;
  }

  li {
    &.closed {
      > ul {
        display: none;
      }
    }
  }

  .person {
    fb-node-card {
      display: inline-block;
    }

    > fb-node-card {
      > .node-card {
        border: 1px solid transparent;
        border-radius: 4px;
        width: auto;
        display: inline-block;

        &:hover {
          border-color: #f0f0f1;
          background-color: #f0f0f1;
        }
      }
    }

    &:not(.unnoded) {
      > fb-node-card {
        display: block;
        background-color: #f9f9fc;
        border-left: 5px solid #e2e2e5;

        > .node-card.expandable {
          &:hover {
            border-color: transparent;
          }
        }
      }
    }
  }

  .team {
    background: #ffffff45;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid #e9ecfd;
    box-shadow: 0 3px 12px 0 rgb(0 0 0 / 4%);
    margin-bottom: 1rem;
    margin-right: 0.3rem;

    > fb-node-card {
      display: block;
      margin-bottom: _space(0.25);
      background-color: #dee680;
      border-left: 5px solid #becd00;

      > .node-card {
        display: inline-block;
        margin: 0 0 0 4rem;
        width: auto;
        border: 1px solid transparent;
        border-radius: 4px;

        &:hover {
          border-color: #d2db6e;
          background-color: #d2db6e;
        }
      }
    }
  }

  .unnoded {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
