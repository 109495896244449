/*
---
name: _min()
category:
  - core/function
  - core/function/media-query
---
Return min size.

* If `$size` is key of `$_size`, return that value.
* If `$size` is number, return this.

### scss
```scss
//
// @param  size|length  $size
// @return length|false
//

@media (min-width: _min(md)) {
  ...
}
```
*/

@function _min($size) {
  @if (null != map-get($_sizes, $size)) {
    @return map-get($_sizes, $size);
  } @else if ('number' == type-of($size)) {
    @return $size;
  }
  @return false;
}

/*
---
name: _max()
category:
  - core/function
  - core/function/media-query
---
Return max size.

* If `$size` is key of `$_size`, return that max value.
* If `$size` is number, return this.

### scss
```scss
//
// @param  size|length  $size
// @return length|false
//

@media (max-width: _max(md)) {
  ...
}
```
*/

@function _max($size) {
  $next_size: _next-size-key($size);
  @if (false != $next_size) {
    $max: map-get($_sizes, $next_size) - 1;
    @return $max;
  } @else if ('number' == type-of($size)) {
    @return $size;
  }
  @return false;
}

/*
---
name: _prev-size-key()
category:
  - core/function
  - core/function/media-query
---
Return prev key of `$_size`

### scss
```scss
//
// @param  size  $size
// @return size
//

$prev_size_key: _prev-size-key(md); // => sm
```
*/

@function _prev-size-key($size) {
  $prev: false;
  @each $key, $value in $_sizes {
    @if ($key == $size) {
      @return $prev;
    }
    $prev: $key;
  }
  @return false;
}

/*
---
name: _next-size-key()
category:
  - core/function
  - core/function/media-query
---
Return next key of `$_size`

### scss
```scss
//
// @param  size  $size
// @return size
//

$next_size_key: _next-size-key(md); // => lg
```
*/

@function _next-size-key($size) {
  $next: false;
  @each $key, $value in $_sizes {
    @if ($key == $size) {
      $next: $key;
    } @else if (false != $next) {
      @return $key;
    }
  }
  @return false;
}
