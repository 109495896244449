p-multiselect {
  &.flex-auto {
    .p-multiselect {
      @apply w-full;
    }
  }

  .p-placeholder {
    @apply text-gray-300;
  }

  .p-multiselect {
    @apply rounded-lg;

    .p-multiselect-label {
      @apply pl-3.5;

      &.p-placeholder {
        @apply text-placeholder;
      }
    }

    .p-icon-wrapper {
      @apply flex;
    }

    .p-multiselect-trigger {
      @apply relative;
    }
    .p-multiselect-trigger:before {
      @apply absolute -left-[2px] my-2 h-4/6 w-px bg-gray-200 content-[''];
    }

    &:not(.p-disabled) {
      &:hover {
        @apply border-blue-600;
      }
      &.p-focus {
        @apply border-primary-300 ring ring-primary-600/20;
      }
    }

    &.p-multiselect-chip {
      .p-multiselect-label.p-placeholder {
        @apply p-2 pl-3 text-base leading-6;
      }
      .p-multiselect-label {
        @apply p-1;

        .p-multiselect-token {
          @apply mr-1;
        }

        .p-multiselect-token-label {
          @apply text-base leading-5;
        }
      }
      .p-multiselect-clear-icon {
        @apply mr-4;
      }
    }
  }
}

.p-multiselect-panel {
  @apply my-[2px] rounded-lg;

  .p-multiselect-items-wrapper {
    @apply rounded-l-lg;
  }

  .p-multiselect-items {
    @apply m-1 p-1;

    .p-multiselect-item-group,
    .p-multiselect-item {
      @apply rounded-md px-3 py-2;
    }

    .p-multiselect-item {
      &:not(.p-highlight):not(.p-disabled) {
        @apply hover:bg-blue-gray-50;
      }
    }
  }

  .p-multiselect-header {
    @apply rounded-t-lg bg-gray-100;
  }
}

p-multiselectitem {
  li.p-multiselect-item {
    @apply flex items-start;

    span {
      @apply whitespace-pre-wrap;
    }
  }
}

.p-inputwrapper-filled .p-multiselect {
  &.p-multiselect-chip {
    .p-multiselect-label {
      @apply p-2;
    }
  }
}

.p-column-filter-overlay {
  p-multiselect {
    .p-multiselect-clear-icon {
      @apply right-12 -mt-2.5 mr-1 inline-flex;
    }
  }
}
