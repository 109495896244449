.dc-ace-editor {
  width: 100%;
  height: 400px;
  border: 1px solid $grey-20;
  border-radius: 3px;

  &.extended {
    height: calc(100vh - 80px);
  }
}
