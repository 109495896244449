@charset "utf-8";

//
// !!! IMPORTANT !!!
//
// This is generated content by www.icomoon.io
// Add custom icons to the end of this file
//-------------------------------
@font-face {
  font-family: 'direct-core-icons';
  src:
    url('/assets/fonts/direct-core-icons.ttf?3wv549') format('truetype'),
    url('/assets/fonts/direct-core-icons.woff?3wv549') format('woff'),
    url('/assets/fonts/direct-core-icons.svg?3wv549#direct-core-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.dc-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'direct-core-icons' !important;
  speak: none;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// fixed width
.dc-fw {
  width: calc(18em / 14);
  text-align: center;
}

// Font icons
$dc-icon__accident: '\e900';
$dc-icon__actions: '\e901';
$dc-icon__actions-alt: '\e902';
$dc-icon__address: '\e903';
$dc-icon__balance: '\e904';
$dc-icon__bell: '\e905';
$dc-icon__bell-o-2: '\e906';
$dc-icon__bell-o-4: '\e907';
$dc-icon__cards: '\e908';
$dc-icon__check: '\e909';
$dc-icon__chevron-down: '\e90a';
$dc-icon__chevron-left: '\e90b';
$dc-icon__chevron-right: '\e90c';
$dc-icon__chevron-up: '\e90d';
$dc-icon__company: '\e90e';
$dc-icon__document: '\e90f';
$dc-icon__document-important: '\e910';
$dc-icon__edit: '\e911';
$dc-icon__email: '\e912';
$dc-icon__envelope: '\e913';
$dc-icon__fleet: '\e914';
$dc-icon__hourglass: '\e915';
$dc-icon__inbalance: '\e916';
$dc-icon__info: '\e917';
$dc-icon__liability: '\e918';
$dc-icon__person: '\e91a';
$dc-icon__phone: '\e91b';
$dc-icon__pin: '\e91c';
$dc-icon__property: '\e91d';
$dc-icon__table: '\e920';
$dc-icon__tasks: '\e921';
$dc-icon__travel: '\e922';
$dc-icon__user-business: '\e923';
$dc-icon__user-circle: '\e924';
$dc-icon__vehicle: '\e925';
$dc-icon__walk: '\e926';
$dc-icon__users-plus: '\e927';
$dc-icon__users-minus: '\e928';
$dc-icon__sidebar: '\e91e';
$dc-icon__sme: '\e91f';
$dc-icon__migration: '\e919';

.dc-icon__accident {
  &:before {
    content: $dc-icon__accident;
  }
}

.dc-icon__actions {
  &:before {
    content: $dc-icon__actions;
  }
}

.dc-icon__actions-alt {
  &:before {
    content: $dc-icon__actions-alt;
  }
}

.dc-icon__address {
  &:before {
    content: $dc-icon__address;
  }
}

.dc-icon__balance {
  &:before {
    content: $dc-icon__balance;
  }
}

.dc-icon__bell {
  &:before {
    content: $dc-icon__bell;
  }
}

.dc-icon__bell-o-2 {
  &:before {
    content: $dc-icon__bell-o-2;
  }
}

.dc-icon__bell-o-4 {
  &:before {
    content: $dc-icon__bell-o-4;
  }
}

.dc-icon__cards {
  &:before {
    content: $dc-icon__cards;
  }
}

.dc-icon__check {
  &:before {
    content: $dc-icon__check;
  }
}

.dc-icon__chevron-down {
  &:before {
    content: $dc-icon__chevron-down;
  }
}

.dc-icon__chevron-left {
  &:before {
    content: $dc-icon__chevron-left;
  }
}

.dc-icon__chevron-right {
  &:before {
    content: $dc-icon__chevron-right;
  }
}

.dc-icon__chevron-up {
  &:before {
    content: $dc-icon__chevron-up;
  }
}

.dc-icon__company {
  &:before {
    content: $dc-icon__company;
  }
}

.dc-icon__document {
  &:before {
    content: $dc-icon__document;
  }
}

.dc-icon__document-important {
  &:before {
    content: $dc-icon__document-important;
  }
}

.dc-icon__edit {
  &:before {
    content: $dc-icon__edit;
  }
}

.dc-icon__email {
  &:before {
    content: $dc-icon__email;
  }
}

.dc-icon__envelope {
  &:before {
    content: $dc-icon__envelope;
  }
}

.dc-icon__fleet {
  &:before {
    content: $dc-icon__fleet;
  }
}

.dc-icon__hourglass {
  &:before {
    content: $dc-icon__hourglass;
  }
}

.dc-icon__inbalance {
  &:before {
    content: $dc-icon__inbalance;
  }
}

.dc-icon__info {
  &:before {
    content: $dc-icon__info;
  }
}

.dc-icon__liability {
  &:before {
    content: $dc-icon__liability;
  }
}

.dc-icon__migration,
.dc-icon__virtual {
  &:before {
    content: $dc-icon__migration;
  }
}

.dc-icon__person,
.dc-icon__health {
  &:before {
    content: $dc-icon__person;
  }
}

.dc-icon__phone {
  &:before {
    content: $dc-icon__phone;
  }
}

.dc-icon__pin {
  &:before {
    content: $dc-icon__pin;
  }
}

.dc-icon__property,
.dc-icon__property_damage {
  &:before {
    content: $dc-icon__property;
  }
}

.dc-icon__sidebar {
  &:before {
    content: $dc-icon__sidebar;
  }
}

.dc-icon__sme {
  &:before {
    content: $dc-icon__sme;
  }
}

.dc-icon__table {
  &:before {
    content: $dc-icon__table;
  }
}

.dc-icon__tasks {
  &:before {
    content: $dc-icon__tasks;
  }
}

.dc-icon__travel {
  &:before {
    content: $dc-icon__travel;
  }
}

.dc-icon__user-business {
  &:before {
    content: $dc-icon__user-business;
  }
}

.dc-icon__user-circle {
  &:before {
    content: $dc-icon__user-circle;
  }
}

.dc-icon__vehicle {
  &:before {
    content: $dc-icon__vehicle;
  }
}

.dc-icon__walk {
  &:before {
    content: $dc-icon__walk;
  }
}

.dc-icon__users-plus {
  &:before {
    content: $dc-icon__users-plus;
  }
}

.dc-icon__users-minus {
  &:before {
    content: $dc-icon__users-minus;
  }
}

//-------------------------------------
//
//       END OF GENERATED CODE
//
//-------------------------------------

//
// Text icons
//----------------------
$dc-icon__sms: 'SMS';
$dc-icon__ipex: 'IPEX';
$dc-icon__www: 'WWW';
$dc-icon__online: 'ONLINE';
$dc-icon__m_notif: '\f10b';
$dc-icon__m_app: 'Direct appka';
$dc-icon__client_zone: 'Klientská zóna';

.dc-icon__sms,
.dc-icon__www,
.dc-icon__online {
  font-family: $ff--monospace;
  font-size: 12px;
}

.dc-icon__sms {
  &:before {
    content: $dc-icon__sms;
  }
}

.dc-icon__ipex {
  &:before {
    content: $dc-icon__ipex;
  }
}

.dc-icon__www {
  &:before {
    content: $dc-icon__www;
  }
}

.dc-icon__online {
  &:before {
    content: $dc-icon__online;
  }
}

.dc-icon__m_app {
  &:before {
    content: $dc-icon__m_app;
  }
}

.dc-icon__client_zone {
  &:before {
    content: $dc-icon__client_zone;
  }
}

//
// Aliases
//----------------------
.dc-icon__personal {
  &:before {
    content: $dc-icon__walk;
  }
}

.dc-icon__broker {
  &:before {
    content: $dc-icon__person;
  }
}

.dc-icon__letter {
  &:before {
    content: $dc-icon__envelope;
  }
}

.dc-icon__selfemployed {
  &:before {
    content: $dc-icon__user-business;
  }
}

//
// Font Awesome
//----------------------
$dc-icon__registered_letter: '\f003'; // fa-envelope-o
$dc-icon__branch: '\f015'; // fa-home
$dc-icon__payment: '\f0d6'; // fa-money

.dc-icon__registered_letter,
.dc-icon__branch,
.dc-icon__m_notif,
.dc-icon__payment {
  font-family: $ff--icons--fontAwesome;
  font-weight: normal;
}

.dc-icon__registered_letter {
  &:before {
    content: $dc-icon__registered_letter;
  }
}

.dc-icon__branch {
  &:before {
    content: $dc-icon__branch;
  }
}

.dc-icon__payment {
  &:before {
    content: $dc-icon__payment;
  }
}

.dc-icon__m_notif {
  &:before {
    content: $dc-icon__m_notif;
  }
}
