@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
