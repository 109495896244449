@mixin _container-padding($use-safe-area: true) {
  padding-right: _var-container-margin-sm();
  padding-left: _var-container-margin-sm();

  @if true == $use-safe-area {
    padding-right: calc(#{ _var-container-margin-sm() } + constant(safe-area-inset-right));
    padding-left: calc(#{ _var-container-margin-sm() } + constant(safe-area-inset-left));

    padding-right: calc(#{ _var-container-margin-sm() } + env(safe-area-inset-right));
    padding-left: calc(#{ _var-container-margin-sm() } + env(safe-area-inset-left));
  }

  @include _media-min(md) {
    padding-right: _var-container-margin();
    padding-left: _var-container-margin();

    @if true == $use-safe-area {
      padding-right: calc(#{ _var-container-margin() } + constant(safe-area-inset-right));
      padding-left: calc(#{ _var-container-margin() } + constant(safe-area-inset-left));

      padding-right: calc(#{ _var-container-margin() } + env(safe-area-inset-right));
      padding-left: calc(#{ _var-container-margin() } + env(safe-area-inset-left));
    }
  }
}

/*
---
name: _fluid-container()
category:
  - core/abstract
  - core/abstract/container
---
Abstract fluid container component

### scss
```scss
.c-fluid-container {
  @include _fluid-container();
}
```

### html
```ejs
<div class="c-fluid-container">
  ...
</div>
```
*/

@mixin _fluid-container() {
  @include _container-padding();
  max-width: 100%;
}

/*
---
name: _container()
category:
  - core/abstract
  - core/abstract/container
---
Abstract container component

### scss
```scss
.c-container {
  @include _container();
}
```

### html
```ejs
<div class="c-container">
  ...
</div>
```
*/

@mixin _container() {
  @include _fluid-container();

  margin-right: auto;
  margin-left: auto;

  @include _media-min(lg) {
    max-width: _var-container-max-width();
  }
}
