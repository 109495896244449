/*
---
name: _entries()
category:
  - core/abstract
---
Abstract entries component

### scss
```scss
.c-entries {
  @include _entries();
  boder-top: 1px solid _light($_color-gray);

  &__item {
    @include _padding(1, 0);
    boder-bottom: 1px solid _light($_color-gray);
  }
}
```

### html
```ejs
<ul class="c-entries">
  <li class="c-entries__item">
    ...
  </li>
</ul>
```
*/

@mixin _entries() {
  @include _list-unstyled();
}
