/*
---
name: _pre()
category:
  - core/abstract
---
Abstract pre component

### scss
```scss
pre {
  @include _pre();
}
```

## html
```ejs
<pre>pre</pre>
```
*/

@mixin _pre() {
  background-color: _var-lightest-color-gray();
  border: 1px solid _var-lighter-color-gray();
  border-radius: _var-border-radius();
  padding: _var-base-font-size-px();
  overflow: auto;

  code {
    background-color: transparent;
    border: none;
    color: _var-color-text();
    font-size: 100%;
    padding: 0;
  }
}
