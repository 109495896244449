//
//  ui-media
//
.ui-media {
  display: flex;

  &__figure {
    padding-right: _space(0.5);
  }
  &__content {
    width: 100%;
  }
}
