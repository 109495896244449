/*


template
--------

  <div class="ui-empty-listing">
      Výpis je prázdný.
  </div>


*/

.ui-empty-listing {
  padding: _space(0.5);

  color: $grey-5;

  background: $grey-1;
  border: 1px solid $grey-3;
  border-radius: $radius--md;
}
