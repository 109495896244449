.p-datatable {
  .p-datatable-thead > tr > th {
    @apply text-sm/4 font-medium text-gray-500;
  }

  &.p-highlight {
    @apply bg-gray-200 text-gray-600;
  }

  .p-datatable-first-col-fixed {
    .p-datatable-thead > tr > th:first-of-type,
    .p-datatable-tbody > tr > td:first-of-type {
      @apply p-0 text-center;
    }

    .p-datatable-thead > tr > th:first-of-type {
      @apply pt-1;
    }

    .p-datatable-tbody > tr > td[rowspan] {
      @apply border-solid;
    }
    .p-datatable-tbody > tr > td[colspan] {
      @apply px-3 pb-4 pt-0 text-left;
    }
  }

  .p-datatable-loading-overlay {
    @apply bg-white/70;
  }

  .p-sortable-column {
    &.p-highlight {
      @apply text-gray-700 shadow-none hover:text-inherit;

      ui-sort-icon {
        @apply opacity-100;

        svg {
          @apply fill-gray-700;
        }
      }
    }

    ui-sort-icon {
      @apply inline-flex opacity-0;
    }

    p-columnfilter {
      @apply inline-flex;

      .p-column-filter-menu-button {
        @apply size-5;

        &.p-column-filter-menu-button-active {
          @apply bg-transparent;

          svg {
            @apply text-primary-700;
          }
        }

        span {
          @apply inline-flex;
        }
      }
    }

    &:hover {
      ui-sort-icon {
        @apply opacity-100;
      }
    }
  }
}
