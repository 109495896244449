fb-page-feedback {
  .fb-menu {
    list-style: none;
    margin-left: 0;
    margin-top: 1.2rem;

    .link {
      padding: 1rem;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      position: relative;
      right: -2px;

      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.5rem;
        padding: 1rem;
        align-items: center;
        width: 110px;
        height: 110px;
        color: #333;
        font-size: 0.9rem;
        text-align: center;
        border-radius: 200px;
        background-color: #f2f5cc;
        border: 2px solid #f2f5cc;
        transition: all 200ms linear;

        &:hover {
          text-decoration: none;
          border-color: #dfe680;
        }

        .fa {
          display: block;
          font-size: 2rem;
        }

        span {
          line-height: 1.3;
          font-weight: 500;
        }

        .muted {
          color: #757575c7;
          font-weight: 400;
        }
      }

      &.--active {
        background-color: #fff;
        box-shadow: 0 2px 18px 0 rgb(0 0 0 / 15%);
        clip-path: inset(-18px 1px -18px -18px);

        a {
          background-color: #dfe680;
          border-color: #dfe680;
        }
      }
    }
  }
}

.fb-menu__overview {
  font-weight: 500;
  margin-top: 2rem;

  h5 {
    font-weight: 500;
  }

  .ui-label-value__label {
    color: #453e6c70;
    font-weight: 500;
  }

  .ui-label-value__row {
    grid-template-columns: 110px auto;
  }
}
