/*
---
name: .c-top-balloon
category:
  - object/component
  - object/component/balloon
  - object/component/balloon/top-balloon
---
Top balloon component

### html
```html
<div class="c-top-balloon">
  ...
</div>
```
*/

.#{$_prefix}c-top-balloon {
  @include _top-balloon((
    background-color: _var-color-white(),
    border-color: _var-light-color-gray(),
    border-size: 1px,
  ));
}

/*
---
name: .c-right-balloon
category:
  - object/component
  - object/component/balloon
  - object/component/balloon/right-balloon
---
Right balloon component

### html
```html
<div class="c-right-balloon">
  ...
</div>
```
*/

.#{$_prefix}c-right-balloon {
  @include _right-balloon((
    background-color: _var-color-white(),
    border-color: _var-light-color-gray(),
    border-size: 1px,
  ));
}

/*
---
name: .c-bottom-balloon
category:
  - object/component
  - object/component/balloon
  - object/component/balloon/bottom-balloon
---
Bottom balloon component

### html
```html
<div class="c-bottom-balloon">
  ...
</div>
```
*/

.#{$_prefix}c-bottom-balloon {
  @include _bottom-balloon((
    background-color: _var-color-white(),
    border-color: _var-light-color-gray(),
    border-size: 1px,
  ));
}

/*
---
name: .c-left-balloon
category:
  - object/component
  - object/component/balloon
  - object/component/balloon/left-balloon
---
Left balloon component

### html
```html
<div class="c-left-balloon">
  ...
</div>
```
*/

.#{$_prefix}c-left-balloon {
  @include _left-balloon((
    background-color: _var-color-white(),
    border-color: _var-light-color-gray(),
    border-size: 1px,
  ));
}
