dc-partner--contact-history {
}

.dc-contact-history {
  &__list-item {
    margin-left: _space(-0.5);
    margin-bottom: _space(0.5);
    background: $concrete;
    border-radius: $radius--md;

    &--INCOMMING .fa {
      color: $temp__PURPLE--light;
    }

    &:not(:last-child) {
      margin-bottom: _space(0.5);
    }

    &--header {
      position: relative;
      @include _padding(0.25, 0.5);
      border-bottom: 1px solid darken($concrete, 10%);
      line-height: 1.3;

      .dc-icon {
        position: relative;
        top: 1px;
        display: inline-block;
        width: 15px;
        text-align: center;

        // Text icons
        &.dc-icon__sms,
        &.dc-icon__www,
        &.dc-icon__online {
          width: auto;
          top: 0;
        }
      }

      .date {
        float: right;
      }

      .title {
        display: block;
      }
    }

    &--body {
      @include _padding(0.25, 0.5);

      .fa-file-text {
        @include _margin(0.5, 0, 0.125);
        @include _padding-right(0.8);
        cursor: pointer;
        color: $action;

        &.import-failed {
          color: gray;
          opacity: 0.5;
          cursor: default;
        }
      }
    }
  }
}
