/*
---
name: _between-content()
category:
  - core/mixin
---
Sets Child elements margin

### scss
```scss
//
// @param  int  $coefficient
//

.u-between-content {
  @include _between-content(1);
}
```

### html
```ejs
<div class="u-between-content">
  ...
</div>
```
*/

@mixin _between-content($coefficient: 1) {
  > :not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) + :not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
    @include _margin-top($_base-margin-bottom * $coefficient);
  }

  > :first-child {
    margin-top: 0;
  }
}

/*
---
name: _content()
category:
  - core/mixin
---
Sets Child elements margin

### scss
```scss
//
// @param  int  $coefficient
//

.u-content {
  @include _content(1);
}
```

### html
```ejs
<div class="u-content">
  ...
</div>
```
*/

@mixin _content($coefficient: 1) {
  @include _between-content($coefficient);

  > h1 {
    @include _margin-top($_h1-margin-top * $coefficient);
    @include _margin-bottom($_h1-margin-bottom * $coefficient);
  }

  > h2 {
    @include _margin-top($_h2-margin-top * $coefficient);
    @include _margin-bottom($_h2-margin-bottom * $coefficient);
  }

  > h3 {
    @include _margin-top($_h3-margin-top * $coefficient);
    @include _margin-bottom($_h3-margin-bottom * $coefficient);
  }

  > h4 {
    @include _margin-top($_h4-margin-top * $coefficient);
    @include _margin-bottom($_h4-margin-bottom * $coefficient);
  }

  > h5 {
    @include _margin-top($_h5-margin-top * $coefficient);
    @include _margin-bottom($_h5-margin-bottom * $coefficient);
  }

  > h6 {
    @include _margin-top($_h6-margin-top * $coefficient);
    @include _margin-bottom($_h6-margin-bottom * $coefficient);
  }

  > ol ol,
  > ol ul,
  > ul ol,
  > ul ul {
    margin-bottom: 0;
  }
}
