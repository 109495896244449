//
//  < selectbox >
//
select.c-form-control {
  -webkit-appearance: none;
  appearance: none; // remove default arrow
  padding-right: _space(1.5); // reserved space for SVG arrow

  // add arrow
  &:not([multiple]) {
    background-repeat: no-repeat;
    background-position: (calc(100% - 10px)) 50%;
    background-size: 16px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPGc+ICA8dGl0bGU+YmFja2dyb3VuZDwvdGl0bGU+ICA8cmVjdCBmaWxsPSJub25lIiBpZD0iY2FudmFzX2JhY2tncm91bmQiIGhlaWdodD0iNDAyIiB3aWR0aD0iNTgyIiB5PSItMSIgeD0iLTEiLz4gPC9nPiA8Zz4gIDx0aXRsZT5MYXllciAxPC90aXRsZT4gIDxwYXRoIGZpbGw9IiNjY2NjY2MiIGlkPSJzdmdfMSIgZD0ibTk4LjksMTg0LjdsMS44LDIuMWwxMzYsMTU2LjVjNC42LDUuMyAxMS41LDguNiAxOS4yLDguNmM3LjcsMCAxNC42LC0zLjQgMTkuMiwtOC42bDEzNS45LC0xNTYuMmwyLjMsLTIuNmMxLjcsLTIuNSAyLjcsLTUuNSAyLjcsLTguN2MwLC04LjcgLTcuNCwtMTUuOCAtMTYuNiwtMTUuOGwwLDBsLTI4Ni44LDBsMCwwYy05LjIsMCAtMTYuNiw3LjEgLTE2LjYsMTUuOGMwLDMuMyAxLjEsNi40IDIuOSw4Ljl6Ii8+IDwvZz48L3N2Zz4=);

    &:hover,
    &:focus {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPGc+ICA8dGl0bGU+YmFja2dyb3VuZDwvdGl0bGU+ICA8cmVjdCBmaWxsPSJub25lIiBpZD0iY2FudmFzX2JhY2tncm91bmQiIGhlaWdodD0iNDAyIiB3aWR0aD0iNTgyIiB5PSItMSIgeD0iLTEiLz4gPC9nPiA8Zz4gIDx0aXRsZT5MYXllciAxPC90aXRsZT4gIDxwYXRoIGZpbGw9IiM5OTk5OTkiIGlkPSJzdmdfMSIgZD0ibTk4LjksMTg0LjdsMS44LDIuMWwxMzYsMTU2LjVjNC42LDUuMyAxMS41LDguNiAxOS4yLDguNmM3LjcsMCAxNC42LC0zLjQgMTkuMiwtOC42bDEzNS45LC0xNTYuMmwyLjMsLTIuNmMxLjcsLTIuNSAyLjcsLTUuNSAyLjcsLTguN2MwLC04LjcgLTcuNCwtMTUuOCAtMTYuNiwtMTUuOGwwLDBsLTI4Ni44LDBsMCwwYy05LjIsMCAtMTYuNiw3LjEgLTE2LjYsMTUuOGMwLDMuMyAxLjEsNi40IDIuOSw4Ljl6Ii8+IDwvZz48L3N2Zz4=);
    }
  }
}
