.opacity {
  opacity: 0.5;
  &--0 {
    opacity: 0 !important;
  }
  &--1 {
    opacity: 0.1 !important;
  }
  &--2 {
    opacity: 0.2 !important;
  }
  &--3 {
    opacity: 0.3 !important;
  }
  &--4 {
    opacity: 0.4 !important;
  }
  &--5 {
    opacity: 0.5 !important;
  }
  &--6 {
    opacity: 0.6 !important;
  }
  &--7 {
    opacity: 0.7 !important;
  }
  &--8 {
    opacity: 0.8 !important;
  }
  &--9 {
    opacity: 0.9 !important;
  }

  &--none {
    opacity: 1 !important;
  }
}
