/*
---
name: .c-pagination
category:
  - object/component
  - object/component/paginatiion
---
Paginatiion component

### html
```html
<div class="c-pagination">
  <span class="c-pagination__item" aria-current="page">1</span>
  <a class="c-pagination__item-link">2</a>
  <span class="c-pagination__item-ellipsis" aria-hidden="true">…</span>
  <a class="c-pagination__item-link">99</a>
  <a class="c-pagination__item-link">100</a>
</div>
```
*/

.#{$_prefix}c-pagination {
  @include _pagination();
}
