//
//  Hidden
//
@media only screen and (min-width: $breakpoint__xs) and (max-width: $breakpoint__sm--min) {
  .hidden--xs {
    display: none;
  }
}
@media only screen and (min-width: $breakpoint__sm) and (max-width: $breakpoint__md--min) {
  .hidden--sm {
    display: none;
  }
}
@media only screen and (min-width: $breakpoint__md) and (max-width: $breakpoint__lg--min) {
  .hidden--md {
    display: none;
  }
}
@media only screen and (min-width: $breakpoint__lg) and (max-width: $breakpoint__xl--min) {
  .hidden--lg {
    display: none;
  }
}
@media only screen and (min-width: $breakpoint__xl) and (max-width: $breakpoint__xxl--min) {
  .hidden--xl {
    display: none;
  }
}
@media only screen and (min-width: $breakpoint__xxl) {
  .hidden--xxl {
    display: none;
  }
}

//  Visible
//
@media only screen and (min-width: $breakpoint__xs) and (max-width: $breakpoint__sm--min) {
  .visible--xs {
    display: block !important;
  }
}
@media only screen and (min-width: $breakpoint__sm) and (max-width: $breakpoint__md--min) {
  .visible--sm {
    display: block !important;
  }
}
@media only screen and (min-width: $breakpoint__md) and (max-width: $breakpoint__lg--min) {
  .visible--md {
    display: block !important;
  }
}
@media only screen and (min-width: $breakpoint__lg) and (max-width: $breakpoint__xl--min) {
  .visible--lg {
    display: block !important;
  }
}
@media only screen and (min-width: $breakpoint__xl) and (max-width: $breakpoint__xxl--min) {
  .visible--xl {
    display: block !important;
  }
}
@media only screen and (min-width: $breakpoint__xxl) {
  .visible--xxl {
    display: block !important;
  }
}
