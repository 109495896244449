.ui-text {
  //...

  &__cloud:not(:last-child)::after {
    content: '•';
    display: inline-block;
    padding-left: _space(0.25);
    padding-right: _space(0.25);

    font-family: $ff--arial;
    line-height: 1;
    opacity: 0.25;

    transform: scale(1.4);
  }
}
