.p-menu-overlay {
  min-width: 12.5rem;
  width: unset;
}

.p-menuitem {
  @apply whitespace-nowrap;

  &.p-menuitem-destructive {
    .p-menuitem-link {
      .p-menuitem-icon,
      .p-menuitem-text {
        @apply text-red-600;
      }
    }
  }
}

.p-menuitem-link {
  @apply hover:bg-neutral-200 hover:no-underline;
}

.p-menuitem-link-active {
  @apply border-0 bg-neutral-200;
}

ui-menu {
  ui-button {
    .p-button.p-button-rounded:enabled:hover,
    .p-button.p-button-rounded:enabled:active,
    .p-button.p-button-rounded.p-highlight:enabled {
      @apply bg-primary-100/10 shadow-none outline-0;

      .ui-svg svg {
        @apply stroke-primary-500 stroke-[0.2px];
      }
    }
  }
}
.ui-menu {
  @apply rounded-lg p-0;
  .p-menu-list {
    @apply rounded-md p-2;

    .p-menuitem-separator {
      @apply -mx-2 my-2 border-t-gray-200;
    }

    .p-menuitem-link:not(.p-highlight):not(.p-disabled) {
      @apply rounded-md bg-transparent p-3 text-gray-700 hover:bg-gray-50;
    }

    .p-menuitem-destructive .p-menuitem-link:not(.p-highlight):not(.p-disabled) {
      @apply text-red-500 hover:bg-red-100/50;

      svg {
        @apply stroke-red-600;
      }
    }

    .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content {
      @apply hover:bg-transparent;
    }
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
      @apply bg-transparent;
    }
  }
}
