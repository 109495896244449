//
//  Validation
//
//  <div class="dc-form__validation --{{ state }}">
//
.dc-form__validation {
  padding-top: _space(0.25);
  line-height: $lh--medium;

  &.--info {
    color: $info;
  }
  &.--warning {
    color: $warning;
  }
  &.--failure {
    color: $danger;
  }
  &.--success {
    color: $success;
  }
}
