fb-review-history-card {
  .review-history-card {
    background-color: #f9f9fc;
    border-radius: 6px;
    padding: _space(1);

    &__date {
      span:not(.badge) {
        color: #5d646c;
      }
    }

    &__name {
      font-weight: 500;
      font-size: 1.4rem;
    }

    &__text {
      margin: 2rem 0 1rem 0;
      font-size: 1rem;

      p {
        font-weight: inherit;
        font-size: 1rem;
      }
    }

    .badge {
      display: flex;
      align-self: center;
    }
  }
}
