.p-inputswitch {
  @apply h-[1.692rem];

  .p-inputswitch-slider:before {
    @apply -mt-[0.625rem];
  }

  &.p-focus .p-inputswitch-slider {
    @apply ring ring-primary-600/20;
  }
}
