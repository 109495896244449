/*
---
name: .c-checkbox
category:
  - object/component
  - object/component/checkbox
---
Checkbox component

### html
```html
<label>
  <span class="c-checkbox">
    <input type="checkbox" class="c-checkbox__control">
    <span class="c-checkbox__label">...</span>
  </span>
</label>
```
*/

.#{$_prefix}c-checkbox {
  @include _checkbox();
}
