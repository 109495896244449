dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}

//
//  Shared
//
ul.ui-list,
ol.ui-list,
dl.ui-list dd {
  padding-inline-start: _space(1);
}

//
//  Unordered list
//
ul.ui-list {
  li {
    list-style-type: disc;
  }
}

//
//  Ordered list
//
ol.ui-list {
  list-style-type: decimal;
}

//
//  Definition list
//
dl.ui-list {
  dt {
    font-weight: bold;
  }
  //dd {  }
}
