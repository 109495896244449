//
//  Label - Value           ------ OLD COMPONENT   FR > replace by new component
//
// FR > create component
.dc-label-value {
  display: table;
  width: 100%;
  margin-bottom: _space(0.5);

  &__row {
    display: table-row;
    padding-bottom: _space(0.25);
  }
  &__flex {
    display: flex;
    width: 100%;
  }
}
.dc-label,
.dc-value {
  display: table-cell;
  padding-bottom: _space(0.25);

  &--highlighted {
    font-weight: bold;
  }
}
.dc-label {
  padding-right: _space(1);
  vertical-align: top;

  // kdyz zde je tento radek tam se me to zobrazi ve sloupecku po jednom pismenku
  // word-break: break-word;
  opacity: 0.7;
}
.dc-value {
  padding-right: _space(0.25);
}

/****************************************


Markup:
    <ul class="ui-label-value"
        data-label-size=" small | medium | large ">

        <li class="ui-label-value__row">
            <div class="ui-label-value__label">
                {{ label }}
            </div>
            <div class="ui-label-value__value">
                {{ value }}
            </div>
        </li>
    </ul>






modifier classes:
    --highlighted ~ higlight row / label / value

    examples:
        <ul class="ui-label-value__row --highlighted"> ~ highlight entire row (label, value)
        <ul class="ui-label-value__label --highlighted"> ~ highlight label
        <ul class="ui-label-value__value --highlighted"> ~ highlight value


*****************************************/

.ui-label-value {
  margin-left: 0;
  list-style: none;

  &__row {
    display: grid;
    grid-template-columns: 130px auto;
    grid-gap: _space(1);

    margin-bottom: _space(0.25);
    line-height: $lh--medium;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__label {
    color: $grey-5;
    text-wrap: pretty;
    &::after {
      content: ':';
    }
  }

  &__value {
    max-width: 65ch; // max. 65 characters per line = better readability
  }

  //
  // modifier classes
  .--highlighted {
    font-weight: bold;
  }

  //
  // label width
  &[data-label-size='small'] .ui-label-value__row {
    grid-template-columns: 90px auto;
  }
  &[data-label-size='medium'] .ui-label-value__row {
    grid-template-columns: 130px auto;
  }
  &[data-label-size='large'] .ui-label-value__row {
    grid-template-columns: 150px auto;
  }

  //
  // value on new line
  &[data-wrap-value] .ui-label-value__row {
    display: block;
  }
}
