/*
---
name: _size-prefix()
category:
  - core/function
---

Return prefix for the device size

### scss
```scss
//
// @param   string  $size  sm|md|lg
// @return  string
//

$prefix: _size-prefix(md); // -> --md
```
*/

@function _size-prefix($size) {
  $prefix: '-';

  $sizes-keys: map-keys($_sizes);
  $min-key: nth($sizes-keys, 1);
  @if ($min-key != $size) {
    $prefix: '--' + $size;
  }

  @return $prefix;
}
