/*
---
name: _clearfix()
category:
  - core/mixin
---
Clearfix helper

### scss
```scss
.clerfix {
  @include _clearfix();
}
```

### html
```ejs
<div class="clearfix">
  <img style="float: right">
  ....
</div>
```
*/

@mixin _clearfix() {
  &:before, &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}
