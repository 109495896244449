.review-card {
  background-color: #fff;
  margin-bottom: _space(1.5);
  margin-left: 6rem;
  max-width: 800px;
  position: relative;
  border-radius: $radius--lg;
  border-top-left-radius: 3px;
  box-shadow:
    0 4px 4px rgb(51 51 51 / 4%),
    0 4px 16px rgb(51 51 51 / 8%);
  transition: all 200ms linear;

  &__header {
    transition: all 200ms ease-in-out;
    padding: _space(0.75) _space(1) 0 _space(1);

    .d--flex {
      gap: 0.5rem;
    }

    .sender {
      .ico {
        color: #453e6c70;
        font-size: 1.2rem;
      }

      span {
        font-size: 1rem;
        color: #757575cc;
      }

      .fa {
        color: #757575cc;
      }
    }

    .review-card__ico {
      transition: all 150ms ease-out;
      background-color: #fff;
      border-radius: 200px;

      img {
        height: 3rem;
        border-radius: 200px;
      }

      &:hover {
        z-index: 2;
        transform: scale(2.2);
        box-shadow: 0 2px 8px #757575;
      }

      &:first-child {
        position: absolute;
        left: -5rem;
        top: 1rem;

        img {
          height: 3.5rem;
        }
      }
    }

    .review-card__name {
      line-height: 1.4;

      span {
        font-size: 1rem;
        opacity: 1;
      }

      .name {
        display: block;
        font-size: 1.2rem;
        color: #000;
        font-weight: 500;
      }

      .date {
        display: block;

        span {
          color: #808080;
          font-size: 1rem;
        }
      }
    }

    .ico {
      font-size: 2.4rem;
    }

    .dc-btn.dc-btn--faced.dc-btn--circled {
      background: #18232f;
      color: #fff;
      border-color: #18232f;
    }
  }

  &__content {
    padding: _space(1);
  }

  &__text {
    p {
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  &__body {
    flex-basis: 60%;

    textarea {
      max-width: 100%;
      height: 200px;
    }
  }

  &__history {
    flex-basis: 40%;
    border-radius: 6px;
    background: #f6effb;
    color: #5a2382;

    .h5.muted {
      font-weight: 400;
      font-size: 1rem;
    }

    fb-review-history-card:not(:last-child) {
      margin-bottom: _space(1);
    }

    > p {
      font-weight: 400;
      font-size: 1rem;
    }
  }

  &__footer {
    padding: _space(0.5) 0;
  }

  &:hover {
    .review-card {
      &--header {
        span {
          opacity: 1;
        }
      }
    }
  }

  &:not(.requested) {
    .review-card__history {
      flex-basis: 100%;
      background: transparent;
      color: #000;
    }
  }

  &.requested {
    .review-card__content {
      flex-direction: column-reverse;
      .review-card__history {
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  &__parent {
    padding: _space(1) _space(1) 0 _space(1);

    .review-card__parent--content {
      background-color: #f6f6f6;
      color: #808080;
      font-size: 1rem;
      border-left: 3px solid #5a2382;
      padding: _space(0.5) _space(0.5) _space(0.5) _space(1);
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;

      h5 {
        margin-bottom: _space(0.25);
      }

      .review-card__parent--text {
        p {
          color: #808080;
          font-size: 1rem;
        }
      }

      .review-card__parent--created {
        text-align: right;
      }
    }
  }

  &.inactive {
    background-color: #dedede85;
  }
}

fb-review-detail {
  .review-card {
    max-width: 100%;
  }
  .review-card:not(.requested) .review-card__history {
    flex-basis: 40%;
    border-radius: 6px;
    color: #000;
    padding: 0;
  }
}
