.ckp-data--detail-visible {
  tbody {
    tr {
      opacity: 0.3;

      &.active {
        opacity: 1;
      }
    }
  }
}
