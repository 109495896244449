.blur {
  filter: blur(3px) !important;
  transform: translateZ(0); // enable hardware acceleration
  will-change: filter;

  &--1 {
    filter: blur(1px) !important;
  }
  &--2 {
    filter: blur(2px) !important;
  }
  &--3 {
    filter: blur(3px) !important;
  }
  &--4 {
    filter: blur(4px) !important;
  }

  &--0 {
    filter: blur(0) !important;
  }

  &--on-hover {
    filter: blur(3px) !important;
    transition: all $speed ease-in-out;

    &:hover {
      filter: blur(0) !important;
    }
  }
}
