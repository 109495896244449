/*
---
name: .c-radio
category:
  - object/component
  - object/component/radio
---
Radio button component

### html
```html
<label>
  <span class="c-radio">
    <input type="radio" class="c-radio__control">
    <span class="c-radio__label">...</span>
  </span>
</label>
```
*/

.#{$_prefix}c-radio {
  @include _radio();
}
