//
// FR > clean-up loaders
//

//
//  Loader
//
// <div class="loader">Loading...</div>
.loader,
.loader::before,
.loader::after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation-fill-mode: both;
  animation: ui-spinner-legacy 1.8s infinite ease-in-out;
}

.loader {
  position: relative;
  top: -6px;

  color: lighten($antracit, 30%);
  font-size: 6px;
  margin: 0 auto;
  transform: translateZ(0);
  animation-delay: -0.16s;

  &::before,
  &::after {
    @include pseudo();
    top: 0;
  }

  &::before {
    left: -2em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &::after {
    left: 2em;
  }
}

//
// Search in progress
//
.dc-search-progress {
  text-align: center;

  .fa-search {
    margin-top: _space(-0.25);
    margin-left: _space(0.125);

    animation-name: animation--search-progress;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

/*


HTML
====


<div class="ui-loaders --modifier">
    Načítám ___WHAT___
</div>



modifiers
---------
--___modifier_name
--___modifier_name
--___modifier_name




*/

.ui-loader {
  padding: _space(1);

  font-family: $ff--yummo;
  font-size: _space(1);
  line-height: $lh--small;
  text-align: center;
  color: $grey-5;

  user-select: none;
}

//
//  VARIANTS
//
.ui-loader {
  &.--animate {
    background-image: linear-gradient(-60deg, $white, $grey-5);
    background-size: 300%;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: ui-loader--animation 1.69s ease-in-out infinite;
  }

  &.--animate-border {
    position: relative;
    z-index: 1;

    color: $white;

    --borderWidth: 1rem;
    background-color: $black;
    border-radius: var(--borderWidth);

    // FR > fix - background is not visible (z-index problem?)
    // source:
    // https://codepen.io/mike-schultz/pen/NgQvGO;
    &::after {
      content: '';
      display: block;

      position: absolute;
      top: calc(-1 * var(--borderWidth));
      left: calc(-1 * var(--borderWidth));

      height: calc(100% + var(--borderWidth) * 2);
      width: calc(100% + var(--borderWidth) * 2);

      background-image: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
      border-radius: calc(2 * var(--borderWidth));
      z-index: -1;

      animation: ui-loader--animation-border 3s ease alternate infinite;
      background-size: 300%;
    }
  }

  &.--animate-border--knight-rider {
    --borderWidth: 0.5rem;
    &::after {
      background-image: linear-gradient(90deg, rgba($white, 0.1), $danger, rgba($white, 0.1));
    }
  }
}

@keyframes ui-loader--animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
