.p-dialog {
  @apply rounded-lg;

  &.p-dialog-draggable {
    .p-dialog-header {
      @apply cursor-move;
    }
  }

  & &-header,
  & &-footer {
    @apply border border-solid;
  }

  & &-header {
    @apply rounded-tl-lg rounded-tr-lg border-l-0 border-r-0 border-t-0 border-b-neutral-200 px-6 py-5;
  }

  .p-dialog-header {
    @apply text-xl font-semibold text-neutral-600;
    .p-dialog-title {
      span {
        @apply text-neutral-900;
      }
    }

    .p-dialog-header-icon {
      @apply text-neutral-600 enabled:hover:bg-neutral-100;
    }
  }

  &:not(.p-dialog-maximized):has(.p-dialog-content-container > ui-message[severity='error']) .p-dialog-header {
    @apply border-b-red-400 bg-red-400 text-white;
  }
  &:not(.p-dialog-maximized):has(.p-dialog-content-container > ui-message[severity='warn']) .p-dialog-header {
    @apply border-b-orange-300 bg-orange-300 text-white;
  }
  &:not(.p-dialog-maximized):has(.p-dialog-content-container > ui-message[severity='info']) .p-dialog-header {
    @apply border-b-blue-100 bg-blue-100 text-primary-800;
  }
  &:not(.p-dialog-maximized):has(.p-dialog-content-container > ui-message[severity='empty']) .p-dialog-header {
    @apply border-b-blue-gray-100 bg-blue-gray-100 text-blue-gray-600;
  }

  & &-content {
    @apply relative bg-slate-50 p-6 text-gray-900;

    .p-dialog-content-container {
      &.loading,
      &.saving {
        @apply relative;
      }
      &.saving {
        ui-loader {
          @apply absolute left-1/2 top-1/2 z-9001 translate-x-[-50%] translate-y-[-50%];
        }
      }
      &.loading {
        ui-loader {
          @apply absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] p-0;
        }
      }

      > ui-message,
      > p-messages {
        .p-messages {
          @apply -mt-6 mb-12;
          .p-message {
            @apply rounded-t-none;
            .p-message-summary {
              @apply whitespace-pre-line pb-0.5 text-base/2.5;
            }
          }
        }
      }

      > #dialog-tabview {
        .p-tabview-panels {
          @apply bg-transparent p-6;
        }
        .p-tabview {
          &.p-tabview-nav-hidden {
            .p-tabview-nav-content {
              @apply hidden;
            }
            form {
              @apply -mt-3;
            }
          }
          .p-tabview-nav {
            li {
              @apply w-1/2;
              &.p-highlight .p-tabview-nav-link {
                @apply bg-blue-50;
              }

              .p-tabview-nav-link:not(.p-disabled) {
                @apply justify-center px-6 focus:shadow-none;
              }
            }
          }
        }
      }
    }

    &:has(.p-dialog-content-container.loading),
    &:has(.p-dialog-content-container.saving) {
      @apply flex min-h-40 flex-col items-stretch justify-center overflow-hidden;

      ui-block-ui-container > div {
        @apply contents;

        ui-loader {
          @apply z-9001;
        }

        .p-component-overlay {
          --maskbg: theme(colors.slate.105);
        }
      }
    }
    &:has(.p-dialog-content-container.loading.saving) {
      @apply block;
    }
  }

  & &-footer {
    @apply flex justify-between rounded-bl-lg rounded-br-lg border-l-0 border-r-0 border-t border-t-neutral-200 px-6 py-4;

    p-checkbox {
      .p-checkbox {
        @apply h-[16px] w-[16px];

        .p-checkbox-box {
          @apply h-full w-full;

          .p-checkbox-icon {
            @apply text-xs;
          }
        }
      }
      .p-checkbox-label {
        @apply font-light text-gray-600;
      }
    }

    .p-button {
      @apply m-0 border-2 border-transparent py-2.5 leading-4;

      &-secondary.p-button-outlined {
        @apply border-2 border-transparent text-neutral-600 hover:border-neutral-100 hover:bg-neutral-100;
      }
    }
  }
}

.p-confirm-dialog {
  .p-dialog-footer {
    .p-confirm-dialog-accept {
      @apply bg-primary-500 hover:bg-primary-600;
    }
  }
}

body > .p-dialog-mask .p-dialog-content ui-block-ui-container > div {
  @apply -m-6 p-6;
}
