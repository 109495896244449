//
//  < textarea >
//
textarea.c-form-control {
  min-height: _space(4);
  resize: vertical;

  &.c-form-control--min-h-auto {
    min-height: auto;
  }
}
