/*
---
name: .c-ic-angle-right
category:
  - object/component
  - object/component/icon
  - object/component/icon/ic-angle-right
---
Icon (Angle Right) component

### html
```html
<span class="c-ic-angle-right" aria-hidden="true"></span>
```
*/

.#{$_prefix}c-ic-angle-right {
  display: inline-block;
  padding: 0 .25em;
  transform: translateY(-.15em);

  &::before {
    content: '';
    display: block;
    height: .4em;
    width: .4em;
    border-color: _var-color-text();
    border-style: solid;
    border-width: 1px 1px 0 0;
    transform: rotate(45deg);
  }
}

/*
---
name: .c-ic-check
category:
  - object/component
  - object/component/icon
  - object/component/icon/ic-check
---
Icon (Check) component

### html
```html
<span class="c-ic-check" aria-hidden="true"></span>
```
*/

.#{$_prefix}c-ic-check {
  display: inline-block;
  padding: 0 .25em;
  transform: translateY(-.2em);

  &::before {
    content: '';
    display: block;
    height: .4em;
    width: .25em;
    border-color: _var-color-text();
    border-style: solid;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
}

/*
---
name: .c-ic-angle-down
category:
  - object/component
  - object/component/icon
  - object/component/icon/ic-angle-down
---
Icon (Angle Down) component

### html
```html
<span class="c-ic-angle-down" aria-hidden="true"></span>
```
*/

.#{$_prefix}c-ic-angle-down {
  display: inline-block;
  padding: 0 .25em;
  transform: translateY(-.2em);

  &::before {
    content: '';
    display: block;
    height: .4em;
    width: .4em;
    border-color: _var-color-text();
    border-style: solid;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
}
