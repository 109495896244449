.dc-popup-menu {
  //...

  &__item {
    //...
    line-height: $lh--medium;
  }

  &__popup {
    //...
  }
}

// ----------------------------------

// FR > all below - clean-up
// + recode whole component

@mixin iconSmallDot() {
  width: 4px;
  height: 4px;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  background-color: $action;
  border-radius: $radius--lg;
}

dc-popup-menu {
  width: auto;
  height: 24px;
  margin: 0 0 0 10px;
  padding: 0;
  display: block;
  position: relative;
  float: right;
  ui-button-legacy {
    .dc-btn {
      padding: 0;
      line-height: 24px;
      text-align: center;
      & > .icon--more {
        width: 32px;
        height: 24px;
        margin: 0;
        padding: 0;
        display: block;
        position: relative;
        line-height: 20px;
        & > .elm {
          @include iconSmallDot();
          display: inline-block;
          &::before {
            content: '';
            @include iconSmallDot();
            position: absolute;
            left: -6px;
          }
          &::after {
            content: '';
            @include iconSmallDot();
            position: absolute;
            left: 6px;
          }
        }
      }
      & > .fa.fa-ellipsis-h {
        font-size: 1.1em;
        margin-bottom: -1px;
      }
    }
  }
  .dc-popup-menu__popup {
    position: absolute;
    z-index: z-index(dropdown);
    left: -134px;
    top: 24px;

    min-width: 170px;
    display: none;
    background-color: $white;
    border: 1px solid $action;
    text-align: left;
    border-radius: $radius--lg;
    border-top-right-radius: 0;
    box-shadow: 0 10px 20px 5px transparentize($action, 0.9);

    & > div {
      position: relative;
      z-index: z-index(dropdown) + 1;

      span,
      a {
        width: auto;
        height: auto;
        margin: 0;
        padding: 7px 13px;
        display: block;
        border-bottom: 1px solid transparentize($action, 0.9);
        &:last-child {
          border-bottom: 0;
        }
        &.nonlink {
          color: $text--disabled;
        }
        &.link {
          &.active {
          }
          &.disabled {
          }
        }
      }
      span {
      }
      a {
      }
    }
  }
  &.dc-popup-menu--opened {
    .dc-popup-menu__popup {
      display: block;
    }
    ui-button-legacy {
      &:before {
        content: '';
        width: 89%;
        height: 1px;
        margin: 0;
        padding: 0;
        display: block;
        position: absolute;
        z-index: z-index(dropdown) + 1;
        bottom: -1px;
        right: 1px;
        background-color: $white;
      }
      .dc-btn {
        background-color: $white;
        border-top-left-radius: $radius--lg;
        border-top-right-radius: $radius--lg;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom: 0;
      }
    }
  }
}
