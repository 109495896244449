/*
---
name: _sanitize-animation-name()
category:
  - core/function
---
Sanitize animation name

### scss
```scss
//
// @param   string  $name
// @return  string
//

$animation-name: _sanitize-animation-name($name);
animation: $animation-name .2s ease-in 0s;
```
*/

@function _sanitize-animation-name($name) {
  $name: replace(('.', '(', ')', ',', ' ', '#', '-', '[', ']'), '_', $name);
  @return $name;
}
