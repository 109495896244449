/*
---
name: .c-select
category:
  - object/component
  - object/component/select
---
Select box component

### html
```html
<div class="c-select">
  <select class="c-select__control">
    <option value="1">label-1</option>
    <option value="2">label-2</option>
    <option value="3">label-3</option>
  </select>
  <span class="c-select__toggle"></span>
</div>
```
```html
<div class="c-select c-select--block">
  <select class="c-select__control">
    <option value="1">label-1</option>
    <option value="2">label-2</option>
    <option value="3">label-3</option>
  </select>
  <span class="c-select__toggle"></span>
</div>
```
*/

.#{$_prefix}c-select {
  @include _select();

  &--block {
    display: block;

    > .c-select__control {
      width: 100%;
    }
  }
}
