html {
  @apply scroll-smooth;
}

*,
*::before,
*::after {
  @apply box-border;
}

html,
body {
  @apply h-full;
}

body {
  @apply m-0 flex max-h-full max-w-full bg-neutral-50;
}

dc-app,
fb-app {
  @apply relative min-h-screen grow;
}

.l-page,
router-outlet + * {
  @apply z-5 flex-auto;
}

.l-page {
  @apply mb-8 mt-4;
}
