//
//
//  INDEX:
//
//  * font-family
//  * font-size
//  * font-style
//  * line-height
//  * font-weight
//  * text-align
//  * text-transform
//  * letter-spacing
//  * white-space
//  * vertical-align
//
//  Custom Utilities
//  .text--truncate
//_____________________________

//
//  FONT FAMILY
//
.font {
  &--open-sans {
    font-family: $ff--default;
  }
  &--yummo {
    font-family: $ff--yummo;
    font-weight: 400 !important;
    font-style: normal !important;
  }
  &--calibri {
    font-family: $ff--calibri;
  }
  &--monospace {
    font-family: $ff--monospace;
  }
  &--arial {
    font-family: $ff--arial;
  }
}

//
//  FONT SIZE
//
.fs {
  &--8 {
    font-size: 8px !important;
  }
  &--9 {
    font-size: 9px !important;
  }
  &--10 {
    font-size: 10px !important;
  }
  &--11 {
    font-size: 11px !important;
  }
  &--12 {
    font-size: 12px !important;
  }
  &--13 {
    font-size: 13px !important;
  }
  &--14 {
    font-size: 14px !important;
  }
  &--16 {
    font-size: 16px !important;
  }
  &--18 {
    font-size: 18px !important;
  }
  &--20 {
    font-size: 20px !important;
  }
  &--24 {
    font-size: 24px !important;
  }
  &--32 {
    font-size: 32px !important;
  }

  &--50 {
    font-size: 50px !important;
  }
  &--64 {
    font-size: 64px !important;
  }

  &--1r {
    font-size: 1rem !important;
  }
}

//
//  FONT STYLE
//
.fs {
  &--normal {
    font-style: normal !important;
  }
  &--italic {
    font-style: italic !important;
  }
}

//
//  LINE HEIGHT
//
.lh {
  &--1 {
    line-height: 1 !important;
  }
  &--small {
    line-height: $lh--small !important;
  }
  &--medium {
    line-height: $lh--medium !important;
  }
  &--large {
    line-height: $lh--large !important;
  }
  &--0 {
    line-height: 0 !important;
  }
}

//
//  FONT WEIGHT
//
.fw {
  &--normal {
    font-weight: 400 !important;
  }
  &--bold {
    font-weight: 700 !important;
  }
}

//
//  TEXT ALIGN
//
.ta {
  &--left {
    text-align: left !important;
  }
  &--right {
    text-align: right !important;
  }
  &--center {
    text-align: center !important;
  }
}

//
//  TEXT TRANSFORM
//
.tt {
  &--uppercase {
    text-transform: uppercase !important;
  }
  &--lowercase {
    text-transform: lowercase !important;
  }
  &--capitalize {
    text-transform: capitalize !important;
  }
  &--none {
    text-transform: none !important;
  }
}

//
//  LETTER SPACING
//
.ls {
  &--1 {
    letter-spacing: 1px !important;
  }
  &--2 {
    letter-spacing: 2px !important;
  }
  &--3 {
    letter-spacing: 3px !important;
  }
  &--4 {
    letter-spacing: 4px !important;
  }
  &--0 {
    letter-spacing: 0 !important;
  }
}

//
//  WHITE SPACE
//
.ws {
  &--nowrap {
    white-space: nowrap !important;
  }
  &--pre {
    white-space: pre !important;
  }
  &--pre-line {
    white-space: pre-line !important;
  }
  &--pre-wrap {
    white-space: pre-wrap !important;
  }
  &--normal {
    white-space: normal !important;
  }
}

//
//  VERTICAL ALIGN
//
.va {
  &--top {
    vertical-align: top !important;
  }
  &--middle {
    vertical-align: middle !important;
  }
  &--bottom {
    vertical-align: bottom !important;
  }
  &--baseline {
    vertical-align: baseline !important;
  }
  &--text-top {
    vertical-align: text-top !important;
  }
  &--text-bottom {
    vertical-align: text-bottom !important;
  }
  &--sub {
    vertical-align: sub !important;
  }
  &--sup {
    vertical-align: super !important;
  }
}

//
//  Text truncate
//
.text--truncate {
  @include truncate();
}

//
//
//
//    FR > CLEAN-UP
//
//
.td--underline {
  text-decoration: underline;
}
.td--none {
  text-decoration: none;
}

.text {
  &--break-word {
    word-break: break-word;
  }
  &--line-through {
    text-decoration: line-through;
  }
  &--improve-readability {
    max-width: 70ch;
  }
}

.text--muted {
  opacity: 0.7;
  filter: grayscale(100%);
}
a.text--muted {
  cursor: not-allowed;
}

a.link--ext {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: -1rem;
    font-size: 0.75rem;
    top: -1px;
    color: #808080;
  }

  &:hover {
    &::after {
      content: '\f08e';
      font-family: $ff--icons--fontAwesome;
    }
  }
}
