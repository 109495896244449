/*
---
name: _hover()
category:
  - core/mixin
---
Hover event helper

### scss
```scss
img {
  @include _hover() {
    opacity: .8;
  }
}
```
*/

@mixin _hover() {
  @media (hover: hover) and (pointer: fine) {
    &:hover, &:active, &:focus {
      @content;
    }
  }
}
