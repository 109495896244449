// all cursors:
// https://www.w3schools.com/cssref/tryit.asp?filename=trycss_cursor
.cursor {
  &--default {
    cursor: default !important;
  }
  &--pointer {
    cursor: pointer !important;
  }
  &--wait {
    cursor: wait !important;
  }
  &--disabled {
    cursor: not-allowed !important;
  }
  &--help {
    cursor: help !important;
  }
  &--copy {
    cursor: copy !important;
  }
  &--zoom-in {
    cursor: zoom-in !important;
  }
  &--zoom-out {
    cursor: zoom-out !important;
  }
  &--move {
    cursor: move !important;
  }
}
