/*
---
name: _transition()
category:
  - core/mixin
---
Transition helper

### scss
```scss
//
// @param  string  $property
//

a {
  @include _transition(color, font-size);
  color: #f00;
  font-size: 12px;

  @include _hover() {
    color: #f9c;
    font-size: 14px;
  }
}
```
*/

@mixin _transition($property: all, $property2...) {
  $properties: join($property, $property2);
  $transitions: ();

  @each $property in $properties {
    $transitions: append(
      $transitions,
      ($property _var-transition-duration() _var-transition-function-timing() _var-transition-delay()),
      comma
    );
  }

  transition: $transitions;
}
