ui-angle-legacy {
  display: inline-block;

  width: 20px;
  height: 20px;
  margin-right: _space(0.25);
  text-align: center;

  background: $action;
  border-radius: 50%;
  color: $white;

  a {
    font-size: 12px;
    color: $white;

    &:hover {
      text-decoration: none;
    }
  }

  .dc-icon {
    position: relative;
    &__chevron-down {
      top: 1px;
    }
  }

  &.ui-angle-legacy--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
