// FR > REFUCK ALL

.pdf-viewer {
  display: flex;
  align-items: stretch;

  position: fixed;
  top: 0;
  left: 0;
  z-index: z-index(modal);
  overflow: hidden;

  width: 100vw;
  height: 100vh;

  background: $temp__sedivka-1;
  color: $grey-2;

  // todo: create common component label-value
  .c-label {
    opacity: 0.75;
  }
  .c-value {
  }

  // error message
  ui-box-legacy {
    margin: auto;
    @include _margin-top(4);
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    order: 2;
  }

  &__main-header {
    position: relative;
    min-height: 48px;
    margin: _space(1);
    padding-left: _space(2.5);

    &-icon {
      position: absolute;
      top: 0;
      left: 0;

      font-size: 3.75rem;
      opacity: 0.5;
    }

    .c-section__title {
      text-align: left;
      margin-bottom: 0;
      color: $grey-2;
    }

    .dc-icon {
      font-size: 10px;
    }

    .dc-btn {
      margin-left: _space(0.5);
      margin-top: 10px;
    }

    ui-box-legacy {
      margin: 1rem auto 0 auto;
      display: inline-block;
    }
  }

  .ui-heading-legacy__subtitle {
    padding-top: 5px;

    .c-label {
      white-space: nowrap;
      margin-right: _space(0.25);
    }

    .c-value {
      line-height: 1.2;
    }
  }

  &__document {
    position: relative;
    flex-grow: 1;
    margin: _space(1);
    margin-top: 0;

    background: $temp__sedivka-1; // same color as PDF JS plugin

    .document-inner {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      .document-inner-scrollable {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        img {
          cursor: pointer;
        }
      }
    }

    &-loading-status,
    object {
      position: absolute;
      top: 0;
      right: -1px;
      left: 0;
      bottom: 0;
    }

    // Loader, Failure message
    &-loading-status {
      right: -1px;
      z-index: 2;

      display: flex;
      align-items: center;
      justify-content: center;

      background: $temp__sedivka-1;
    }

    // inserted PDF
    object {
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }

  &__image {
    position: relative;
    flex-grow: 1;
    margin: _space(1);
    margin-top: 0;

    background: $temp__sedivka-1; // same color as PDF JS plugin

    .document-inner {
      height: 100%;
      position: absolute;

      img {
        cursor: pointer;
        max-height: 100%;
      }
    }

    &-loading-status,
    object {
      position: absolute;
      top: 0;
      right: -1px;
      left: 0;
      bottom: 0;
    }

    // Loader, Failure message
    &-loading-status {
      right: -1px;
      z-index: 2;

      display: flex;
      align-items: center;
      justify-content: center;

      background: $temp__sedivka-1;
    }
  }

  &__sidebar {
    width: 33vw;
    max-width: 200px;
    order: 1;
    flex-grow: 0;
    @include _padding(1);
    background: darken($temp__sedivka-1, 8%);
    border-right: 1px solid darken($temp__sedivka-1, 15%);

    position: relative;
    .sidebar-scrollable {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      .sidebar-inner {
        width: calc(100% + 25px);
        height: 100%;
        margin: 0;
        padding: 0;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        .inner-scrollable {
          width: calc(100% - 25px);
          height: 100%;
          margin: 0;
          padding: 10px 10px;
          position: relative;
        }
      }
    }

    &--item {
      display: block;
      margin-bottom: _space(1);
      overflow: hidden;

      background: lighten($temp__sedivka-1, 5%);
      border: 3px solid lighten($temp__sedivka-1, 5%);
      border-radius: $radius--md;

      &-thumbnail {
        min-height: 100px;
        position: relative;
        // padding-bottom: 56.25%;
        background: darken($temp__sedivka-1, 3%);

        .dc-file-attachements__thumbnail {
          position: relative;
        }

        .item-preloader {
          width: 100%;
          height: 100px;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 0;
          left: 0;
          line-height: 100px;
          text-align: center;
          .loader {
            display: inline-block;
          }
        }
      }
      &-details {
        @include _padding(0.25, 0.25, 0.125);
      }

      &-name {
        display: block;
        font-weight: bold;
        line-height: 1.4;
        color: $white;
      }

      &-fileinfo {
        color: $grey-2;
        line-height: 1.4;
      }

      &:hover {
        text-decoration: none;
        border-color: lighten($action, 15%);
      }

      &.--active {
        border-color: lighten($action, 15%);
      }
    }

    &--item:hover {
      &-name {
        text-decoration: underline;
        color: $danger;
      }
    }
  }

  .document__toolbar {
    display: inline-block;
    text-align: center;
    background: #555;
    padding: 1rem 2rem;
    border-radius: 0.5rem;

    &-item {
      vertical-align: middle;
      display: inline-block;
      padding: 0 1rem;

      &:not(:last-child) {
        border-right: 1px solid #6b6b6b;
      }

      label {
        color: #92928a;
      }

      ui-button-legacy button.dc-btn {
        margin: 0;
      }
    }
  }
}
