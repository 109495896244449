/*
  INDEX
  -----

  > Row with background

  > remove all classes starting with prefix "temp__"
  > remove all classes starting with prefix "tmp-"  (PNG icons)

  > remove all inline styles   (style="...")

*/

//
//  Mirror object
//
.transform--reflect {
  transform: rotateY(-180deg);
}

//
//  Row with background
//
.c-row--background {
  @include _padding(0.25, 0.5);
  margin-left: _space(-0.5);
  margin-right: _space(-0.25);

  background: $white;
  border-radius: $radius--md;
}

//
//  Small icon for "Detail klienta"
//
.ui-partner-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: none;
  border-radius: 50%;
  overflow: hidden;
}

//
//  extend label value for label sizes
//
.ui-label__width--sm {
  grid-template-columns: 70px auto !important;
}
