*[role='tooltip'] {
  @apply absolute;
}

.p-tooltip {
  @apply max-w-fit;

  .p-tooltip-arrow {
    @apply h-[6px] w-[16px] border-none bg-tooltip-arrow bg-right-top bg-no-repeat;
  }

  &.p-tooltip-left .p-tooltip-arrow {
    @apply -mr-[7.5px] -rotate-90;
  }
  &.p-tooltip-right .p-tooltip-arrow {
    @apply -ml-[7.5px] rotate-90 bg-inherit;
  }
  &.p-tooltip-top .p-tooltip-arrow {
    @apply -mb-[5px] -ml-[5px] rotate-0;
  }
  &.p-tooltip-bottom .p-tooltip-arrow {
    @apply -mt-[2.5px] rotate-180;
  }

  .p-tooltip-text {
    @apply max-w-[40rem] rounded-md bg-gray-900 px-4 py-2.5 text-white;
  }

  &.p-tooltip-max {
    @apply max-w-[40rem];

    .p-tooltip-text {
      @apply whitespace-normal;
    }
  }
}
