//
.ui-avatar {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  background: $grey-2;
  border-radius: 50%;
  overflow: hidden;

  //  variants
  //
  &--icon {
    padding: _space(0.5);
  }

  &--glyph {
    .fa,
    .dc-icon {
      font-size: 24px;
    }
  }

  &--missing {
    padding: _space(0.25);
    padding-bottom: 0;
    align-items: end;
  }
}
