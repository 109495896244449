/*
---
name: _replace()
category:
  - core/function
---
Replace in a string

### scss
```scss
//
// @param   string|list  $substr
// @param   string       $newsubstr
// @param   string       $string
// @return  string
//

$value: _replace('p', 'a', 'apple'); // => aaale
```
*/

@function _replace($substr, $newsubstr, $string) {
  @if ('list' == type-of($substr)) {
    @each $_s in $substr {
      $string: _replace($_s, $newsubstr, $string);
    }
    @return $string;
  } @else {
    $position-found: str-index($string, $substr);
    $processed: ();

    @if (_is-null($position-found)) {
      @return $string;
    }

    @while (not _is-null($position-found) and $position-found > 0) {
      $length-substr: str-length($substr);
      $processed: append($processed, str-slice($string, 0, $position-found - 1));
      $processed: append($processed, $newsubstr);
      $string: str-slice($string, $position-found + $length-substr);
      $position-found: str-index($string, $substr);
    }

    $processed: append($processed, $string);
    $string: "";

    @each $s in $processed {
      $string: #{$string}#{$s};
    }

    @return $string;
  }
}

@function replace($substr, $newsubstr, $string) {
  @return _replace($substr, $newsubstr, $string);
}
