/*
---
name: _navbar()
category:
  - core/abstract
---
Abstract navbar component

### scss
```scss
.c-navbar {
  @include _navbar();

  &__item > a {
    color: $_color-text;
    @include _padding(.5, 1);
  }
}
```

### js
```js
import BasisNavbar from 'node_modules/sass-basis/src/js/_navbar.js';
new BasisNavbar({
  wrapper: '.c-navbar',
});
```

### html
```ejs
<ul class="c-navbar">
  <li class="c-navbar__item" aria-haspopup="true">
    <a href="#">menu</a>
    <ul class="c-navbar__submenu" aria-hidden="true">
      <li class="c-navbar__subitem" aria-haspopup="true">
        <a href="#">submenu</a>
        <ul class="c-navbar__submenu" aria-hidden="true">
          <li class="c-navbar__subitem"><a href="#">submenu</a></li>
          <li class="c-navbar__subitem"><a href="#">submenu</a></li>
        </ul>
      </li>
      <li class="c-navbar__subitem" aria-haspopup="true">
        <a href="#">submenu</a>
        <ul class="c-navbar__submenu" aria-hidden="true">
          <li class="c-navbar__subitem"><a href="#">submenu</a></li>
          <li class="c-navbar__subitem"><a href="#">submenu</a></li>
        </ul>
      </li>
    </ul>
  </li>
  <li class="c-navbar__item" aria-haspopup="true">
    <a href="#">menu</a>
    <ul class="c-navbar__submenu" aria-hidden="true">
      <li class="c-navbar__subitem" aria-haspopup="true">
        <a href="#">submenu</a>
        <ul class="c-navbar__submenu" aria-hidden="true">
          <li class="c-navbar__subitem"><a href="#">submenu</a></li>
        </ul>
      </li>
    </ul>
  </li>
  <li class="c-navbar__item">
    <a href="#">menu</a>
  </li>
</ul>
``````html
<ul class="c-navbar" data-popup-mode="click">
  <li class="c-navbar__item" aria-haspopup="true">
    <a href="#">menu</a>
    <button class="c-navbar__toggle" aria-expanded="false">
      <span class="c-ic-angle-right" aria-hidden="true"></span>
    </button>
    <ul class="c-navbar__submenu" aria-hidden="true">
      <li class="c-navbar__subitem" aria-haspopup="true">
        <a href="#">submenu</a>
        <button class="c-navbar__toggle" aria-expanded="false">
          <span class="c-ic-angle-right" aria-hidden="true"></span>
        </button>
        <ul class="c-navbar__submenu" aria-hidden="true">
          <li class="c-navbar__subitem"><a href="#">submenu</a></li>
          <li class="c-navbar__subitem"><a href="#">submenu</a></li>
        </ul>
      </li>
      <li class="c-navbar__subitem" aria-haspopup="true">
        <a href="#">submenu</a>
        <button class="c-navbar__toggle" aria-expanded="false">
          <span class="c-ic-angle-right" aria-hidden="true"></span>
        </button>
        <ul class="c-navbar__submenu" aria-hidden="true">
          <li class="c-navbar__subitem"><a href="#">submenu</a></li>
          <li class="c-navbar__subitem"><a href="#">submenu</a></li>
        </ul>
      </li>
    </ul>
  </li>
  <li class="c-navbar__item" aria-haspopup="true">
    <a href="#">menu</a>
    <button class="c-navbar__toggle" aria-expanded="false">
      <span class="c-ic-angle-right" aria-hidden="true"></span>
    </button>
    <ul class="c-navbar__submenu" aria-hidden="true">
      <li class="c-navbar__subitem" aria-haspopup="true">
        <a href="#">submenu</a>
        <button class="c-navbar__toggle" aria-expanded="false">
          <span class="c-ic-angle-right" aria-hidden="true"></span>
        </button>
        <ul class="c-navbar__submenu" aria-hidden="true">
          <li class="c-navbar__subitem"><a href="#">submenu</a></li>
        </ul>
      </li>
    </ul>
  </li>
  <li class="c-navbar__item">
    <a href="#">menu</a>
  </li>
</ul>
```
*/

@mixin _navbar() {
  @include _list-unstyled();
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  $root: #{&};

  &__item {
    position: relative;
    display: flex;
    flex: 1 1 auto;

    > a {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-decoration: none;
    }

    > #{$root}__submenu {
      @include _position(absolute, 100%, auto, null, auto);

      &--turn-left {
        #{$root}__submenu {
          right: 100%;
          left: auto;
        }
      }
    }
  }

  &__subitem {
    position: relative;

    > a {
      display: block;
      text-decoration: none;
    }

    > #{$root}__submenu {
      @include _position(absolute, 0, auto, null, 100%);
    }
  }

  &__submenu {
    @include _list-unstyled();
    min-width: 240px;
    opacity: 0;
    visibility: hidden;
    @include _transition(opacity);

    &[aria-hidden="false"] {
      visibility: visible;
      opacity: 1;
    }
  }

  &__toggle {
    cursor: pointer;
    appearance: none;
    background: transparent;
    border: none;
    color: inherit;
    padding: 0;
    vertical-align: middle;
  }
}
