/*
---
name: _ghost()
category:
  - core/mixin
---
### Ghost button helper

### scss
```scss
//
// @param  hash  $params
//   border-size  length
//   color        hex
//

.c-ghost-btn {
  @include _btn();
  @include _ghost((
    border-size: 1px,
    color: #fff,
  ));
  @include _padding(1, 2);
}
```

### html
```ejs
<a class="c-ghost-btn" role="button">btn</a>
```
*/

@mixin _ghost($params: ()) {
  $params: map-merge(
    (
      'border-size' : 1px,
      'color'       : _var-color-white(),
    ),
    $params
  );

  background-color: transparent !important;
  border: map-get($params, 'border-size') transparent;

  @each $color in map-get($params, 'color') {
    border-color: $color;
    color: $color;
  }
}
