/*
---
name: _page-header()
category:
  - core/abstract
---
Abstract page header component

### scss
```scss
.c-page-header {
  @include _page-header();
  @include _padding(2, 0);

  &__title {
    @include _font-size-line-height($_base-font-size-px * 2);
  }
}
```

### html
```ejs
<div class="c-page-header">
  <h1 class="c-page-header__title">...</h1>
</div>
```
*/

@mixin _page-header() {
  &__title {
    margin: 0;
    max-width: 100%; // For IE11
  }
}
