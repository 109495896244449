//
//  < progress >
//

progress {
  width: 100%;

  //
  // state: indeterminate
  &:not([value]) {
    //...
    filter: grayscale(100%);
  }

  //
  // state: determinate
  &[value] {
    /*
    // reset the default appearance
    -webkit-appearance: none;
    appearance: none;

    // container
    &::-webkit-progress-bar {
      overflow: hidden;
      background: $grey-1;
      border: 1px solid $grey-3;
      border-radius: $radius--lg;
    }
    &::-webkit-progress-value {
      background: $brand;

      &::before {
        content: '80%';
        position: absolute;
        right: 0;
        top: -125%;
      }
    }
    */
  }
}
