@mixin quill-editor {
  quill-editor {
    .ql-toolbar.ql-snow {
      border-color: #e1e4ea;
      background-color: #f9f9fc;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      display: flex;
      border-color: #e1e4ea;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top: 1px solid transparent;

      &:focus {
        border-color: #becd00;
        box-shadow:
          0 4px 4px rgb(51 51 51 / 4%),
          0 4px 24px rgb(51 51 51 / 10%);
      }

      &:hover {
        border-color: #becd00;
        box-shadow:
          0 4px 4px rgb(51 51 51 / 4%),
          0 4px 24px rgb(51 51 51 / 10%);

        &:focus {
          border-color: #becd00;
          box-shadow:
            0 4px 4px rgb(51 51 51 / 4%),
            0 4px 24px rgb(51 51 51 / 10%);
        }
      }

      &.ql-disabled {
        &:focus {
          border-color: #e1e4ea;
          box-shadow: none;
          border-top: 1px solid transparent;
        }

        &:hover {
          border-color: #e1e4ea;
          box-shadow: none;
          border-top: 1px solid transparent;

          &:focus {
            border-color: #e1e4ea;
            box-shadow: none;
            border-top: 1px solid transparent;
          }
        }

        .ql-editor {
          p {
            cursor: not-allowed;
          }
        }
      }

      .ql-editor {
        width: 100%;
        height: auto;

        p {
          margin-bottom: 0;
        }

        &.ql-blank::before {
          color: #75757580;
          font-style: normal;
        }

        strong {
          font-weight: 600;
        }
      }
    }

    &.ql-disabled {
      .ql-toolbar.ql-snow {
        display: none;

        .ql-formats {
          opacity: 0.2;

          button {
            cursor: not-allowed;
          }
        }
      }

      .ql-toolbar.ql-snow + .ql-container.ql-snow {
        background-color: #fafafa;
        border-radius: 6px;
        border-top-color: #e1e4ea;

        &:hover {
          border-top-color: #e1e4ea;
        }
      }
    }

    #tab-panel {
      gap: 1px;
      padding-top: 6px;
    }
  }
}

// shared imports
@import '../app/feedback/components/shared/review-card';
@import '../app/feedback/components/shared/pd-card';
@import '../app/feedback/components/shared/dialogs/dialogs';

// component imports
@import '../app/feedback/containers/menu/feedback-menu.container';
@import '../app/feedback/components/node/node-list/node-list.component';
@import '../app/feedback/components/node/node-card/node-card.component';
@import '../app/feedback/pages/give/containers/give-drafts/components/review-detail/review-detail.component.scss';
@import '../app/feedback/components/reviews/review-view/review-view.component.scss';
@import '../app/feedback/pages/give/containers/give-drafts/components/review-history-card/review-history-card.component';

fb-page-feedback {
  font-family: 'Poppins', 'Verdana', sans-serif;
  @include _padding(1.5, 1.5, 1.5, 1.5);
  background: #f9f9fc;
  color: #000000;
  font-size: 14px;

  .fb {
    //
    //  Menu column
    //
    &__menu {
      flex: 0 1 170px;
      //z-index: 2; // fixme: z-index s modalem
    }

    //
    //  Middle column
    //
    &__primary {
      flex: 1;
      z-index: 1;
      background: #fff;
      box-shadow: 0 2px 18px 0 rgb(0 0 0 / 15%);
      border-radius: $radius--lg;
      padding: 1rem 3rem 3rem 3rem;
      min-height: 100vh;

      .fb__error-report {
        margin-bottom: 1rem;
        font-size: 0.8rem;
        span {
          opacity: 0.4;
        }
        a {
          opacity: 0.8;
        }
      }

      .fb-content--wrapper {
        padding: 2rem;
        background-color: #fbfcf0;
        border-radius: $radius--lg;
        background-image: url('/assets/images/feedback/ui/stacked-waves.svg');
        background-size: 100%;
        background-repeat: repeat-y;
      }
    }
  }

  .h1,
  .h2,
  .h3 {
    color: #000;
  }

  h1.h1 {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: _space(1.5);
    font-size: 2rem;

    .fa {
      font-size: 2rem;
      color: #65676e30;
    }
  }

  .h5 {
    &.muted {
      color: #453e6c70;
      font-weight: 500;
    }
  }

  h5 {
    &.tip {
      font-size: 1.6rem;
      font-weight: 500;
      color: #333;
      margin-bottom: 2rem;

      &.tip--no-margin {
        margin-bottom: 0;
      }

      .fb__help {
        display: block;
        width: max-content;
        padding-left: 1.4rem;
        font-size: 1rem;
        color: #757575;
        text-decoration: underline;
        letter-spacing: 0.1px;
        font-weight: 400;
        position: relative;

        &:hover {
          text-decoration: none;
        }

        &:before {
          content: '';
          background: url('/assets/images/feedback/icons/question-mark.svg');
          opacity: 0.3;
          position: absolute;
          left: 0;
          top: 0.27rem;
          height: 1rem;
          width: 1rem;
        }
      }
    }
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    font-weight: 500;
    color: #333;
  }

  .c-form-control {
    border-color: #e1e4eb;
    border-radius: 4px;
    transition: all 200ms linear;
    box-shadow: none;
    font-size: 1.2rem;
    max-width: 100%;

    &:focus {
      border-color: #becd00;
      box-shadow:
        0 4px 4px rgb(51 51 51 / 4%),
        0 4px 24px rgb(51 51 51 / 10%);
    }

    &:hover {
      border-color: #becd00;
      box-shadow:
        0 4px 4px rgb(51 51 51 / 4%),
        0 4px 24px rgb(51 51 51 / 10%);

      &:focus {
        border-color: #becd00;
        box-shadow:
          0 4px 4px rgb(51 51 51 / 4%),
          0 4px 24px rgb(51 51 51 / 10%);
      }
    }

    &::placeholder {
      color: #75757580;
      opacity: 1;
    }
  }

  @include quill-editor;

  .spacer--vertical {
    width: 2px;
    margin: 0 14px;
    background-color: #cfcfcf42;
  }

  .muted {
    color: #75757580;
    font-weight: 400;
  }

  .ui-tabs {
    margin-bottom: 1.25rem;
    border-bottom: 1px solid #e2e2e4;
    gap: _space(0.75);

    .ui-tab {
      font-weight: 500;
      color: #a9abbd;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      border-radius: 0;
      border-bottom: 3px solid transparent;
      padding: 1rem 1.5rem;
      position: relative;
      top: 2px;
      transition: all 150ms linear;

      .badge {
        background-color: #e2e2e4;
        color: #65676ec2;
        font-size: 1rem;
        border-radius: 1rem;
        @include _padding(0.125, 0.4);
        margin-left: 0.3rem;
      }

      &.--active {
        color: #000;
        border-color: #becd00;

        &:hover {
          color: #000;
          text-decoration: none;
        }
      }

      &:hover {
        color: #000;
        border-color: #e2e2e4;
        text-decoration: none;
      }
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-size: 1rem;
  }

  .query {
    position: relative;

    &:before {
      position: absolute;
      left: 0.6rem;
      top: 2px;
      font-family: 'FontAwesome';
      content: '\f002';
      color: #e1e4ea;
      font-size: 1.6rem;
    }

    input {
      max-width: 30rem;
      padding-left: 2.6rem;
    }
  }

  .dc-btn {
    transition: all 200ms linear;

    &:not(.dc-btn--circled) {
      font-size: 1.2rem;
      padding: 0.8rem 1.6rem;

      &.dc-btn--primary {
        background-color: #5a2382;
        border-color: #5a2382;
        color: #fff;

        &:hover {
          background: #9041c9;
          border-color: #9041c9;
          color: #fff;
        }
      }

      &.dc-btn--secondary {
        color: #5a2382;
        background: #fff;
        border-color: #5a2382;

        &:hover {
          background: #fff;
          color: #9041c9;
          border-color: #9041c9;
        }
      }

      &:hover {
        box-shadow:
          0 4px 4px rgb(51 51 51 / 4%),
          0 4px 24px rgb(51 51 51 / 24%);
      }
    }

    &.dc-btn--small {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }

    &.dc-btn--tertiary {
      background-color: #333;
      color: #fff;

      &:hover {
        box-shadow:
          0 4px 4px rgb(51 51 51 / 4%),
          0 4px 24px rgb(51 51 51 / 24%);
      }
    }

    &.dc-datepicker__close {
      padding: _space(0.25) _space(0.5);
      font-size: 1rem;
      border: 1px solid #e1e4e9;
      color: #5d646c;

      &:hover {
        border-color: #e1e4e9;
      }
    }
  }

  .ui-date-time-field-legacy__inputs {
    grid-template-columns: 1fr 50px;
    width: 200px;

    .ui-date-time-field-legacy__inputs--button-wrapp {
      .dc-btn.dc-btn--full-faced {
        font-size: 1rem;
        padding: 0.6rem 0.8rem;
        position: relative;
        top: -2px;
      }
    }
  }

  fb-instant,
  fb-instant-give {
    padding-top: _space(4);

    .c-section__title {
      text-align: center !important;
      color: $text;
    }

    .c-modal__content {
      max-width: 900px;
      background-color: #fbfcf0;
      background-image: url('/assets/images/feedback/ui/stacked-waves.svg');
      background-position: 0 42px;
      height: 100%;
    }
  }
  fb-instant-give {
    padding-top: 0;
    width: 100%;
  }

  .fb-form__wrapper {
    margin-bottom: 2rem;
  }

  .dc-form__group {
    label {
      padding-left: 0.8rem;
    }
  }

  ui-form-element-checkbox-legacy {
    &.dc-form__group {
      label {
        padding-left: 2.5rem;
        font-size: 14px;

        span {
          position: relative;
          top: 3px;
        }
      }

      input[type='checkbox'] + label:before {
        background: #f2f5cc;
        border: 1px solid #becd00;
        border-radius: 0.6rem;
        width: 24px;
        height: 24px;
        transition: all 150ms ease-in-out;
      }

      input[type='checkbox'] + label:after {
        width: 24px;
        height: 24px;
        line-height: 24px;
      }

      input[type='checkbox']:checked + label:before {
        border-color: #becd00;
        background: #becd00;
      }
    }

    &:hover {
      input[type='checkbox'] + label:before {
        border-color: #98a300;
        box-shadow:
          0 4px 4px rgb(51 51 51 / 4%),
          0 4px 24px rgb(51 51 51 / 24%);
      }
    }
  }

  fieldset {
    border: 1px dashed #e1e4ea;
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 1rem 1rem 0.5rem 1rem;
    border-radius: 4px;
    position: relative;

    > .fieldset-label {
      display: block;
      position: absolute;
      top: -0.6rem;
      line-height: 1.2;
      padding: 0 0.8rem;
      background-color: #fff;
      color: #5d646c;
      cursor: default;
    }

    > .dc-form__group {
      margin-bottom: _space(0.25);
    }
  }

  .dc-form__container {
    background-color: #fff;
    padding: _space(1.5);
    max-width: 800px;
    position: relative;
    border-radius: $radius--lg;
    box-shadow:
      0 4px 4px rgb(51 51 51 / 4%),
      0 4px 16px rgb(51 51 51 / 8%);
  }

  ui-date-time-picker-legacy.dc-datepicker {
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #e1e4ea;
    box-shadow:
      0 4px 4px rgb(51 51 51 / 4%),
      0 4px 16px rgb(51 51 51 / 8%);
    border-radius: 4px;
    max-width: 430px;

    .dc-datepicker__output {
      padding-top: 1.05rem;

      .dc-form__group label {
        padding: 0 0 0.375rem 0;
        margin-bottom: _space(0.25);
        color: #7d7b6e;
      }
    }

    select.c-form-control {
      min-width: 110px;
      margin-bottom: _space(0.25);
      font-size: 1rem;
    }
  }

  ul {
    &.lst {
      &--none {
        margin-left: 0;
        list-style-type: none;
      }
    }
  }

  fb-personal-development {
    .row__remove {
      width: 30px;
    }
  }

  fb-quarter-select {
    @apply w-80;
  }

  angular-editor {
    angular-editor-toolbar {
      .angular-editor-toolbar {
        background-color: transparent !important;
        border-color: transparent !important;
        border-radius: 6px;
        padding: 0 !important;

        .angular-editor-button {
          border-radius: 4px !important;
          margin: 0 0.1rem !important;
          width: 2.5rem;
          height: 2.5rem;
          font-size: 1rem;
          font-family: 'Poppins';

          &:hover {
            background-color: #f7eefb !important;
          }
        }
      }
    }
  }

  .badge {
    font-weight: 500;
    font-size: 0.8rem;
    white-space: nowrap;
    padding: 0.6rem 0.6rem 0.5rem 0.6rem;
    margin: 0;
    border-radius: 3px;
    color: #000;

    &.bg--description {
      background-color: #e9ecef;
    }

    &.bg--instant {
      background-color: #fbc02d;
    }

    &.bg--core-member {
      background-color: #689f38;
      color: #fff;
    }
  }

  .fb-rating {
    border: 1px solid #e1e4ea;
    width: max-content;
    padding: 1rem;
    border-radius: 6px;

    .label {
      text-align: center;
      font-size: 0.8rem;
      line-height: 1.4;
      color: #75757580;
    }

    &:not(.readonly):hover {
      border-color: #becd00;
      box-shadow:
        0 4px 4px rgb(51 51 51 / 4%),
        0 4px 24px rgb(51 51 51 / 10%);
    }

    &.readonly {
      .p-rating-icon {
        cursor: auto;
      }
    }
  }

  fb-personal-development-edit {
    .dc-form__container {
      max-width: 100%;

      .dc-form__form-with-tips {
        .row {
          display: flex;
          gap: _space(1);
          margin-bottom: $form__group--margin;

          .main {
            flex: 0 0 60%;
          }

          .tip {
            flex-grow: 1;
            color: #5d646c;
            font-size: 1rem;
            background-color: #f6f6f6;
            padding: 1rem;
            border-radius: 6px;
          }

          @media (min-width: $breakpoint__xxl) {
            gap: _space(3);

            .main {
              flex: 0 0 65%;
            }
          }

          @media (max-width: $breakpoint__md) {
            flex-direction: column;
            gap: 0;

            .main {
              .dc-form__group {
                margin-bottom: 1rem;
              }
            }

            .tip {
              margin-top: 0;
              margin-bottom: _space(2.5);
            }
          }
        }
      }
    }
  }

  // primeng p-rating fix
  .p-rating {
    .p-rating-icon {
      color: #757575;

      &.pi-star-fill {
        color: #becd00 !important;
      }
    }

    &.p-disabled {
      opacity: 0.8;
    }

    &:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
      color: #becd00 !important;
    }
  }

  .p-button.cta {
    background: #5a2382;
    border-color: #5a2382;

    &:enabled {
      &:hover {
        background: #9041c9;
        border-color: #9041c9;
      }
    }

    &:active,
    &:active:focus {
      background: #5a2382;
      border-color: #5a2382;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem #be88e5;
    }
  }

  .p-message {
    // nase message, primeng nema tuto severitu
    &.p-message-text {
      background: #fff;
      border: solid #98a300;
      border-width: 0 0 0 6px;
      color: #333;
    }
  }

  .fb__help {
    display: block;
    width: max-content;
    padding-left: 1.4rem;
    font-size: 1rem;
    color: #757575;
    text-decoration: underline;
    letter-spacing: 0.1px;
    font-weight: 400;
    position: relative;

    &:hover {
      text-decoration: none;
    }

    &:before {
      content: '';
      background: url('/assets/images/feedback/icons/question-mark.svg');
      opacity: 0.3;
      position: absolute;
      left: 0;
      top: 0.27rem;
      height: 1rem;
      width: 1rem;
    }
  }

  .validity-arrow {
    position: relative;
    top: 1rem;
    left: 0.1rem;
  }
}
