.overflow {
  &--auto {
    overflow: auto !important;
  }
  &--hidden {
    overflow: hidden !important;
  }
  &--visible {
    overflow: visible !important;
  }

  &-x--auto {
    overflow-x: auto !important;
  }
  &-x--hidden {
    overflow-x: hidden !important;
  }
  &-x--visible {
    overflow-x: visible !important;
  }
  &-x--scroll {
    overflow-x: scroll !important;
  }

  &-y--auto {
    overflow-y: auto !important;
  }
  &-y--hidden {
    overflow-y: hidden !important;
  }
  &-y--visible {
    overflow-y: visible !important;
  }
  &-y--scroll {
    overflow-y: scroll !important;
  }
}
