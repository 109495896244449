.p-accordion {
  p-accordiontab {
    &:first-child {
      .p-accordion-header {
        .p-accordion-header-link {
          @apply rounded-t-lg;
        }
      }
    }
    &:last-child {
      .p-accordion-header:not(.p-highlight) {
        .p-accordion-header-link {
          @apply rounded-b-lg;
        }
      }
      .p-accordion-content {
        @apply rounded-b-lg;
      }
    }
  }

  .p-accordion-header {
    &:not(.p-disabled) {
      .p-accordion-header-link {
        @apply rounded-tl-lg rounded-tr-lg leading-6 no-underline;

        .p-accordion-header-text {
          @apply leading-6;
        }

        &:focus {
          @apply border-blue-500 shadow-none ring ring-blue-200;
        }
      }
    }
  }
}
