body.c-modal--is-open {
  overflow: hidden;
}

.c-modal {
  will-change: visibility, opacity;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: z-index(modal);

  min-height: 100%;
  overflow-y: visible;
  overflow-x: visible;
  padding-bottom: _space(4);

  background: rgba($black, 0.7);

  @supports (backdrop-filter: none) {
    backdrop-filter: blur(#{_space(0.25)});
  }

  &__content {
    position: relative;
    max-width: $modal--max-width;
    margin-left: auto;
    margin-right: auto;

    padding: _space(2);
    padding-bottom: 0;

    background-color: $white;
    border-radius: $radius--lg;
    border-bottom-right-radius: $radius--lg * 2;
    border-bottom-left-radius: $radius--lg * 2;

    box-shadow: 0 0 _space(1.75) _space(-1) $black;
  }

  &__header {
    position: relative;
  }
  &__body {
    padding-bottom: _space(1.5);
  }
  &__perex {
    margin-bottom: _space(1);
  }
  &__file-attachments {
    margin-top: _space(2);
  }

  &__footer {
    margin-left: _space(-2);
    margin-right: _space(-2);
    @include _padding(1, 2);

    background: $core;
    text-align: right;

    border-radius: 0 0 $radius--lg $radius--lg;
  }

  ui-app-modal-close-legacy {
    position: absolute;
    top: -2.5rem;
    right: -2.5rem;

    .dc-btn {
      background: $core;
      color: $white;

      &:hover {
        background: lighten($core, 15%);
      }
    }
  }
}
