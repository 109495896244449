/*
---
name: $_prefix
category:
  - core/variable
---
Prefix of basis classes

```scss
$_prefix: '';
```
*/

$_prefix: '' !default;
