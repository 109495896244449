/*
---
name: .u-clearfix
category:
  - object/utility
  - object/utility/clearfix
---
Clearfix utility

### html
```html
<div class="u-clearfix">
  <img class="u-pull-left" src="/basis/aigis_assets/images/dummy.jpg" style="width: 150px; margin-right: 1em">
  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
</div>
Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
```
*/

.#{$_prefix}u-clearfix {
  @include _clearfix();
}
