input[type='checkbox'],
input[type='radio'] {
  position: absolute;
  outline: none;
  opacity: 0;

  &[disabled] {
    cursor: not-allowed;

    + label {
      cursor: not-allowed;
      opacity: 0.5;

      &::before {
        // FR > keep important, it's important here!
        background: transparent !important;
      }

      transition: all $speed--fast $effect;
      &:hover {
        opacity: 1;

        &::before {
          opacity: 0.5;
        }
      }
    }
  }

  //
  // states
  //
  &:hover {
    + label:before {
      border-color: $focus;
    }
  }
  &:focus {
    + label:before {
      box-shadow: 0 0px 2px 1px $focus;
      //outline: -webkit-focus-ring-color auto 15px;
    }
  }

  &:checked {
    + label:after {
      opacity: 1;
    }
  }

  &[disabled] {
    //...
  }

  + label {
    position: relative;
    cursor: pointer;
    padding-left: _space(1.125);
    user-select: none;
    line-height: 1.6;

    &:before,
    &:after {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 2px;
      width: $form__checkbox--width;
      height: $form__checkbox--width;
    }

    // container (checkbox - square, radio - circle)
    &:before {
      content: '';
      background: $grey-1;
      border: 1px solid $grey-3;
      border-radius: $radius--md;
    }

    // the Check symbol, circle for Radio
    &:after {
      content: '✓';
      line-height: $form__checkbox--width;
      text-align: center;
      color: $text;

      opacity: 0;
      transition: all $speed--normal ease-in-out;
    }
  }
}

//
//  radio
//
input[type='radio'] {
  + label {
    &:before {
      border-radius: calc($form__checkbox--width / 2);
    }

    // circle (checked radio)
    &:after {
      background: $text;
      content: '';
      top: 5px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: calc($form__checkbox--width / 2);
    }
  }
}

// >>>>>>>>>>>>>>>>. all bellow   FR > refuck
// >>>>>>>>>>>>>>>>. all bellow   FR > refuck
// >>>>>>>>>>>>>>>>. all bellow   FR > refuck

//
//  FR > inherit styles from native <checkbox>
//
// KOKA
//http://0.0.0.0:3000/partner/fda7a34a-48b2-45cb-a96a-dc3689b1294d/detail/(kontakty)
.dc-fake-checkbox {
  position: relative;
  cursor: pointer;
  padding-left: _space(1.125);
  user-select: none;
  line-height: 1.6;

  &:before,
  &:after {
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 0;
    width: $form__checkbox--width;
    height: $form__checkbox--width;
  }

  //
  // square shape
  //
  &:before {
    content: '';
    background: $grey-1;
    border: 1px solid $grey-3;
    border-radius: $radius--md;
  }

  //
  // check symbol
  //
  &:after {
    line-height: $form__checkbox--width;
    text-align: center;

    color: $text;
    opacity: 0.5;
    transition: all $speed--normal ease-in-out;
  }

  //
  // state: checked
  //
  &.checked:after {
    opacity: 1;
    content: '✓';
  }

  //
  // state: Indeterminated
  //
  &.indefinite:after {
    opacity: 1;
    content: '-';
  }
}
