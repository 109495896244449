/*
---
name: .c-input-group
category:
  - object/component
  - object/component/input-group
---
Input group component

### html
```html
<div class="c-input-group">
  <div class="c-input-group__addon">@</div>
  <div class="c-input-group__field">
    <input type="text">
  </div>
  <button class="c-input-group__btn">Go</button>
</div>
```
*/

.#{$_prefix}c-input-group {
  @include _input-group();
}
