.ui-user {
  display: grid;
  grid-template-columns: 58px 1fr;
  grid-gap: _space(0.5);

  &__avatar {
    float: right;
    width: 58px;
    height: 58px;
  }

  &__name {
    display: block;
    line-height: $lh--medium;
  }

  &__role {
    display: block;
    color: $grey-5;
  }

  &__link {
    display: block;
  }
}
