//
//  Text help
//
.ui-hint {
  border-bottom: 1px dotted $action;

  @include transition();
  &:hover {
    cursor: help;
    border-bottom-style: dashed;
  }
}
