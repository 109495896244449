/*
---
name: _padding-top()
category:
  - core/mixin
  - core/mixin/padding
---
Sets padding-top

### scss
```scss
//
// @param  int  $coefficient
// @param  boolean  $apply-margin-scale
//

@include _padding-top(1);
@include _padding-top(10px);
@include _padding-top(1, true);
```
*/

@mixin _padding-top($coefficient, $apply-margin-scale: false) {
  @if _is-number($coefficient) and 0 != $coefficient {
    padding-top: _space($coefficient);

    @if $apply-margin-scale {
      padding-top: _space($coefficient, true, true);
    } @else {
      padding-top: _space($coefficient, true);
    }
  } @else {
    padding-top: $coefficient;
  }
}

/*
---
name: _padding-right()
category:
  - core/mixin
  - core/mixin/padding
---
Sets padding-right

### scss
```scss
//
// @param  int  $coefficient
// @param  boolean  $apply-margin-scale
//

@include _padding-right(1);
@include _padding-right(10px);
@include _padding-right(1, true);
```
*/

@mixin _padding-right($coefficient, $apply-margin-scale: false) {
  @if _is-number($coefficient) and 0 != $coefficient {
    padding-right: _space($coefficient);

    @if $apply-margin-scale {
      padding-right: _space($coefficient, true, true);
    } @else {
      padding-right: _space($coefficient, true);
    }
  } @else {
    padding-right: $coefficient;
  }
}

/*
---
name: _padding-bottom()
category:
  - core/mixin
  - core/mixin/padding
---
Sets padding-bottom

### scss
```scss
//
// @param  int  $coefficient
// @param  boolean  $apply-margin-scale
//

@include _padding-bottom(1);
@include _padding-bottom(10px);
@include _padding-bottom(1, true);
```
*/

@mixin _padding-bottom($coefficient, $apply-margin-scale: false) {
  @if _is-number($coefficient) and 0 != $coefficient {
    padding-bottom: _space($coefficient);

    @if $apply-margin-scale {
      padding-bottom: _space($coefficient, true, true);
    } @else {
      padding-bottom: _space($coefficient, true);
    }
  } @else {
    padding-bottom: $coefficient;
  }
}

/*
---
name: _padding-left()
category:
  - core/mixin
  - core/mixin/padding
---
Sets padding-left

### scss
```scss
//
// @param  int  $coefficient
// @param  boolean  $apply-margin-scale
//

@include _padding-left(1);
@include _padding-left(10px);
@include _padding-left(1, true);
```
*/

@mixin _padding-left($coefficient, $apply-margin-scale: false) {
  @if _is-number($coefficient) and 0 != $coefficient {
    padding-left: _space($coefficient);

    @if $apply-margin-scale {
      padding-left: _space($coefficient, true, true);
    } @else {
      padding-left: _space($coefficient, true);
    }
  } @else {
    padding-left: $coefficient;
  }
}

/*
---
name: _padding()
category:
  - core/mixin
  - core/mixin/padding
---
Sets padding

### scss
```scss
//
// @param  int  $coefficient-1
// @param  int  $coefficient-2
// @param  int  $coefficient-3
// @param  int  $coefficient-4
// @param  boolean  $apply-margin-scale
//

@include _padding(1);
@include _padding(1, 2);
@include _padding(1, 2, .5);
@include _padding(1, 2, .5, 2);
@include _padding(10px);
@include _padding(1, null, null, null, true);
```
*/

@mixin _padding($coefficient-1, $coefficient-2: null, $coefficient-3: null, $coefficient-4: null, $apply-margin-scale: false) {
  @if (_is-null($coefficient-2) and _is-null($coefficient-3) and _is-null($coefficient-4)) {
    @include _padding-top($coefficient-1, $apply-margin-scale);
    @include _padding-right($coefficient-1, $apply-margin-scale);
    @include _padding-bottom($coefficient-1, $apply-margin-scale);
    @include _padding-left($coefficient-1, $apply-margin-scale);
  } @else if (_is-null($coefficient-3) and _is-null($coefficient-4)) {
    @include _padding-top($coefficient-1, $apply-margin-scale);
    @include _padding-right($coefficient-2, $apply-margin-scale);
    @include _padding-bottom($coefficient-1, $apply-margin-scale);
    @include _padding-left($coefficient-2, $apply-margin-scale);
  } @else if (_is-null($coefficient-4)) {
    @include _padding-top($coefficient-1, $apply-margin-scale);
    @include _padding-right($coefficient-2, $apply-margin-scale);
    @include _padding-bottom($coefficient-3, $apply-margin-scale);
    @include _padding-left($coefficient-2, $apply-margin-scale);
  } @else {
    @include _padding-top($coefficient-1, $apply-margin-scale);
    @include _padding-right($coefficient-2, $apply-margin-scale);
    @include _padding-bottom($coefficient-3, $apply-margin-scale);
    @include _padding-left($coefficient-4, $apply-margin-scale);
  }
}
