$dc-file-attachements-item__min-height: 170px;

.dc-file-attachements {
  padding: _space(1);
  background: $concrete;
  border-radius: $radius--lg;

  &__item {
    display: flex;
    overflow: hidden;
    min-height: $dc-file-attachements-item__min-height;

    border-radius: $radius--sm;

    &-inner {
      position: relative;
      width: 100%;

      border-radius: $radius--sm;
      background: $white;
    }
  }

  &__figure {
    text-align: center;

    // missing thumbnails => fa icon
    .fa {
      position: static;
      margin-top: 20px;
    }

    img {
      position: relative;
      z-index: 2;
    }

    .loader {
      position: absolute;
      top: 35%;
      left: 50%;
      z-index: 1;
    }
  }

  &__thumbnail {
    //...
  }

  &__body {
    position: absolute;
    bottom: 0;
    z-index: 3;

    width: 100%;
    min-height: 50px;
    @include _padding(0.25, 0.5);

    line-height: $lh--medium;
    word-break: break-word;
    color: $white;

    background: rgba($black, 0.8);
    border-bottom-left-radius: $radius--sm;
    border-bottom-right-radius: $radius--sm;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    // todo: optimize animation
    height: 0;
    transition: all $speed ease;

    //... enable for debugging
    //height: auto;
    //@include _padding(.5, 0);

    .dc-btn {
      min-width: 78px;
    }
  }
}

// state: HOVER
.dc-file-attachements__item {
  &:focus,
  &:hover {
    .dc-file-attachements__controls {
      height: auto;
      @include _padding(0.5, 0);
    }
  }
}

// pdf | image | unsupported
.dc-file-attachements__item {
  &--image {
    .dc-file-attachements__body {
      background: darken($antracit, 20%);
    }
  }
}

/*
"application/vnd.ms-excel"
"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
"application/xlsx"
"application/zip"
"image/jpeg"
"image/png"
"image/tiff"




original:
<img [uiSecuredImage]="file.fileId + '?width=200'"

landscape IMG:
<img src="https://static.pexels.com/photos/36764/marguerite-daisy-beautiful-beauty.jpg"

portrait IMG:
<!-- <img src="https://upload.wikimedia.org/wikipedia/commons/2/2a/Portrait_of_Maria_Dalmazzo%2C_Headshot_in_black_and_white_by_Ricardo_Pinz%C3%B3n.jpg" -->

*/
