.ui-alert {
  position: relative;
  margin-bottom: _space(2);
  padding: _space(0.5) _space(1);
  @include _padding(0.5, 1, 0.5, 2);

  font-size: 14px;

  background: $milk;
  border-radius: $radius--md;

  &__icon {
    position: absolute;
    top: _space(0.5);
    left: _space(0.5);
    margin-top: 1px; // adjust position

    font-size: _space(1);
    opacity: 0.7;
  }

  //
  // variants
  //
  &.--info {
    color: $white;
    background: $info;
  }
  &.--warning {
    color: $white;
    background: $warning;
  }
  &.--danger {
    color: $white;
    background: $danger;
  }
  &.--success {
    color: $white;
    background: $success;
  }
}
