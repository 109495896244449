//
//  Layout (wrapper)
//
.dc-datepicker {
  position: relative;
  display: inline-block;

  // input === Calendar
  &__input {
    //...
  }

  // output === manual selection (month / year / hour / minute)
  &__output {
    //...
  }

  &__controls {
    //...

    ui-button-legacy {
      margin-right: _space(0.75);
    }
  }
}

//
//  Layout ( Calendar )
//
.datepicker {
  &__header {
    display: none;

    // prev / next button
    .dc-btn {
      border-radius: $radius--lg;
    }

    &--title {
      flex-basis: 120px;

      font-size: 14px;
      line-height: 1;
      font-weight: bold;
      text-align: center;
    }
  }
}

//
//  LEGEND:
//
//  span  ~  day name ( PO | ÚT | ST | ...)
//  em    ~  day from previous / next month
//
.datepicker__calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
  align-items: center;

  padding: _space(0.5) 0;
  text-align: center;

  border-radius: $radius--lg;

  // week day name ( PO  ÚT  ST ...)
  span {
    margin-bottom: _space(0.25);
    padding-bottom: _space(0.125);

    font-size: 11px;
    color: $grey-5;

    border-bottom: 1px solid $grey-2;

    user-select: none;
    cursor: default;
  }

  .day {
    user-select: none;
    width: 32px;
    height: 32px;

    color: $grey-7;
    line-height: 32px;

    border: 1px solid transparent;
    border-radius: $radius--md;

    transition: background-color $speed--fast ease-in-out;

    // FR > refuck
    &.--not-current-month {
      opacity: 0.3 !important;
      color: $grey-7 !important;
      cursor: not-allowed;

      &:hover {
        border-color: transparent !important;
        font-weight: normal !important;
      }
    }

    // weekend days
    &:nth-child(6):not(.--selected),
    &:nth-child(7):not(.--selected),
    &:nth-child(13):not(.--selected),
    &:nth-child(14):not(.--selected),
    &:nth-child(20):not(.--selected),
    &:nth-child(21):not(.--selected),
    &:nth-child(27):not(.--selected),
    &:nth-child(28):not(.--selected),
    &:nth-child(34):not(.--selected),
    &:nth-child(35):not(.--selected),
    &:nth-child(41):not(.--selected),
    &:nth-child(42):not(.--selected) {
      color: $grey-5;

      &.--today {
        //color:$action;
      }
      &.--selected {
        //color: $white;
      }
    }
  }

  .--today {
    font-weight: bold;
  }

  .--selected {
    font-weight: bold;
    color: $white;
    background: darken($action, 10%);
    border-color: darken($action, 10%);
  }
}

//
//  EXTENSIONS
//
/*
.datepicker__calendar {
  // add text "DNES" to current day
  .--today {
    position: relative;
    &::after {
      content: "DNES";
      display: block;
      position: absolute;
      left: 0; right: 0;
      bottom: -13px;

      font-size: 8px;
      //letter-spacing: 1px;
      font-weight: normal;
      color: $grey-5;

      // effects
      opacity: 1;
      transition: all $speed ease;
    }
    &:hover::after { opacity: 0; }
  }
  .--today.--selected::after { color: $white; }
}
*/

//
//  EFFECTS
//
.datepicker__calendar {
  .day {
    &:hover {
      font-weight: bold;
      text-decoration: none;
    }
    &:not(.--selected):hover {
      color: $action;
      border-color: $action;
    }
  }
}

.dc-native-form__date-field {
  display: block;
}
