/*

  template
  --------

  <div class="ui-card" tabindex="0">
      <header class="ui-card__header">
          <div class="ui-card__header--left">
              _left
          </div>
          <div class="ui-card__header--middle">
              _middle
          </div>
          <div class="ui-card__header--right">
              _right
          </div>
      </header>
      <div class="ui-card__body">
          _body
      </div>
      <footer class="ui-card__footer">
          _footer
      </footer>
  </div>


*/

.ui-card {
  display: block;
  width: 100%;
  background: $white;
  border-radius: $radius--lg;
  overflow: hidden;

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: _space(0.25);
    padding: _space(0.25) _space(0.5) _space(0.125);
    line-height: 1.2;
    font-weight: bold;
    color: $antracit;
    min-height: 26px;
    background: $grey-10;
    border-bottom: 2px solid darken($concrete, 25%);
    &.participant {
      border-bottom: 2px solid $active;
    }

    &.two-cols {
      grid-template-columns: 1fr 1fr;

      &.cols--2-1 {
        grid-template-columns: 2fr 1fr;
      }
    }

    &--left {
      //...
    }
    &--middle {
      text-align: center;
    }
    &--right {
      text-align: right;
    }
  }

  &__body {
    padding: _space(0.5);
    padding-bottom: _space(0.25);
  }

  &__footer {
    display: block;
    padding: _space(0.25) _space(0.5);

    color: $grey-5;
    border-top: 1px dotted $grey-3;
  }

  // variants
  //
  &.--highlighted {
    box-shadow: 0 0 9px 3px $highlight;
  }

  // effects
  //
  transition: all 60ms ease-in-out;
  &:hover,
  &:focus {
    cursor: pointer;
    outline: none;
    box-shadow: 0 8px 16px 0 rgba($black, 0.1);
  }
}

.ui-card--noHover:hover {
  cursor: default;
  outline: none;
  box-shadow: none;
}

//
//  customize components
//
.ui-card {
  &__title {
    display: block;
    font-size: 14px;
  }

  .ui-media {
    margin-bottom: _space(0.5);

    &__content {
      padding-top: _space(0.25);
    }
  }

  .ui-label-value {
    flex-grow: 1;
    white-space: nowrap;

    &__row {
      grid-template-columns: 100px 1fr;
      grid-gap: _space(0.25);
    }
  }
}
