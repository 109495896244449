/*
---
name: $_min-columns
category:
  - core/variable
  - core/variable/media-query
---
The minimum number of columns

### scss
```scss
$_min-columns: 1;
```
*/

$_min-columns: 1 !default;

/*
---
name: $_max-columns
category:
  - core/variable
  - core/variable/media-query
---
The maximum number of columns

### scss
```scss
$_max-columns: 12;
```
*/

$_max-columns: 12 !default;

/*
---
name: $_sizes
category:
  - core/variable
  - core/variable/media-query
---
Hash of breakpoints

### scss
```scss
$_sizes: (
  'sm': 0,
  'md': (_px2rem(640px) / _px2rem($_base-font-size-px) * 1em),
  'lg': (_px2rem(1024px) / _px2rem($_base-font-size-px) * 1em),
  'xl': (_px2rem(1280px) / _px2rem($_base-font-size-px) * 1em)
);
```
*/

$_sizes: (
  'sm': 0,
  'md': 640px,
  'lg': 1024px,
  'xl': 1280px
) !default;
