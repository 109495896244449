ui-dropdown,
p-dropdown {
  &.p-inputtext-sm {
    .p-dropdown {
      @apply rounded-lg;

      .p-inputtext {
        @apply pl-3;
      }
    }
  }

  &.ng-touched.ng-invalid,
  &.ng-dirty.ng-invalid {
    .p-dropdown .p-dropdown-label {
      @apply after:absolute after:right-2 after:top-2 after:inline-flex after:size-[20px] after:bg-input-error after:bg-contain;
    }

    & > .p-dropdown {
      @apply border-red-300 ring ring-red-100;

      &:enabled:focus {
        @apply border-red-300 ring ring-red-100;
      }
    }

    + div,
    + small {
      @apply mt-2 text-base font-medium text-red-700;
    }
  }

  .p-dropdown {
    @apply rounded-lg border-gray-300 font-stack hover:border-primary-500;

    .p-inputtext {
      @apply pl-3;
    }

    .p-dropdown-label {
      @apply relative;

      &.p-placeholder {
        @apply text-neutral-400;
      }

      &.p-dropdown-clearable .p-dropdown-label {
        @apply pr-0;
      }
    }

    &:not(.p-disabled).p-focus {
      @apply border-primary-300 ring ring-primary-600/20;
    }
  }
}
ui-label:hover + p-dropdown .p-dropdown:not(.p-disabled),
label:hover + p-dropdown .p-dropdown:not(.p-disabled),
ui-label:hover + ui-dropdown .p-dropdown:not(.p-disabled),
label:hover + ui-dropdown .p-dropdown:not(.p-disabled) {
  @apply border-[color:var(--input-border-hover)];
}

ui-dropdown-item,
p-dropdownitem {
  .p-dropdown-item {
    @apply flex items-center;
  }
}

.p-dropdown-panel {
  @apply mt-[2px] rounded-lg;

  .p-dropdown-header {
    @apply relative rounded-t-lg bg-gray-100 px-3.5 py-2;

    .p-dropdown-filter {
      @apply w-full;
    }
  }

  .p-dropdown-items-wrapper {
    @apply overflow-auto;
  }

  .p-dropdown-items {
    @apply p-2;

    .p-dropdown-item {
      @apply cursor-pointer rounded-md px-3 py-2;

      span {
        @apply whitespace-normal;
      }

      &:not(.p-highlight):not(.p-disabled) {
        @apply hover:bg-blue-gray-50;
      }
    }
  }
}
