//
//  layout
//
form {
  //...
}

fieldset {
  border: 0;

  + fieldset {
    margin-top: $form__fieldset--margin;
  }
}

legend {
  position: relative;
  width: fit-content;
  margin-bottom: $form__legend--margin;

  font-weight: bold;
  font-size: 14px;
  color: $text;

  border-bottom: $form__legend__border--width solid $text;
  cursor: default;
}

.dc-form {
  &__perex {
    margin-bottom: _space(0.5);
  }

  &__group,
  &__row {
    clear: both;
    display: block;
    position: relative;
  }

  &__group {
    margin-bottom: $form__group--margin;
    .error {
      display: block;
      color: $danger;
    }

    .no-error {
      display: block;
      color: $grey-6;
      font-size: 12px;
    }

    &-title {
      display: block;
      margin-bottom: _space(0.25);

      font-weight: bold;
      color: $antracit;
      cursor: default;
    }
  }

  //
  // inline group
  &__group--inline {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $form__group--margin;

    &-item {
      //...
    }
  }

  &__row {
    margin-bottom: $form__row--margin;
  }

  &__controls {
    margin-top: _space(1.5);

    &--primary {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    &--secondary {
      margin-top: _space(1);
      padding-top: _space(1);

      border-top: 1px dotted $grey-3;

      // empty container renders un-wanted top border > hide whole block
      &:empty {
        display: none;
      }
    }
  }
}

//
//  Input + addon
//
.c-input-group {
  &__field {
    .c-form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

//
//  Form - Layouts
//
.form-row--two-columns {
  display: grid;
  grid-template-columns: $form__element--max-width 1fr;
  grid-gap: _space(1);

  .dc-btn {
    position: relative;
    top: 3px;
  }
}
