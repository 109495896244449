/*
---
name: .c-entry
category:
  - object/component
  - object/component/entry
---
Entry component

### html
```html
<article class="c-entry">
  <header class="c-entry__header">
    <h1 class="c-entry__title">...</h1>
    <div class="c-entry__content">
      ...
    </div>
  </header>
</article>
```
*/

.#{$_prefix}c-entry {
  @include _entry();
}
