/*


usage
-----

.element {
  @include transition();
}


*/

@mixin transition() {
  transition: all $speed ease-in-out;
}
