//
//  Tiles - responsivity
//
.grid--tiles {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: _space(1);
}
//
//  @ media
//
@media screen and (min-width: $breakpoint__sm--min) {
  .grid--tiles {
    grid-template-columns: repeat(3, 1fr);
  }
}

/*

    Flexible square grid with CSS Grid Layout
    -----------------------------------------
    article:  https://medium.com/cloudaper/how-to-create-a-flexible-square-grid-with-css-grid-layout-ea48baf038f3
    demo:     https://codepen.io/krystof-k/pen/vdNQGB

*/
.grid--tiles--responsive {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($width--mobile, 1fr));
  grid-auto-rows: 1fr; // set equal height to all rows
  //grid-gap: 0;    // this will render ✦✦✦ STARS ✦✦✦
  grid-gap: _space(1);

  // InvisibleSquare
  //
  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;

    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  // reposition the InvisibleSquare as well as the first grid element to overlap
  //
  > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
}
