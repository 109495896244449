/*
---
name: $_color-text
category:
  - core/variable
  - core/variable/color
---
Default text color

### scss
```scss
$_color-text: #333;
```
*/

$_color-text: #333 !default;

/*
---
name: $_color-black
category:
  - core/variable
  - core/variable/color
---
Default black color

### scss
```scss
$_color-black: #111;
```
*/

$_color-black: #111 !default;

/*
---
name: $_color-white
category:
  - core/variable
  - core/variable/color
---
Default white color

### scss
```scss
$_color-white: #fff;
```
*/

$_color-white: #fff !default;

/*
---
name: $_color-gray
category:
  - core/variable
  - core/variable/color
---
Default gray color

### scss
```scss
$_color-gray: #999;
```
*/

$_color-gray: #999 !default;

/*
---
name: $_color-red
category:
  - core/variable
  - core/variable/color
---
Default red color

### scss
```scss
$_color-red: #e74c3c;
```
*/

$_color-red: #e74c3c !default;
