/*
---
name: _margin-top()
category:
  - core/mixin
  - core/mixin/margin
---
Sets margin-top

### scss
```scss
//
// @param  int  $coefficient
// @param  boolean  $apply-margin-scale
//

@include _margin-top(1);
@include _margin-top(10px);
@include _margin-top(1, false);
```
*/

@mixin _margin-top($coefficient, $apply-margin-scale: true) {
  @if _is-number($coefficient) and 0 != $coefficient {
    margin-top: _space($coefficient);

    @if $apply-margin-scale {
      margin-top: _space($coefficient, true, true);
    } @else {
      margin-top: _space($coefficient, true);
    }
  } @else {
    margin-top: $coefficient;
  }
}

/*
---
name: _margin-right()
category:
  - core/mixin
  - core/mixin/margin
---
Sets margin-right

### scss
```scss
//
// @param  int  $coefficient
// @param  boolean  $apply-margin-scale
//

@include _margin-right(1);
@include _margin-right(10px);
@include _margin-right(1, false);
```
*/

@mixin _margin-right($coefficient, $apply-margin-scale: true) {
  @if _is-number($coefficient) and 0 != $coefficient {
    margin-right: _space($coefficient);

    @if $apply-margin-scale {
      margin-right: _space($coefficient, true, true);
    } @else {
      margin-right: _space($coefficient, true);
    }
  } @else {
    margin-right: $coefficient;
  }
}

/*
---
name: _margin-bottom()
category:
  - core/mixin
  - core/mixin/margin
---
Sets margin-bottom

### scss
```scss
//
// @param  int  $coefficient
// @param  boolean  $apply-margin-scale
//

@include _margin-bottom(1);
@include _margin-bottom(10px);
@include _margin-bottom(1, false);
```
*/

@mixin _margin-bottom($coefficient, $apply-margin-scale: true) {
  @if _is-number($coefficient) and 0 != $coefficient {
    margin-bottom: _space($coefficient);

    @if $apply-margin-scale {
      margin-bottom: _space($coefficient, true, true);
    } @else {
      margin-bottom: _space($coefficient, true);
    }
  } @else {
    margin-bottom: $coefficient;
  }
}

/*
---
name: _margin-left()
category:
  - core/mixin
  - core/mixin/margin
---
Sets margin-left

### scss
```scss
//
// @param  int  $coefficient
// @param  boolean  $apply-margin-scale
//

@include _margin-left(1);
@include _margin-left(10px);
@include _margin-left(1, false);
```
*/

@mixin _margin-left($coefficient, $apply-margin-scale: true) {
  @if _is-number($coefficient) and 0 != $coefficient {
    margin-left: _space($coefficient);

    @if $apply-margin-scale {
      margin-left: _space($coefficient, true, true);
    } @else {
      margin-left: _space($coefficient, true);
    }
  } @else {
    margin-left: $coefficient;
  }
}

/*
---
name: _margin()
category:
  - core/mixin
  - core/mixin/margin
---
Sets margin

### scss
```scss
//
// @param  int  $coefficient-1
// @param  int  $coefficient-2
// @param  int  $coefficient-3
// @param  int  $coefficient-4
// @param  boolean  $apply-margin-scale
//

@include _margin(1);
@include _margin(1, 2);
@include _margin(1, 2, .5);
@include _margin(1, 2, .5, 2);
@include _margin(10px);
@include _margin(1, null, null, null, false);
```
*/

@mixin _margin($coefficient-1, $coefficient-2: null, $coefficient-3: null, $coefficient-4: null, $apply-margin-scale: true) {
  @if (_is-null($coefficient-2) and _is-null($coefficient-3) and _is-null($coefficient-4)) {
    @include _margin-top($coefficient-1, $apply-margin-scale);
    @include _margin-right($coefficient-1, $apply-margin-scale);
    @include _margin-bottom($coefficient-1, $apply-margin-scale);
    @include _margin-left($coefficient-1, $apply-margin-scale);
  } @else if (_is-null($coefficient-3) and _is-null($coefficient-4)) {
    @include _margin-top($coefficient-1, $apply-margin-scale);
    @include _margin-right($coefficient-2, $apply-margin-scale);
    @include _margin-bottom($coefficient-1, $apply-margin-scale);
    @include _margin-left($coefficient-2, $apply-margin-scale);
  } @else if (_is-null($coefficient-4)) {
    @include _margin-top($coefficient-1, $apply-margin-scale);
    @include _margin-right($coefficient-2, $apply-margin-scale);
    @include _margin-bottom($coefficient-3, $apply-margin-scale);
    @include _margin-left($coefficient-2, $apply-margin-scale);
  } @else {
    @include _margin-top($coefficient-1, $apply-margin-scale);
    @include _margin-right($coefficient-2, $apply-margin-scale);
    @include _margin-bottom($coefficient-3, $apply-margin-scale);
    @include _margin-left($coefficient-4, $apply-margin-scale);
  }
}
