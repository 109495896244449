/*
---
name: _select()
category:
  - core/abstract
  - core/abstract/form
---
Abstract selectbox component

### scss
```scss
.c-select {
  @include _select();
}
```

### html
```ejs
<div class="c-select">
  <select class="c-select__control">
    <option value="1">label-1</option>
    <option value="2">label-2</option>
    <option value="3">label-3</option>
  </select>
  <span class="c-select__toggle"></span>
</div>
```
*/

@mixin _select() {
  display: inline-block;

  &__toggle {
    display: none;
  }

  @supports (appearance: none) or (-webkit-appearance: none) or (-moz-appearance: none) {
    position: relative;

    &__control {
      position: relative;
      z-index: 1;
      appearance: none;
      display:inline-block;
      cursor: pointer;
      @include _form-control-base-border();
      background-color: transparent;
      line-height: 1;
      max-width: 100%;
      @include _padding(.5, 1.25, .5, .5);

      &:focus {
        & + .smf-select-control__toggle {
          &::before {
            border-color: #3a87fd;
          }
        }
      }

      &:disabled {
        & + .smf-select-control__toggle {
          background-color: _var-lightest-color-gray();
        }
      }
    }

    &__toggle {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      display: block;
      background-color: _var-color-white();

      &::before {
        position: absolute;
        top: calc(50% - 4px);
        right: _space(.5);
        content: '';
        display: block;
        @include _square(6px);
        border-style: solid;
        border-color: _var-color-text();
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
      }
    }
  }
}
