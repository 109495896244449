// FR > improve box-shadow transition performance
//        http://tobiasahlin.com/blog/how-to-animate-box-shadow/

.shadow {
  box-shadow: 0 0 _space(1) 0 rgba($black, 0.1) !important;
  &--card {
    box-shadow: 0 _space(0.5) _space(1) 0 rgba($black, 0.1) !important;
  }
  &--variant-1 {
    box-shadow:
      0 0 _space(0.25) 0 rgba(46, 62, 72, 0.12),
      0 _space(0.25) _space(0.75) 0 rgba(46, 62, 72, 0.12);
  }

  &--none {
    box-shadow: none;
  }
}
