$ui-stepper__nav-item--min-width: 100px;
$ui-stepper__circle--width: 32px;

.dc-stepper {
  display: flex;
  justify-content: center;

  &__content {
    display: none;
    width: 100%;
    padding: _space(2);

    background: $white;
    border-radius: $radius--lg;
    box-shadow:
      0 0px 28px rgba($black, 0.0625),
      0 0px 10px rgba($black, 0.0625);

    &.--active {
      display: block;
    }
  }

  &__step {
    position: relative;
    flex-grow: 1;
    min-width: $ui-stepper__nav-item--min-width;
    @include _padding(1, 0.5);
  }
}

//
//  Container
//
dc-step-form-navigation-bar {
  display: block;
  max-width: 400px;
  @include push--auto();
  margin-bottom: _space(0.25);
}

// context based configuration
dc-blacklist-process {
  dc-step-form-navigation-bar {
    max-width: 500px;
  }
}

//
//  O N E  item
//
.dc-stepper {
  //
  // step - step number
  &__circle {
    width: $ui-stepper__circle--width;
    height: $ui-stepper__circle--width;
    margin: 0 auto;

    background: $grey-3;
    border: 2px solid $grey-3;
    border-radius: 50%;

    text-align: center;
    font-family: $ff--yummo;
    line-height: $ui-stepper__circle--width;
    font-weight: bold;
    color: $white;

    &::before {
      font-weight: normal;
    }
  }
  //
  // Step - title
  &__text {
    margin-top: _space(0.5);

    text-align: center;
    line-height: 1.2;
    color: $grey-5;
  }
  //
  // step number
  &__step-no {
    //...
  }
  //
  // horizontal lines
  &__bar-left,
  &__bar-right {
    position: absolute;
    top: 37px;
    height: 1px;
    border-top: 1px solid $grey-3;
  }
  .dc-stepper__bar-right {
    right: 0;
    left: 50%;
    margin-left: 20px;
  }
  .dc-stepper__bar-left {
    left: 0;
    right: 50%;
    margin-right: 20px;
  }
}

//
// S T A T E S
//
.dc-stepper__step {
  &.--valid .dc-stepper__circle *,
  &.--failure .dc-stepper__circle * {
    display: none;
  }

  &:active {
    border-radius: 15% / 75%;
  }
  &:first-child:active {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &:last-child:active {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  // hide bars
  &:first-child .dc-stepper__bar-left,
  &:last-child .dc-stepper__bar-right {
    display: none;
  }

  &.--current {
    .dc-stepper__circle {
      background: $white;
      border-color: $grey-3;
      color: $grey-3;
      animation: animation--stepper 3s infinite;
      span {
        color: $black;
      }
    }
    .dc-stepper__text {
      color: $text;
      font-weight: bold;
    }
  }
  &.--active {
    .dc-stepper__circle {
      background: $action;
      border-color: $action;
    }
    .dc-stepper__text {
      color: $action;
    }
  }
  &.--valid {
    .dc-stepper__circle {
      &::before {
        font-family: $ff--icons--fontAwesome;
        content: '\f00c'; // fa-check
      }
    }
  }
  &.--failure {
    .dc-stepper__circle {
      position: relative;
      top: 2px;
      background: $danger;
      border-color: transparent;
      font-size: 18px;

      &::before {
        font-family: $ff--icons--fontAwesome;
        content: '\f12a'; // fa-exclamation
        color: $white;
      }
    }
    .dc-stepper__text,
    .dc-stepper__optional {
      color: $danger;
    }
    .dc-stepper__text {
      font-weight: bold;
    }
  }
  &.--previous {
    cursor: pointer;
    .dc-stepper__text {
      color: $action;
    }

    &:focus,
    &:hover,
    &:active {
      background: rgba($black, 0.03);
    }
  }
}
