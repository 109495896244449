.ui-spinner-legacy--page {
  display: block;
  text-align: center;
  height: 60%;
  width: 100%;
  min-height: 10rem;
  position: relative;
  div {
    @include centerer();
    height: 40px;
    width: 40px;
  }
}

.ui-spinner-legacy--section {
  display: flex;
  flex-direction: column;
  gap: _space(0.5);
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
  position: relative;
  div:not(.spinner--text) {
    height: 40px;
    width: 40px;
  }
  div.spinner--text {
    color: #808080;
  }
}

.dc-btn {
  ui-spinner-legacy {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    opacity: 0;
    transition: opacity $speed ease;

    background: $antracit;
  }

  &.dc-btn--progress {
    pointer-events: none;
    cursor: wait;

    background: transparent;
    border-color: $antracit;
    color: transparent !important;

    ui-spinner-legacy {
      opacity: 1;
      z-index: 2;
    }
  }
}
