/*


template
--------

  <details class="ui-details">
      <summary class="ui-details__trigger">
          ___TRIGGER___
      </summary>
      <div class="ui-details__content">
          ___CONTENT___
      </div>
  </details>


*/

.ui-details {
  @include transition();
  &:hover {
    border-color: $action;
  }

  &__trigger {
    font-weight: bold;
    text-decoration: underline;
    color: $action;
    cursor: pointer;
    user-select: none;

    @include transition();
    &:focus {
      outline: 0;
    }
  }

  &__content {
    padding: _space(1);
  }

  &[open] {
    margin-left: _space(-1);
    margin-right: _space(-1);

    background: $white;
    border-radius: $radius--lg;
    box-shadow: 0 0 _space(1) 0 rgba($grey-3, 0.6);

    .ui-details__trigger {
      padding: _space(1);

      &:focus {
        outline: 0;
      }
    }
  }
}
