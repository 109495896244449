/*


usage
-----

.element {
  @include truncate();
}


*/

@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
