/*
---
name: .c-row
category:
  - object/component
  - object/component/grid-system
---
Row component of flexbox based grid system

### html
```html
<div class="c-row">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2
  </div>
  <div class="c-row__col c-row__col--1-3">
    3
  </div>
</div>
```
```html
<div class="c-row c-row--reverse">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2
  </div>
  <div class="c-row__col c-row__col--1-3">
    3
  </div>
</div>
```
```html
<div class="c-row c-row--nowrap">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2
  </div>
  <div class="c-row__col c-row__col--1-3">
    3
  </div>
</div>
```
```html
<div class="c-row c-row--left">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2
  </div>
</div>
```
```html
<div class="c-row c-row--right">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2
  </div>
</div>
```
```html
<div class="c-row c-row--center">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2
  </div>
</div>
```
```html
<div class="c-row c-row--between">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2
  </div>
</div>
```
```html
<div class="c-row c-row--around">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2
  </div>
</div>
```
```html
<div class="c-row c-row--top">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2<br>
    2
  </div>
  <div class="c-row__col c-row__col--1-3">
    3<br>
    3<br>
    3
  </div>
</div>
```
```html
<div class="c-row c-row--bottom">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2<br>
    2
  </div>
  <div class="c-row__col c-row__col--1-3">
    3<br>
    3<br>
    3
  </div>
</div>
```
```html
<div class="c-row c-row--middle">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2<br>
    2
  </div>
  <div class="c-row__col c-row__col--1-3">
    3<br>
    3<br>
    3
  </div>
</div>
```
```html
<div class="c-row c-row--baseline">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2<br>
    2
  </div>
  <div class="c-row__col c-row__col--1-3">
    3<br>
    3<br>
    3
  </div>
</div>
```
```html
<div class="c-row c-row--collapse">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2
  </div>
  <div class="c-row__col c-row__col--1-3">
    3
  </div>
</div>
```
```html
<div class="c-row c-row--margin">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2
  </div>
  <div class="c-row__col c-row__col--1-3">
    3
  </div>
</div>
```
```html
<div class="c-row c-row--margin-s">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2
  </div>
  <div class="c-row__col c-row__col--1-3">
    3
  </div>
</div>
```
```html
<div class="c-row c-row--margin-l">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2
  </div>
  <div class="c-row__col c-row__col--1-3">
    3
  </div>
</div>
```
```html
<div class="c-row c-row--fill">
  <div class="c-row__col c-row__col--1-3">
    1
  </div>
  <div class="c-row__col c-row__col--1-3">
    2<br>
    2
  </div>
  <div class="c-row__col c-row__col--1-3">
    3<br>
    3<br>
    3
  </div>
</div>
```
```html
<div class="c-row c-row--margin">
  <div class="c-row__col c-row__col--auto">
    1
  </div>
  <div class="c-row__col c-row__col--auto">
    22
  </div>
  <div class="c-row__col c-row__col--auto">
    333
  </div>
</div>
```
```html
<div class="c-row c-row--margin">
  <div class="c-row__col c-row__col--justify">
    1
  </div>
  <div class="c-row__col c-row__col--justify">
    22
  </div>
  <div class="c-row__col c-row__col--justify">
    333
  </div>
</div>
```
```html
<div class="c-row c-row--margin">
  <div class="c-row__col c-row__col--fit">
    1
  </div>
  <div class="c-row__col c-row__col--fit">
    22
  </div>
  <div class="c-row__col c-row__col--fit">
    333
  </div>
</div>
```
*/

.#{$_prefix}c-row {
  @include _row();

  &__col {
    @include _row__col(null, null);
  }

  &--reverse {
    flex-direction: row-reverse;
  }

  @each $size, $screen-min in $_sizes {
    @include _media-min($screen-min) {
      $prefix: _size-prefix($size);

      &#{$prefix}-nowrap {
        flex-wrap: nowrap;
      }

      &#{$prefix}-left {
        justify-content: flex-start;
      }

      &#{$prefix}-right {
        justify-content: flex-end;
      }

      &#{$prefix}-center {
        justify-content: center;
      }

      &#{$prefix}-between {
        justify-content: space-between;
      }

      &#{$prefix}-around {
        justify-content: space-around;
      }

      &#{$prefix}-top {
        align-items: flex-start;
      }

      &#{$prefix}-bottom {
        align-items: flex-end;
      }

      &#{$prefix}-middle {
        align-items: center;
      }

      &#{$prefix}-baseline {
        align-items: baseline;
      }

      &#{$prefix}-collapse {
        @include _row--margin(0);

        > .#{$_prefix}c-row__col {
          @include _row__col--margin(0);
        }
      }

      &#{$prefix}-margin {
        @include _row--margin(1);

        > .#{$_prefix}c-row__col {
          @include _row__col--margin(1);
        }

        &-s {
          @include _row--margin(.5);

          > .#{$_prefix}c-row__col {
            @include _row__col--margin(.5);
          }
        }

        &-l {
          @include _row--margin(2);

          > .#{$_prefix}c-row__col {
            @include _row__col--margin(2);
          }
        }
      }

      &#{$prefix}-fill {
        > .#{$_prefix}c-row__col {
          display: flex;

          > * {
            @include _row__col--width(100%);
          }
        }
      }
    }
  }
}

@each $size, $screen-min in $_sizes {
  @include _media-min($screen-min) {
    $prefix: _size-prefix($size);

    @for $max_columns from 1 through $_max-columns {
      @for $i from 1 through $max_columns {
        $gcd: _gcd($i, $max_columns);

        @if (1 == $gcd) {
          %_bs-item#{$prefix}-gcd-#{$i / $gcd}-#{$max_columns / $gcd} {
            @include _row__col($i, $max_columns);
          }
        }
      }
    }

    %_bs-item#{$prefix}-full {
      @include _row__col--width(100%);
    }

    .#{$_prefix}c-row__col {
      &#{$prefix}-auto {
        @include _row__col--width(auto);
      }

      &#{$prefix}-justify {
        @include _row__col--width(1);
      }

      &#{$prefix}-fit {
        flex: 0 1 auto;
      }
    }
  }
}

.#{$_prefix}c-row__col {
  @each $size, $screen-min in $_sizes {
    @include _media-min($screen-min) {
      $prefix: _size-prefix($size);

      @for $max_columns from $_min-columns through $_max-columns {
        @for $i from 1 through $max_columns {
          $gcd: _gcd($i, $max_columns);

          &#{$prefix}-#{$i}-#{$max_columns} {
            @if ($max_columns != $i) {
              @extend %_bs-item#{$prefix}-gcd-#{$i / $gcd}-#{$max_columns / $gcd};
            } @else {
              @extend %_bs-item#{$prefix}-full;
            }
          }
        }
      }
    }
  }
}
