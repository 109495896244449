/*
---
name: .c-alert
category:
  - object/component
  - object/component/alert
---
Alert component

### html
```html
<div class="c-alert" role="alert">
  ...
</div>
```
*/

.#{$_prefix}c-alert {
  @include _alert();
}
