// FR >
//    > fix Copyright positioning
//    > set display: inline-block; (see problem here: <figure class="d--inline-block">)
figure {
  position: relative;
  margin: 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}
