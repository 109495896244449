//
//  FR > disable all whats not needed anymore
//       (many components were rewritten from scratch)
//

// FR > what to do with these variables?  (needed by SASS Basis)
$_font-path: '/node_modules/sass-basis/src/font';
$_base-font-size-px: 13px;
$_between-lines: calc(1rem / 3);

$_base-font-size: 1rem; // value used in v7

@import 'sass-basis/src/css/core/core';

//
//  Functions
//
@import 'sass-basis/src/css/core/function/color';
@import 'sass-basis/src/css/core/function/condition';
@import 'sass-basis/src/css/core/function/gcd';
@import 'sass-basis/src/css/core/function/media-query';
@import 'sass-basis/src/css/core/function/px2rem';
@import 'sass-basis/src/css/core/function/rem2px';
@import 'sass-basis/src/css/core/function/replace';
@import 'sass-basis/src/css/core/function/sanitize-animation-name';
@import 'sass-basis/src/css/core/function/size-prefix';
@import 'sass-basis/src/css/core/function/space';
@import 'sass-basis/src/css/core/function/strip-unit';
@import 'sass-basis/src/css/core/function/typography';

@import 'sass-basis/src/css/core/var/background-color';
@import 'sass-basis/src/css/core/var/border-radius';
@import 'sass-basis/src/css/core/var/color-black';
@import 'sass-basis/src/css/core/var/color-white';
@import 'sass-basis/src/css/core/var/color-gray';
@import 'sass-basis/src/css/core/var/color-text';
@import 'sass-basis/src/css/core/var/margin-scale';
@import 'sass-basis/src/css/core/var/typography';
@import 'sass-basis/src/css/core/var/space';

//
//  Variables
//
@import 'sass-basis/src/css/core/variable/typography';
@import 'sass-basis/src/css/core/variable/transition';
@import 'sass-basis/src/css/core/variable/border-radius';
@import 'sass-basis/src/css/core/variable/color';
@import 'sass-basis/src/css/core/variable/media-query';
@import 'sass-basis/src/css/core/variable/page-effect';

//
//  Mixins
//
@import 'sass-basis/src/css/core/mixin/animation';
@import 'sass-basis/src/css/core/mixin/background-image';
@import 'sass-basis/src/css/core/mixin/balloon-triangle';
@import 'sass-basis/src/css/core/mixin/balloon';
@import 'sass-basis/src/css/core/mixin/clearfix';
@import 'sass-basis/src/css/core/mixin/content';
@import 'sass-basis/src/css/core/mixin/event';
@import 'sass-basis/src/css/core/mixin/form';
@import 'sass-basis/src/css/core/mixin/ghost';
@import 'sass-basis/src/css/core/mixin/list';
@import 'sass-basis/src/css/core/mixin/margin';
@import 'sass-basis/src/css/core/mixin/overlay';
@import 'sass-basis/src/css/core/mixin/padding';
@import 'sass-basis/src/css/core/mixin/position';
@import 'sass-basis/src/css/core/mixin/media-query';
@import 'sass-basis/src/css/core/mixin/shape';
@import 'sass-basis/src/css/core/mixin/transition';
@import 'sass-basis/src/css/core/mixin/typography';
@import 'sass-basis/src/css/core/mixin/visibility';

//
//  Abstract
//
@import 'sass-basis/src/css/core/abstract/alert';
@import 'sass-basis/src/css/core/abstract/balloon';
@import 'sass-basis/src/css/core/abstract/btn';
@import 'sass-basis/src/css/core/abstract/checkbox';
@import 'sass-basis/src/css/core/abstract/container';
@import 'sass-basis/src/css/core/abstract/drawer';
@import 'sass-basis/src/css/core/abstract/entries';
@import 'sass-basis/src/css/core/abstract/entry';
@import 'sass-basis/src/css/core/abstract/form-control';
@import 'sass-basis/src/css/core/abstract/hamburger-btn';
@import 'sass-basis/src/css/core/abstract/hero';
@import 'sass-basis/src/css/core/abstract/ib-row';
@import 'sass-basis/src/css/core/abstract/input-group';
@import 'sass-basis/src/css/core/abstract/media';
@import 'sass-basis/src/css/core/abstract/meta';
@import 'sass-basis/src/css/core/abstract/navbar';
@import 'sass-basis/src/css/core/abstract/page-effect';
@import 'sass-basis/src/css/core/abstract/page-header';
@import 'sass-basis/src/css/core/abstract/pagination';
@import 'sass-basis/src/css/core/abstract/pre';
@import 'sass-basis/src/css/core/abstract/radio';
@import 'sass-basis/src/css/core/abstract/row';
@import 'sass-basis/src/css/core/abstract/section';
@import 'sass-basis/src/css/core/abstract/select';
@import 'sass-basis/src/css/core/abstract/site-branding';
@import 'sass-basis/src/css/core/abstract/spinner';

//
//  Normalize
//
@import 'normalize.css';
@import 'foundation/base';

//
//  Components
//
@import 'sass-basis/src/css/object/component/alert';
@import 'sass-basis/src/css/object/component/balloon';
@import 'sass-basis/src/css/object/component/btn';
@import 'sass-basis/src/css/object/component/checkbox';
@import 'sass-basis/src/css/object/component/container'; // -->>
@import 'sass-basis/src/css/object/component/drawer';
@import 'sass-basis/src/css/object/component/entries';
@import 'sass-basis/src/css/object/component/entry';
@import 'sass-basis/src/css/object/component/form-control';
@import 'sass-basis/src/css/object/component/hamburger-btn';
@import 'sass-basis/src/css/object/component/hero';
@import 'sass-basis/src/css/object/component/ic';
@import 'sass-basis/src/css/object/component/input-group';
@import 'sass-basis/src/css/object/component/media';
@import 'sass-basis/src/css/object/component/meta';
@import 'sass-basis/src/css/object/component/navbar';
@import 'sass-basis/src/css/object/component/page-effect';
@import 'sass-basis/src/css/object/component/page-header';
@import 'sass-basis/src/css/object/component/pagination';
@import 'sass-basis/src/css/object/component/radio';
@import 'sass-basis/src/css/object/component/row'; // -->>
@import 'sass-basis/src/css/object/component/section';
@import 'sass-basis/src/css/object/component/select';
@import 'sass-basis/src/css/object/component/site-branding';
@import 'sass-basis/src/css/object/component/spinner';

//
//  Utility
//
@import 'sass-basis/src/css/object/utility/animation';
@import 'sass-basis/src/css/object/utility/clearfix';
@import 'sass-basis/src/css/object/utility/content';
@import 'sass-basis/src/css/object/utility/hidden';
@import 'sass-basis/src/css/object/utility/img';
@import 'sass-basis/src/css/object/utility/pull';
@import 'sass-basis/src/css/object/utility/text';
@import 'sass-basis/src/css/object/utility/transition';
@import 'sass-basis/src/css/object/utility/visible';
