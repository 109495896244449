//
//  DISPLAY
//
.d {
  &--none {
    display: none !important;
  }
  &--block {
    display: block !important;
  }
  &--inline {
    display: inline !important;
  }
  &--inline-block {
    display: inline-block !important;
  }
  &--grid {
    display: grid !important;
  }
  &--flex {
    display: flex !important;
  }

  &--hidden {
    visibility: hidden !important;
  }
}
