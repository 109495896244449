//
//  h1 - h6
//
.h1 {
  font-size: $h1;
}
.h2 {
  font-size: $h2;
}
.h3 {
  font-size: $h3;
}
.h4 {
  font-size: $h4;
}
.h5 {
  font-size: $h5;
}
.h6 {
  font-size: $h6;
}

.h1 {
  margin-bottom: _space(1);
}
.h2 {
  margin-bottom: _space(1);
}
.h3 {
  margin-bottom: _space(1);
}
.h4 {
}
.h5 {
}
.h6 {
  font-weight: 400;
}

.h1,
.h2,
.h3 {
  margin-bottom: _space(1);
  font-family: $ff--yummo;
  font-weight: normal;
  color: darken($grey-5, 25%);

  .subheading {
    display: block;
    font-size: 60%;
    font-family: $ff--default;
    font-weight: normal;
  }
}

.h4,
.h5,
.h6 {
  margin-bottom: _space(0.5);
  color: $grey-5;
}

//
//  Mini heading
//
.heading--mini {
  display: block;
  margin-bottom: _space(0.25);

  font-family: $ff--arial;
  font-weight: 700;
  font-size: 11px;
  line-height: 1;
  text-transform: uppercase;
  color: $grey-5;
}
