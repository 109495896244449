/*
---
name: _input-group()
category:
  - core/abstract
  - core/abstract/form
---
Abstract input group component

### scss
```scss
.c-input-group {
  @include _input-group();
}
```

### html
```ejs
<div class="c-input-group">
  <div class="c-input-group__addon">@</div>
  <div class="c-input-group__field">
    <input type="text">
  </div>
  <button class="c-input-group__btn">Go</button>
</div>
```
*/

@mixin _input-group() {
  display: flex;
  flex-wrap: nowrap;
  border-radius: _var-border-radius();

  > :first-child {
    position: relative;
    border-radius: _var-border-radius() 0 0 _var-border-radius();
    border-right: none;
  }

  > :nth-last-child(2) {
    position: relative;
    z-index: 1;
  }

  > :last-child {
    position: relative;
    z-index: 0;
    left: -1px;
    border-radius: 0 _var-border-radius() _var-border-radius() 0;

    &:hover, &:active, &:focus {
      z-index: 1;
    }
  }

  &__addon {
    background-color: _var-color-white();
    border: 1px solid _var-form-control-border-color();
    @include _form-control-base-padding();
    flex: none;
  }

  &__field {
    display: flex;
    flex: 1;

    > input[type='email'],
    > input[type='number'],
    > input[type='password'],
    > input[type='search'],
    > input[type='tel'],
    > input[type='text'],
    > input[type='url'] {
      @include _form-control();
      -webkit-appearance: none;
      border-radius: 0;
      flex: 1 1 0%;
      width: 100%;
    }
  }

  &__btn {
    @include _btn();
    @include _form-control-base-border();
    @include _form-control-base-padding();
    background-color: _var-color-white();
    color: _var-color-text();
    flex: none;
    overflow: visible;
  }
}
