/*


usage
-----

.element {
  @include clearfix();
}


*/

@mixin clearfix() {
  &::before,
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

.clearfix {
  @include clearfix();
}
