fb-node-card {
  .node-card {
    width: 460px;
    padding: 0.7rem 1rem;
    margin: 0.25rem 0.1rem;
    line-height: 1.4;
    cursor: pointer;
    position: relative;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__checkbox {
      display: flex;
      align-self: center;
      font-size: 1.5rem;
      width: 1.5rem;

      .fa {
        color: #15074e;
        opacity: 0.3;

        &.fa-square-o {
          display: inline-block;
        }

        &.fa-check-square {
          display: none;
        }
      }
    }

    &__ico {
      display: flex;
      width: 4rem;
      height: 4rem;
      border-radius: 100px;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.4rem;
      font-family: $ff--yummo;
      transition: all 150ms ease-out;
      background-color: #fff;
      border: 1px solid transparent;

      &:hover {
        z-index: 2;
        transform: scale(2.2);
        box-shadow: 0 2px 8px #757575;
        border: 1px solid #e2e2e5;
      }

      &.team-ico {
        background-color: transparent;
        border: none;

        &:hover {
          transform: none;
          box-shadow: none;
          border: none;
        }
      }
    }

    &__name {
      font-size: 1.2rem;
      font-weight: 500;
    }

    &__position {
      opacity: 0.6;
      width: 220px;
    }

    &__controls {
      font-size: 2rem;

      .fa,
      img {
        color: #15074e;
        opacity: 0.1;
        transition: all 200ms ease-out;
      }

      img {
        width: 26px;
        height: 26px;
      }
    }

    &__expander {
      position: absolute;
      left: -3rem;
      top: 1.4rem;
      font-size: 1.6rem;
      color: #453e6c70;

      a {
        color: #15074e;
        opacity: 0.3;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    &.selected {
      background-color: #dad1f8;

      .node-card__checkbox {
        .fa {
          opacity: 1;

          &.fa-square-o {
            display: none;
          }
          &.fa-check-square {
            display: inline-block;
          }
        }
      }
    }

    &.expandable {
      margin: 0 0 0 4rem;
    }

    &:hover {
      .node-card__checkbox {
        .fa {
          &.fa-square-o {
            display: none;
          }

          &.fa-check-square {
            display: inline-block;
          }
        }
      }

      .node-card__controls {
        .fa,
        img {
          color: #15074e;
          opacity: 0.3;
        }

        a:not(.requesting) {
          &:hover {
            .fa,
            img {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}
