/*


usage
-----

.element {
  @include centerer();
}


*/

@mixin centerer() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
