/*
---
name: _background-image-cover()
category:
  - core/mixin
  - core/mixin/background-image
---
Background image cover + centering

### scss
```scss
.c-foo {
  @include _background-image-cover();
  background-image: url(...);
}
```
*/

@mixin _background-image-cover() {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

/*
---
name: _background-image-fixed()
category:
  - core/mixin
  - core/mixin/background-image
---
Background image fixed

### scss
```scss
.c-foo {
  @include _background-image-fixed();
  background-image: url(...);
}
```
*/

@mixin _background-image-fixed() {
  background-attachment: fixed;
}
