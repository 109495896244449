@import 'styles/variables';

.car-picker {
  &--wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &--menu {
    margin-left: 0;
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    li {
      font-size: 15px;
      padding: 6px 24px;
      border: 1px solid transparent;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      &:hover {
        background: $grey-1;
        cursor: pointer;
        box-shadow: 0px -3px 7px #888888;
        font-weight: 600;
        &::after {
          display: none;
        }
      }
      &.active {
        position: relative;
        border: 1px solid $grey-4;
        border-bottom: #fafafa;
        &::after {
          content: '';
          z-index: 1;
          position: absolute;
          height: 0.5px;
          width: 100%;
          background: #fafafa;
          bottom: -1px;
          left: 0px;
        }
      }
    }
  }
}

.car {
  &--wrapper {
    height: 1000px;
    width: 900px;
    position: relative;
    padding-top: 40px;
    border: 1px solid $grey-4;
    border-radius: 5px;
    a {
      text-decoration: none;

      .part--description {
        border: 1px solid transparent;
        padding-left: 0;
        margin-left: 9px;
        border-radius: 4px;
        color: $grey-5;
        background-color: $white;
        font-weight: bold;
        font-size: 14px;
        z-index: 1;
        position: relative;
      }

      .part--description:not(.other-part) {
        display: none;
      }
      .fa-check {
        color: $white;
        background-color: $danger;
        border: 5px solid $danger;
        border-radius: 13px;
        padding: 1px;
      }
      .fa-plus {
        color: $white;
        background-color: $brand;
        border: 5px solid $brand;
        border-radius: 13px;
        padding: 1px 2px;
        transition: transform 0.2s;
      }
      &:hover {
        .fa-plus {
          background-color: $danger;
          border: 5px solid $danger;
          transform: scale(1.1);
        }

        .part--description {
          color: $danger;
          background-color: $white;
          display: inline;
        }
      }
    }

    .main-text {
      position: absolute;
      top: 12px;
      color: $grey-5;
      &--bottom {
        color: $grey-5;
        &.last-margin {
          .other-damages {
            &:last-child {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    .other-damages {
      display: block;
      margin-left: 20px;
      margin-top: 10px;
    }
    .car--bg {
      position: relative;
      margin: 0 auto;
      background: url('/assets/images/auto_vyznacovani_poskozeni.png') no-repeat;
      height: 760px;
      width: 850px;
      .wheelFrontLeft {
        position: absolute;
        left: 380px;
        top: 433px;
      }
      .fenderFrontLeft {
        position: absolute;
        top: 380px;
        left: 417px;
      }
      .glassFrontLeftDoor {
        position: absolute;
        top: 333px;
        left: 531px;
      }
      .doorFrontLeft {
        position: absolute;
        left: 491px;
        top: 403px;
      }
      .wheelBackLeft {
        position: absolute;
        left: 710px;
        top: 433px;
      }
      .fenderBackLeft {
        position: absolute;
        left: 766px;
        top: 375px;
      }
      .glassBackLeftDoor {
        position: absolute;
        top: 332px;
        left: 638px;
      }
      .doorBackLeft {
        left: 613px;
        position: absolute;
        top: 403px;
      }

      .wheelFrontRight {
        position: absolute;
        left: 737px;
        top: 663px;
      }
      .fenderFrontRight {
        position: absolute;
        left: 705px;
        top: 609px;
      }
      .glassFrontRightDoor {
        position: absolute;
        top: 564px;
        left: 585px;
      }
      .doorFrontRight {
        left: 607px;
        position: absolute;
        top: 629px;
      }
      .wheelBackRight {
        position: absolute;
        left: 407px;
        top: 663px;
      }
      .fenderBackRight {
        position: absolute;
        top: 605px;
        left: 349px;
      }
      .glassBackRightDoor {
        position: absolute;
        top: 562px;
        left: 474px;
      }
      .doorBackRight {
        position: absolute;
        left: 495px;
        top: 629px;
      }

      .coolingMask {
        position: absolute;
        top: 395px;
        left: 120px;
      }
      .bumperFront {
        position: absolute;
        top: 437px;
        left: 120px;
      }
      .headGlass {
        position: absolute;
        top: 23px;
        left: 107px;
      }
      .headLightRight {
        position: absolute;
        top: 387px;
        left: 32px;
      }
      .headLightLeft {
        position: absolute;
        top: 387px;
        left: 208px;
      }

      .bumperBack {
        position: absolute;
        top: 647px;
        left: 120px;
      }
      .backGlass {
        position: absolute;
        top: 13px;
        left: 102px;
      }
      .tailLightRight {
        position: absolute;
        top: 605px;
        left: 208px;
      }
      .tailLightLeft {
        position: absolute;
        top: 605px;
        left: 32px;
      }

      .headGlass {
        position: absolute;
        top: 135px;
        left: 325px;
      }
      .backGlass {
        position: absolute;
        top: 135px;
        left: 610px;
      }
      .tailGateBack {
        position: absolute;
        top: 135px;
        left: 695px;
      }
      .roof {
        position: absolute;
        top: 135px;
        left: 460px;
      }
      .hoodsFront {
        position: absolute;
        top: 135px;
        left: 230px;
      }
      .mirrorLeft {
        position: absolute;
        top: 255px;
        left: 338px;
      }
      .mirrorRight {
        position: absolute;
        top: 18px;
        left: 338px;
      }
      .glassRoof {
        position: absolute;
        top: 135px;
        left: 395px;
      }
    }
  }
}

.choosen-parts {
  font-family: 'direct_yummosemibold', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 20px;
  &--title {
    color: $danger;
  }
  span {
  }
}
