@font-face {
  font-family: 'direct_yummosemibold';
  src: url('/assets/fonts/directyummo-semibold-webfont.eot');
  src:
    url('/assets/fonts/directyummo-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/directyummo-semibold-webfont.woff2') format('woff2'),
    url('/assets/fonts/directyummo-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
