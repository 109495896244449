/*


HTML code
==========

<span class="ui-status --modifier-class"></span>



modifier class  |   color
-------------------------
--go                green
--wait              orange
--stop              red
--idle              yellow
--inactive          grey

NONE (default)      black


*/

.ui-status {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;

  // ball
  //
  &::before {
    @include pseudo();
    top: -1px;
    left: 0;
    width: 10px;
    height: 10px;
    border: 1px solid rgba($white, 0.5);

    background: $black;
    border-radius: 50%;
  }

  // ball + text
  //
  &:not(:empty) {
    padding-left: _space(0.65);
    font-family: $ff--arial;
    font-size: 10px !important;
    line-height: 1;
    text-transform: uppercase;
  }
}

//
//  Variants
//
.ui-status {
  &.--go::before {
    background: $active;
  }
  &.--wait::before {
    background: $warning;
  }
  &.--stop::before {
    background: $danger;
  }
  &.--idle::before {
    background: $highlight;
  }
  &.--inactive::before {
    background: $passive;
  }
  &.--fixed-width {
    width: 1.4rem;
  }
}

//
// Aliases (business codes)
//
.ui-status {
  &.--ACTIVE {
    @extends &.--go;
  }
  &.--INACTIVE {
    @extends &.--innactive;
  }
}

//////////////////////////////////////
//
//
//
//
//
//
//
//  FR > replace by .ui-status
//
.dc-status {
  // only text label, no colors
  &--text {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &--pasive,
  &--TERMINATED,
  &--INTERRUPTED,
  &--CANCELED,
  &--CLOSED,
  &--NONEXISTENT {
    color: $passive;
  }
  &--progress {
    color: $progress;
  }
  &--active {
    color: $active;
  }

  // todo: remove important
  &-border {
    &--pasive,
    &--TERMINATED,
    &--INTERRUPTED,
    &--CANCELED,
    &--CLOSED,
    &--NONEXISTENT {
      border-color: $passive !important;
    }
    &--progress {
      border-color: $progress !important;
    }
    &--active {
      border-color: $active !important;
      .dc-status--text {
        color: darken($active, 15%) !important;
      }
    }
  }
}

//
//  Card ~ states
//
// FR > create status.sass?
.dc-status-border {
  &--active,
  &--ACTIVE,
  &--ALIVE,
  &--CREATED {
    border-color: $active !important;
  }
  &--OPENED,
  &--CREATED,
  &--POSTOPENED {
    border-color: #ffce44;
  }
  &--REJECTED {
    border-color: $danger;
  }
  &--CLOSED,
  &--CANCELED {
    border-color: $grey-30 !important;
  }
}
