/*
---
name: _alert()
category:
  - core/abstract
---
Abstract alert component

### scss
```scss
.c-alert {
  @include _alert();
  @include _padding(1);
  background-color: #4b9ad8;
  color: #fff;
}
```

### html
```ejs
<div class="c-alert" role="alert">
  ...
</div>
```
*/

@mixin _alert() {
  display: block;
}
