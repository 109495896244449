.p-fileupload.p-fileupload-advanced {
  @apply rounded-lg border-[1px] border-[var(--input-border)] bg-white hover:border-[var(--input-border-hover)];
  .p-fileupload-content {
    @apply min-h-fit rounded-b-lg border-none p-0;

    &.p-fileupload-highlight {
      .p-fileupload-placeholder {
        @apply border-neutral-400 bg-neutral-100;
      }
    }

    .p-progressbar {
      @apply hidden;
    }

    .p-messages {
      .p-message {
        @apply mx-4 mb-3;
        .p-message-wrapper {
          @apply py-1;
        }
        .p-message-icon {
          @apply hidden;
        }
        .p-message-summary {
          @apply font-medium;
        }
      }
    }
  }

  .p-fileupload-placeholder {
    @apply m-4 mt-0 flex justify-center rounded-lg border-2 border-dashed bg-neutral-50 p-5;

    ins {
      @apply ml-1;
    }
  }

  .p-fileupload-buttonbar {
    @apply gap-2 rounded-t-lg border-none bg-white;

    .p-button {
      @apply mr-0 rounded-lg;
      .p-button-icon {
        @apply inline-flex min-w-[20px];
      }
    }
  }

  .p-fileupload-files {
    @apply mx-4 max-h-[20rem] overflow-y-auto border-t-[1px] border-t-neutral-100 bg-white py-3;

    .p-fileupload-row {
      @apply grid grid-cols-[auto_8rem_4rem] border-t border-neutral-100 first:border-0;

      > div {
        @apply w-fit p-0 pr-2;
      }

      div:first-of-type {
        @apply hidden;
      }
      div:first-of-type > img {
        @apply w-10 max-w-[10rem];
      }

      div:last-of-type {
        @apply flex w-full justify-end;
        .p-button {
          @apply h-8 w-8 rounded-full bg-transparent text-neutral-500 hover:border-none hover:text-neutral-700;
        }
      }

      .p-fileupload-filename {
        @apply w-auto truncate;
      }
    }
  }

  .p-fileupload-files ~ .p-fileupload-placeholder {
    @apply hidden;
  }
}

.p-fileupload-hint {
  @apply mt-3 px-0 text-xs text-neutral-500;
}
