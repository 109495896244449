/*
---
name: $_transition-duration
category:
  - core/variable
---
Animation time

### scss
```scss
$_transition-duration: .1s;
```
*/

$_transition-duration: .2s !default;

/*
---
name: $_transition-function-timing
category:
  - core/variable
---
Transition function timing

### scss
```scss
//
// @link  http://www.w3schools.com/cssref/css3_pr_transition-timing-function.asp
//

$_transition-function-timing: ease-out;
```
*/

$_transition-function-timing: ease-out !default;

/*
---
name: $_transition-delay
category:
  - core/variable
---
Transition function timing

### scss
```scss
$_transition-delay: 1s;
```
*/

$_transition-delay: 0s !default;
