//
//  TEXT
//
.tc {
  &--core {
    color: $core !important;
  }
  &--core-1 {
    color: $core-1 !important;
  }
  &--core-2 {
    color: $core-2 !important;
  }
  &--core-3 {
    color: $core-3 !important;
  }
  &--core-4 {
    color: $core-4 !important;
  }
  &--core-5 {
    color: $core-5 !important;
  } // > too dark - can not be used
  &--core-6 {
    color: $core-6 !important;
  } // > too dark - can not be used

  &--brand {
    color: $brand !important;
  }
  &--brand-alt {
    color: $brand-alt !important;
  }
  &--black {
    color: $black !important;
  }
  &--white {
    color: $white !important;
  }

  &--grey-1 {
    color: $grey-1 !important;
  }
  &--grey-2 {
    color: $grey-2 !important;
  }
  &--grey-3 {
    color: $grey-3 !important;
  }
  &--grey-4 {
    color: $grey-4 !important;
  }
  &--grey-5 {
    color: $grey-5 !important;
  }

  // neutral grey
  &--milk {
    color: $milk !important;
  }
  &--concrete {
    color: $concrete !important;
  }
  &--grey-10 {
    color: $grey-10 !important;
  }
  &--grey-20 {
    color: $grey-20 !important;
  }
  &--grey-30 {
    color: $grey-30 !important;
  }
  &--grey-40 {
    color: $grey-40 !important;
  }
  &--grey-50 {
    color: $grey-50 !important;
  }
  &--grey-60 {
    color: $grey-60 !important;
  }
  &--grey-70 {
    color: $grey-70 !important;
  }
  &--grey-80 {
    color: $grey-80 !important;
  }
  &--grey-90 {
    color: $grey-90 !important;
  }

  &--action {
    color: $action !important;
  }
  &--highlight {
    color: $highlight !important;
  }
  &--active {
    color: $active !important;
  }
  &--passive {
    color: $passive !important;
  }
  &--progress {
    color: $progress !important;
  }

  &--info {
    color: $info !important;
  }
  &--warning {
    color: $warning !important;
  }
  &--danger {
    color: $danger !important;
  }
  &--success {
    color: $success !important;
  }
}

//
//  BACKGROUND
//
.bg {
  &--transparent {
    background-color: transparent !important;
  }

  &--core {
    background-color: $core !important;
  }
  &--core-1 {
    background-color: $core-1 !important;
  }
  &--core-2 {
    background-color: $core-2 !important;
  }
  &--core-3 {
    background-color: $core-3 !important;
  }
  &--core-4 {
    background-color: $core-4 !important;
  }
  &--core-5 {
    background-color: $core-5 !important;
  } // > too dark - can not be used
  &--core-6 {
    background-color: $core-6 !important;
  } // > too dark - can not be used

  &--brand {
    background-color: $brand !important;
  }
  &--brand-alt {
    background-color: $brand-alt !important;
  }
  &--black {
    background-color: $black !important;
  }
  &--white {
    background-color: $white !important;
  }

  &--grey-1 {
    background-color: $grey-1 !important;
  }
  &--grey-2 {
    background-color: $grey-2 !important;
  }
  &--grey-3 {
    background-color: $grey-3 !important;
  }
  &--grey-4 {
    background-color: $grey-4 !important;
  }
  &--grey-5 {
    background-color: $grey-5 !important;
  }

  // neutral grey
  &--milk {
    background-color: $milk !important;
  }
  &--concrete {
    background-color: $concrete !important;
  }
  &--grey-10 {
    background-color: $grey-10 !important;
  }
  &--grey-20 {
    background-color: $grey-20 !important;
  }
  &--grey-30 {
    background-color: $grey-30 !important;
  }
  &--grey-40 {
    background-color: $grey-40 !important;
  }
  &--grey-50 {
    background-color: $grey-50 !important;
  }
  &--grey-60 {
    background-color: $grey-60 !important;
  }
  &--grey-70 {
    background-color: $grey-70 !important;
  }
  &--grey-80 {
    background-color: $grey-80 !important;
  }
  &--grey-90 {
    background-color: $grey-90 !important;
  }

  &--action {
    background-color: $action !important;
  }
  &--highlight {
    background-color: $highlight !important;
  }
  &--active {
    background-color: $active !important;
  }
  &--passive {
    background-color: $passive !important;
  }
  &--progress {
    background-color: $progress !important;
  }

  &--info {
    background-color: $info !important;
  }
  &--warning {
    background-color: $warning !important;
  }
  &--danger {
    background-color: $danger !important;
  }
  &--success {
    background-color: $success !important;
  }
}

//
//  BORDER
//
.bc {
  &--core {
    border-color: $core !important;
  }
  &--core-1 {
    border-color: $core-1 !important;
  }
  &--core-2 {
    border-color: $core-2 !important;
  }
  &--core-3 {
    border-color: $core-3 !important;
  }
  &--core-4 {
    border-color: $core-4 !important;
  }
  &--core-5 {
    border-color: $core-5 !important;
  } // > too dark - can not be used
  &--core-6 {
    border-color: $core-6 !important;
  } // > too dark - can not be used

  &--brand {
    border-color: $brand !important;
  }
  &--brand-alt {
    border-color: $brand-alt !important;
  }
  &--black {
    border-color: $black !important;
  }
  &--white {
    border-color: $white !important;
  }

  &--grey-1 {
    border-color: $grey-1 !important;
  }
  &--grey-2 {
    border-color: $grey-2 !important;
  }
  &--grey-3 {
    border-color: $grey-3 !important;
  }
  &--grey-4 {
    border-color: $grey-4 !important;
  }
  &--grey-5 {
    border-color: $grey-5 !important;
  }

  // neutral grey
  &--milk {
    border-color: $milk !important;
  }
  &--concrete {
    border-color: $concrete !important;
  }
  &--grey-10 {
    border-color: $grey-10 !important;
  }
  &--grey-20 {
    border-color: $grey-20 !important;
  }
  &--grey-30 {
    border-color: $grey-30 !important;
  }
  &--grey-40 {
    border-color: $grey-40 !important;
  }
  &--grey-50 {
    border-color: $grey-50 !important;
  }
  &--grey-60 {
    border-color: $grey-60 !important;
  }
  &--grey-70 {
    border-color: $grey-70 !important;
  }
  &--grey-80 {
    border-color: $grey-80 !important;
  }
  &--grey-90 {
    border-color: $grey-90 !important;
  }

  &--action {
    border-color: $action !important;
  }
  &--highlight {
    border-color: $highlight !important;
  }
  &--active {
    border-color: $active !important;
  }
  &--passive {
    border-color: $passive !important;
  }
  &--progress {
    border-color: $progress !important;
  }

  &--info {
    border-color: $info !important;
  }
  &--warning {
    border-color: $warning !important;
  }
  &--danger {
    border-color: $danger !important;
  }
  &--success {
    border-color: $success !important;
  }
}
