//
//  used in template:;
// /src/client/app/+tasks/tasks-list/tasks-list.component.html:

//
//  Search input
//
// FR > apply to all existing (Spotlight / Daktela / Tasks
.ui-search {
  position: relative;

  .c-form-control {
    @extend .disable--max-width !optional;
    padding-left: _space(1.5);
    padding-right: _space(2);

    border-radius: $radius--md;
    border-width: 2px;
  }

  //  icons
  //
  .fa-search,
  .fa-times-circle {
    position: absolute;
    top: _space(0.5);
  }

  .fa-search {
    left: _space(0.5);
    margin-top: -2px;

    font-size: _space(0.75);
    color: $grey-3;
  }

  .fa-times-circle {
    right: _space(0.5);
    margin-top: -2px;

    font-size: _space(0.9);
    color: $grey-5;

    //opacity: 0;
    @include transition();
    &:hover {
      cursor: pointer;
      color: $danger;
    }
  }

  /*
  &:focus-within {
    .ui-search__close { opacity: 1; }
  }
  */
}

//
//  Search input - Task list -- REMOVE WHEN IMPLEMENTED
//
.ui-search.--work-in-progress {
  input,
  .fa {
    filter: blur(3px);
  }

  &::after {
    content: 'Vyhledávání je v přípravě.';
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    //padding: _space(.5) _space(1);

    font-weight: bold;
    letter-spacing: 1px;
    text-shadow: 0 0 3px $black;
    color: $white;

    background: rgba($black, 0.3);
    border-radius: $radius--md;
  }
}
