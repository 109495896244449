p-checkbox {
  .p-checkbox {
    .p-checkbox-box {
      @apply border-[1px] border-neutral-300;
    }
  }
  .p-checkbox-label {
    @apply cursor-pointer;
  }

  .p-checkbox.p-checkbox-small {
    @apply h-auto w-auto;

    &.p-checkbox-readonly .p-disabled,
    &.p-checkbox-readonly + .p-checkbox-label.p-disabled {
      @apply font-normal text-gray-900 opacity-100;
    }

    .p-checkbox-box {
      @apply h-[16px] w-[16px];

      .p-checkbox-icon {
        @apply scale-75;
      }
    }
  }
}

p-checkbox + label {
  @apply ml-2 inline-flex;
}
