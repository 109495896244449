/*
---
name: _radio()
category:
  - core/abstract
  - core/abstract/form
---
Abstract radio button component

### scss
```scss
.c-radio {
  @include _radio();
}
```

### html
```ejs
<label>
  <span class="c-radio">
    <input type="radio" class="c-radio__control">
    <span class="c-radio__label">...</span>
  </span>
</label>
```
*/

@mixin _radio() {
  display: inline-flex;
  align-items: center;

  @supports (appearance: none) or (-webkit-appearance: none) or (-moz-appearance: none) {
    &__control {
      appearance: none;
      position: relative;
      display: inline-block;
      cursor: pointer;
      @include _form-control-base-border();
      background-color: _var-color-white();
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .035);
      @include _square(16px);
      border-radius: 100%;

      &::before {
        top: calc(50% - 3px);
        left: calc(50% - 3px);
        margin: 0 !important;
        @include _circle(6px);
        background-color: _var-color-white();
        visibility: hidden;
      }

      &:checked {
        background-color: #3a87fd;
        border-color: #3a87fd;

        &::before {
          position: absolute;
          content: '';
          display: block;
          visibility: visible;
        }
      }

      &:disabled {
        background-color: _var-lightest-color-gray();

        &::before {
          background-color: _var-dark-color-gray();
        }
      }
    }
  }
}
