//
// START: pozicování Datepickeru
.dc-datepicker {
  margin-top: _space(0.5);
  margin-left: _space(-0.75);
  margin-right: _space(-0.75);
  padding: _space(0.5) _space(0.75);

  //margin-top: _space(-3);
  //padding-top: _space(3.5);
}

.dc-datepicker {
  margin-top: -18px;
  padding-top: 18px;

  // jedna chyba
  // FR > refuck class names
  &.one-error,
  &.two-error {
    margin-top: -42px;
    padding-top: 42px;
  }

  // jedna chyba + druhá = dvě chyby
  &.one-error.two-error {
    margin-top: -65px;
    padding-top: 65px;
  }
}

// END: pozicování Datepickeru

.temp__dateTime--master {
  > label {
    position: relative;
    z-index: 20;
  }
}

.ui-date-time-field-legacy__inputs {
  position: relative;
  z-index: 20;

  display: grid;
  grid-template-columns: 1fr 70px;
  grid-gap: _space(0.5);
  align-items: center;

  width: $form__element--max-width;

  > .c-form-control {
    box-shadow: 0 0 4px 4px $white;
  }
}

/*
.dc-native-form__date-time-field {
  // chyba....1ks
  &.one-error,
  &.two-error {
    .dc-datepicker { margin-top: _space(-4.125); }
  }

  // chyba....2ks
  &.one-error.two-error {
    .dc-form__validation.--failure {
      display: inline-block;

      + .dc-form__validation.--failure {
        padding-left: _space(1);
      }
    }
    .dc-datepicker { margin-top: _space(-6.125); }
  }
}
*/

dc-manual-comm-entry {
  dc-step-form-navigation-bar {
    max-width: 400px;
    @include push--auto();
  }

  .dc-stepper__content {
    padding: _space(2);
    background: $white;
    border-radius: $radius--lg;
  }

  .dc-datepicker {
    background: $milk;
    border: 2px solid $grey-2;
    border-radius: $radius--lg;

    // START: FR > refuck
    .d--grid {
      grid-template-columns: 1fr;

      @media screen and (min-width: $breakpoint__lg--min) {
        grid-template-columns: 250px 250px;
        grid-gap: _space(0.5);
      }

      .dc-datepicker__output {
        padding-top: _space(1);
        border-top: 1px dotted $grey-3;

        @media screen and (min-width: $breakpoint__lg--min) {
          margin-top: _space(1);
          padding: _space(1);
          padding-top: 0;

          border-top: 0;
          border-left: 1px dotted $grey-3;
        }
      }
    }
    // END: FR > refuck
  }

  // fix: datepicker open => invisible error message
  .dc-form__validation.--failure {
    position: relative;
    z-index: 20;
  }
}

.dc-datepicker {
  .d--grid {
    //position: relative;
  }

  &__close {
    position: absolute;
    top: -13px;
    right: 13px;
    z-index: 20;

    background: $milk;
    border-color: $grey-3;
    border-width: 2px;
    border-top: 0;
    border-bottom: 0;
  }
}

.dc-date--hint {
  color: #75757580;
  font-size: 13px;
}
