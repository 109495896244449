// FR > temporary location for .ui-sidebar__list
//      move it to sidebar.scss?
.ui-sidebar__list {
  .ui-sidebar-item {
    margin-left: _space(-0.5);
  }
}

.ui-sidebar-item {
  margin-bottom: _space(0.5);

  background: $concrete;
  border-radius: $radius;

  cursor: default;

  // shared
  //
  > header,
  &__body,
  > footer {
    @include _padding(0.25, 0.5);
  }

  //  header
  //
  > header {
    border-bottom: 2px solid darken($concrete, 10%);
  }

  //  body
  //
  &__body {
    //...
  }

  //  footer
  //
  > footer {
    padding-top: 0;
  }

  //  incoming / outcoming
  //
  //&[data-direction=OUTGOING] > header .ui-indicator { color: $black; }
  &[data-direction='INCOMING'] > header .ui-indicator {
    color: $temp__PURPLE;
  }

  //  status
  //
  &[data-status='FAILURE'] > header {
    border-color: $danger;
  }
  &[data-status='SUCCESS'] > header {
    border-color: $success;
  }
  // claims document status
  &[data-status='REQUIRED'] > header {
    border-color: $danger;
  }
  &[data-status='CREATED'] > header {
    border-color: $neutral;
  }
  &[data-status='CONFIRMED'] > header {
    border-color: $success;
  }
  &[data-status='REJECTED'] > header {
    border-color: $danger;
  }
  &[data-status='DELETED'] > header {
    border-color: $passive;
  }

  //  status - 2021
  //
  &[data-status='ADDRESS_ERROR'] > header,
  &[data-status='OTHER_ERROR'] > header,
  &[data-status='SEND_ERROR'] > header,
  &[data-status='SPAM'] > header {
    border-color: $danger;
  }
  &[data-status='SENT'] > header,
  &[data-status='DELIVERED'] > header,
  &[data-status='RECEIVED'] > header,
  &[data-status='DONE'] > header {
    border-color: $success;
  }
  &[data-status='PLANNED'] > header,
  &[data-status='BEFORE_SEND'] > header {
    border-color: $neutral;
  }
  &[data-status='CANCELED'] > header {
    border-color: $passive;
  }

  //  highlight related items
  //
  &.--related {
    background-color: $white;
  }

  @include transition();
  // :hover   :active   :focus
  //
  &:hover,
  &:focus {
    background-color: $white;
    box-shadow: 0 8px 16px 0 rgba($black, 0.1);
  }
}

//
//  EXTENCION: highlight flag for related items
//
dc-partner--sidebar--contact-history--item {
  position: relative;
  /*

  FR > enable after approval


  &[ng-reflect-highligted="true"]::before {
    @include pseudo();
    top: $radius--md;
    right: calc( 100% + #{_space(.5)} );

    width: _space(.25);
    height: _space(2);

    background: $highlight;
    border-top-left-radius: $radius--sm;
    border-bottom-left-radius: $radius--sm;
  }
  */
}

.ui-sidebar-item__attachments {
  position: relative;

  a {
    &:hover {
      // change document icon from outlined version to fill version
      // .fa-file-o > .fa-file
      .fa::before {
        content: '\f15b';
      }
    }

    // file import failed
    &.--failure {
      color: $danger;
      cursor: default;
    }
  }

  //
  //  EXTENSION: show filename on :hover
  //
  [data-filename] {
    &::after {
      content: attr(data-filename);
      pointer-events: none;

      position: absolute;
      top: calc(100% - #{$radius}); // $radius = .ui-sidebar-item border-radius
      left: 0;
      right: 0;
      z-index: z-index(cooltip);

      @include _padding(0.5, 0.5, 0.25);

      font-family: $ff--arial;
      font-size: 12px !important;
      line-height: $lh--medium;
      text-transform: none !important;
      font-weight: normal !important;
      font-style: normal !important;
      overflow-wrap: break-word; // makes sure the long string will wrap

      color: $grey-5;
      background: $white;
      box-shadow: 0 8px 16px 0 rgba($black, 0.1);

      border-radius: 0 0 $radius $radius;

      @include transition();
      opacity: 0;
    }

    // show filename on :hover
    //
    &:hover::after {
      opacity: 1;
    }
  }
}
