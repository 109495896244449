//
//  PADDING
//
.padding {
  padding: _space(1) !important;
}

.p {
  &--0 {
    padding: 0 !important;
  }
  &--1 {
    padding: _space(1) !important;
  }
  &--2 {
    padding: _space(2) !important;
  }
  &--3 {
    padding: _space(3) !important;
  }
  &--4 {
    padding: _space(4) !important;
  }

  &--1-8 {
    padding: _space(0.125) !important;
  }
  &--1-4 {
    padding: _space(0.25) !important;
  }
  &--1-3 {
    padding: _space(0.33) !important;
  }
  &--1-2 {
    padding: _space(0.5) !important;
  }
  &--2-3 {
    padding: _space(0.66) !important;
  }
  &--3-4 {
    padding: _space(0.75) !important;
  }
}

.pt {
  padding-top: _space(1) !important;
  &--0 {
    padding-top: 0 !important;
  }
  &--1 {
    padding-top: _space(1) !important;
  }
  &--2 {
    padding-top: _space(2) !important;
  }
  &--3 {
    padding-top: _space(3) !important;
  }
  &--4 {
    padding-top: _space(4) !important;
  }

  &--1-8 {
    padding-top: _space(0.125) !important;
  }
  &--1-4 {
    padding-top: _space(0.25) !important;
  }
  &--1-3 {
    padding-top: _space(0.33) !important;
  }
  &--1-2 {
    padding-top: _space(0.5) !important;
  }
  &--2-3 {
    padding-top: _space(0.66) !important;
  }
  &--3-4 {
    padding-top: _space(0.75) !important;
  }

  // FR > extend also other classes for --1-8
  &--1-8 {
    padding-top: _space(0.125) !important;
  }
}

.pb {
  padding-bottom: _space(1) !important;
  &--0 {
    padding-bottom: 0 !important;
  }
  &--1 {
    padding-bottom: _space(1) !important;
  }
  &--2 {
    padding-bottom: _space(2) !important;
  }
  &--3 {
    padding-bottom: _space(3) !important;
  }
  &--4 {
    padding-bottom: _space(4) !important;
  }

  &--1-8 {
    padding-bottom: _space(0.125) !important;
  }
  &--1-4 {
    padding-bottom: _space(0.25) !important;
  }
  &--1-3 {
    padding-bottom: _space(0.33) !important;
  }
  &--1-2 {
    padding-bottom: _space(0.5) !important;
  }
  &--2-3 {
    padding-bottom: _space(0.66) !important;
  }
  &--3-4 {
    padding-bottom: _space(0.75) !important;
  }
}

.pl {
  padding-left: _space(1) !important;
  &--0 {
    padding-left: 0 !important;
  }
  &--1 {
    padding-left: _space(1) !important;
  }
  &--2 {
    padding-left: _space(2) !important;
  }
  &--3 {
    padding-left: _space(3) !important;
  }
  &--4 {
    padding-left: _space(4) !important;
  }

  &--1-8 {
    padding-left: _space(0.125) !important;
  }
  &--1-4 {
    padding-left: _space(0.25) !important;
  }
  &--1-3 {
    padding-left: _space(0.33) !important;
  }
  &--1-2 {
    padding-left: _space(0.5) !important;
  }
  &--2-3 {
    padding-left: _space(0.66) !important;
  }
  &--3-4 {
    padding-left: _space(0.75) !important;
  }
}

.pr {
  padding-right: _space(1) !important;
  &--0 {
    padding-right: 0 !important;
  }
  &--1 {
    padding-right: _space(1) !important;
  }
  &--2 {
    padding-right: _space(2) !important;
  }
  &--3 {
    padding-right: _space(3) !important;
  }
  &--4 {
    padding-right: _space(4) !important;
  }

  &--1-8 {
    padding-right: _space(0.125) !important;
  }
  &--1-4 {
    padding-right: _space(0.25) !important;
  }
  &--1-3 {
    padding-right: _space(0.33) !important;
  }
  &--1-2 {
    padding-right: _space(0.5) !important;
  }
  &--2-3 {
    padding-right: _space(0.66) !important;
  }
  &--3-4 {
    padding-right: _space(0.75) !important;
  }
}
