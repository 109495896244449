//
//  RE-write Sass Basis defaults
//
// FR > refuck
.c-container {
  max-width: 2000px;
  //margin-left: auto;
  //margin-right: auto;
  padding-left: $container--padding-left;
  padding-right: $container--padding-right;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

//
//  Containers
//
.container {
  //max-width: $breakpoint__xxl;
  margin-left: auto;
  margin-right: auto;
  padding-left: $container--padding-left;
  padding-right: $container--padding-right;
}

.container {
  &--xs {
    max-width: $breakpoint__xs;
  }
  &--sm {
    max-width: $breakpoint__sm;
  }
  &--md {
    max-width: $breakpoint__md;
  }
  &--lg {
    max-width: $breakpoint__lg;
  }
  &--xl {
    max-width: $breakpoint__xl;
  }
  &--half-screen {
    max-width: $breakpoint__half-screen;
  }
  &--indigo {
    max-width: $breakpoint__indigo;
  }
  &--xxl {
    max-width: $breakpoint__xxl;
  }
}
