.ui-tabs {
  display: flex;
}

//
// Item
//
.ui-tab {
  display: inline-block;
  @include _padding(0.5, 1, 0.25);

  border-bottom: 2px solid transparent;

  &.--underline {
    border-bottom-color: $grey-5;
  }

  &.--active {
    color: $grey-5;
    border-bottom-color: $grey-5;

    .badge {
      background: $grey-5;
      color: $white;
    }
  }
}
