//
//
//  INDEX:
//
//  * margin
//  * margin - negative values
//
//_____________________________

//
//  MARGIN
//
.margin {
  margin: _space(1) !important;
}

.push--auto {
  @include push--auto();
} // set left/right margin to 'auto'

.m {
  &--0 {
    margin: 0 !important;
  }
  &--1 {
    margin: _space(1) !important;
  }
  &--2 {
    margin: _space(2) !important;
  }
  &--3 {
    margin: _space(3) !important;
  }
  &--4 {
    margin: _space(4) !important;
  }

  &--1-8 {
    margin: _space(0.125) !important;
  }
  &--1-4 {
    margin: _space(0.25) !important;
  }
  &--1-3 {
    margin: _space(0.33) !important;
  }
  &--1-2 {
    margin: _space(0.5) !important;
  }
  &--2-3 {
    margin: _space(0.66) !important;
  }
  &--3-4 {
    margin: _space(0.75) !important;
  }

  &--auto {
    margin: auto !important;
  }
}

.mt {
  margin-top: _space(1) !important;
  &--0 {
    margin-top: 0 !important;
  }
  &--1 {
    margin-top: _space(1) !important;
  }
  &--2 {
    margin-top: _space(2) !important;
  }
  &--3 {
    margin-top: _space(3) !important;
  }
  &--4 {
    margin-top: _space(4) !important;
  }

  &--1-8 {
    margin-top: _space(0.125) !important;
  }
  &--1-4 {
    margin-top: _space(0.25) !important;
  }
  &--1-3 {
    margin-top: _space(0.33) !important;
  }
  &--1-2 {
    margin-top: _space(0.5) !important;
  }
  &--2-3 {
    margin-top: _space(0.66) !important;
  }
  &--3-4 {
    margin-top: _space(0.75) !important;
  }

  &--auto {
    margin-top: auto !important;
  }
}

.mb {
  margin-bottom: _space(1) !important;
  &--0 {
    margin-bottom: 0 !important;
  }
  &--1 {
    margin-bottom: _space(1) !important;
  }
  &--2 {
    margin-bottom: _space(2) !important;
  }
  &--3 {
    margin-bottom: _space(3) !important;
  }
  &--4 {
    margin-bottom: _space(4) !important;
  }

  &--1-8 {
    margin-bottom: _space(0.125) !important;
  }
  &--1-4 {
    margin-bottom: _space(0.25) !important;
  }
  &--1-3 {
    margin-bottom: _space(0.33) !important;
  }
  &--1-2 {
    margin-bottom: _space(0.5) !important;
  }
  &--2-3 {
    margin-bottom: _space(0.66) !important;
  }
  &--3-4 {
    margin-bottom: _space(0.75) !important;
  }

  &--auto {
    margin-bottom: auto !important;
  }
}

.ml {
  margin-left: _space(1) !important;
  &--0 {
    margin-left: 0 !important;
  }
  &--1 {
    margin-left: _space(1) !important;
  }
  &--2 {
    margin-left: _space(2) !important;
  }
  &--3 {
    margin-left: _space(3) !important;
  }
  &--4 {
    margin-left: _space(4) !important;
  }

  &--1-8 {
    margin-left: _space(0.125) !important;
  }
  &--1-4 {
    margin-left: _space(0.25) !important;
  }
  &--1-3 {
    margin-left: _space(0.33) !important;
  }
  &--1-2 {
    margin-left: _space(0.5) !important;
  }
  &--2-3 {
    margin-left: _space(0.66) !important;
  }
  &--3-4 {
    margin-left: _space(0.75) !important;
  }

  &--auto {
    margin-left: auto !important;
  }
}

.mr {
  margin-right: _space(1) !important;
  &--0 {
    margin-right: 0 !important;
  }
  &--1 {
    margin-right: _space(1) !important;
  }
  &--2 {
    margin-right: _space(2) !important;
  }
  &--3 {
    margin-right: _space(3) !important;
  }
  &--4 {
    margin-right: _space(4) !important;
  }

  &--1-8 {
    margin-right: _space(0.125) !important;
  }
  &--1-4 {
    margin-right: _space(0.25) !important;
  }
  &--1-3 {
    margin-right: _space(0.33) !important;
  }
  &--1-2 {
    margin-right: _space(0.5) !important;
  }
  &--2-3 {
    margin-right: _space(0.66) !important;
  }
  &--3-4 {
    margin-right: _space(0.75) !important;
  }

  &--auto {
    margin-right: auto !important;
  }
}

//
//  MARGIN — negative values
//
// FR > replace margin-top: -33% >>> margin-top: _space(.333);
.m-- {
  &-1 {
    margin: _space(-1) !important;
  }
  &-2 {
    margin: _space(-2) !important;
  }
  &-3 {
    margin: _space(-3) !important;
  }
  &-4 {
    margin: _space(-4) !important;
  }

  &-1-8 {
    margin: _space(-0.125) !important;
  }
  &-1-4 {
    margin: _space(-0.25) !important;
  }
  &-1-3 {
    margin: _space(-0.33) !important;
  }
  &-1-2 {
    margin: _space(-0.5) !important;
  }
  &-2-3 {
    margin: _space(-0.66) !important;
  }
  &-3-4 {
    margin: _space(-0.75) !important;
  }
}

.mt-- {
  &-1 {
    margin-top: _space(-1) !important;
  }
  &-2 {
    margin-top: _space(-2) !important;
  }
  &-3 {
    margin-top: _space(-3) !important;
  }
  &-4 {
    margin-top: _space(-4) !important;
  }

  &-1-8 {
    margin-top: _space(-0.125) !important;
  }
  &-1-4 {
    margin-top: _space(-0.25) !important;
  }
  &-1-3 {
    margin-top: _space(0.33) !important;
  }
  &-1-2 {
    margin-top: _space(-0.5) !important;
  }
  &-2-3 {
    margin-top: _space(-0.66) !important;
  }
  &-3-4 {
    margin-top: _space(-0.75) !important;
  }
}

.mb-- {
  &-1 {
    margin-bottom: _space(-1) !important;
  }
  &-2 {
    margin-bottom: _space(-2) !important;
  }
  &-3 {
    margin-bottom: _space(-3) !important;
  }
  &-4 {
    margin-bottom: _space(-4) !important;
  }

  &-1-8 {
    margin-bottom: _space(-0.125) !important;
  }
  &-1-4 {
    margin-bottom: _space(-0.25) !important;
  }
  &-1-3 {
    margin-bottom: _space(-0.33) !important;
  }
  &-1-2 {
    margin-bottom: _space(-0.5) !important;
  }
  &-2-3 {
    margin-bottom: _space(-0.66) !important;
  }
  &-3-4 {
    margin-bottom: _space(-0.75) !important;
  }
}

.ml-- {
  &-1 {
    margin-left: _space(-1) !important;
  }
  &-2 {
    margin-left: _space(-2) !important;
  }
  &-3 {
    margin-left: _space(-3) !important;
  }
  &-4 {
    margin-left: _space(-4) !important;
  }

  &-1-8 {
    margin-left: _space(-0.125) !important;
  }
  &-1-4 {
    margin-left: _space(-0.25) !important;
  }
  &-1-3 {
    margin-left: _space(-0.33) !important;
  }
  &-1-2 {
    margin-left: _space(-0.5) !important;
  }
  &-2-3 {
    margin-left: _space(-0.66) !important;
  }
  &-3-4 {
    margin-left: _space(-0.75) !important;
  }
}

.mr-- {
  &-1 {
    margin-right: _space(-1) !important;
  }
  &-2 {
    margin-right: _space(-2) !important;
  }
  &-3 {
    margin-right: _space(-3) !important;
  }
  &-4 {
    margin-right: _space(-4) !important;
  }

  &-1-8 {
    margin-right: _space(-0.125) !important;
  }
  &-1-4 {
    margin-right: _space(-0.25) !important;
  }
  &-1-3 {
    margin-right: _space(-0.33) !important;
  }
  &-1-2 {
    margin-right: _space(-0.5) !important;
  }
  &-2-3 {
    margin-right: _space(-0.66) !important;
  }
  &-3-4 {
    margin-right: _space(-0.75) !important;
  }
}
