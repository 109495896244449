.ui-calendar-datepicker.p-datepicker {
  @apply rounded-lg p-0;

  .p-yearpicker,
  .p-monthpicker {
    @apply max-w-lg;
  }

  // shortcuts
  &:has(p-footer) {
    @apply grid grid-cols-[minmax(10rem,_auto)_1fr];

    .p-timepicker {
      @apply border-l border-t-0 border-l-gray-100;

      span {
        @apply text-xl;

        &:first-child,
        &:last-child {
          @apply text-base;
        }
      }
    }

    .time-toggle {
      @apply border-t border-t-gray-100;
    }

    .preset-footer {
      @apply bg-gray-50;
    }
  }

  // month and date picker
  &:has(.p-yearpicker),
  &:has(.p-monthpicker) {
    @apply flex flex-col;
  }

  // time picker
  &:has(.p-timepicker) {
    @apply grid grid-cols-[minmax(10rem,_auto)_1fr_10rem];

    .time-footer {
      @apply border-l border-l-gray-100;
    }
  }
  &:has(.p-timepicker):not(:has(p-footer)) {
    @apply grid-cols-[minmax(10rem,_auto)_10rem];

    .time-toggle {
      @apply border-t border-t-gray-100;
    }
    .p-timepicker {
      @apply border-l border-t-0 border-l-gray-100;
    }
  }
  &:has(.p-yearpicker):has(.time-toggle) .time-toggle,
  &:has(.p-monthpicker):has(.time-toggle) .time-toggle {
    @apply hidden;
  }

  .p-datepicker-header {
    @apply rounded-t-lg border-b-0;

    .p-datepicker-title {
      .p-datepicker-month,
      .p-datepicker-year {
        @apply bg-transparent text-lg text-gray-700;
      }

      .p-datepicker-year {
        @apply text-primary-300;
      }
    }

    .p-datepicker-prev,
    .p-datepicker-next {
      @apply enabled:hover:bg-gray-100;
    }
  }

  .p-datepicker-calendar-container {
    @apply flex;
  }

  table.p-datepicker-calendar {
    @apply order-last m-4 w-full;

    tr th > span {
      @apply text-base font-bold text-gray-500;
    }

    td {
      @apply p-[1px];
      span {
        @apply h-[2.625rem] w-[2.625rem];
      }
    }

    td > span.p-highlight,
    td.p-datepicker-today > span.p-highlight {
      @apply bg-primary-50 font-medium text-primary-700;
    }

    td.p-datepicker-today > span {
      @apply relative bg-transparent font-medium after:absolute after:bottom-[3px] after:h-[4px] after:w-[10px] after:rounded-full after:bg-gray-300 after:content-[''];

      &.p-highlight {
        @apply after:bg-primary-200;
      }
    }

    td span:not(.p-highlight):not(.p-disabled):hover {
      @apply hover:border-gray-300 hover:bg-transparent;
    }
  }

  .p-timepicker {
    button {
      @apply bg-gray-50 enabled:hover:bg-gray-100;
    }
  }

  p-footer {
    @apply order-first flex flex-col gap-2 rounded-l-lg bg-gray-50 p-4;

    .p-datepicker-buttonbar,
    .p-datepicker-buttonbar > div {
      @apply flex w-full flex-col items-start gap-2 border-t-0;
    }

    p-button,
    .p-button {
      @apply w-full whitespace-nowrap;
      span {
        @apply text-left;
      }
    }
  }

  .p-monthpicker,
  .p-yearpicker {
    @apply col-span-full;
  }

  .p-monthpicker ~ p-footer,
  .p-yearpicker ~ p-footer {
    @apply hidden;
  }
}
