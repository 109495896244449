/*
---
name: _hidden()
category:
  - core/mixin
  - core/mixin/visibility
---
Hidden helper

* This helper set "position" property.
* We recomment to use this helper on elements with no position specified.

### scss
```scss
.c-foo {
  @include _hidden();

  @include _media-min(md) {
      @include _visible();
  }
}
```
*/

@mixin _hidden() {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  visibility: hidden !important;
  overflow: hidden;
}

/*
---
name: _visible()
category:
  - core/mixin
  - core/mixin/visibility
---
Visible helper

* This helper set "position" property.
*  We recomment to use this helper on elements with no position specified.

### scss
```scss
.c-foo {
  @include _hidden();

  @include _media-min(md) {
      @include _visible();
  }
}
```
*/

@mixin _visible() {
  position: static !important;
  clip: auto !important;
  visibility: visible !important;
  overflow: visible;
}

/*
---
name: _invisible()
category:
  - core/mixin
  - core/mixin/visibility
---
Invisible helper

### scss
```scss
.c-foo {
  @include _invisible();
}
```
*/

@mixin _invisible() {
  display: none !important;
}
