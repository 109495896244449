.dc-process {
  max-width: 700px;
  margin-top: _space(1);
  @include _padding(1, 0);

  background: $white;
  border-radius: $radius--lg;
  box-shadow:
    0 0px 28px rgba($black, 0.0625),
    0 0px 10px rgba($black, 0.0625);
  overflow: hidden;
}

//
//  Item
//
.dc-process {
  padding-left: $dc-process__step--padding;
  padding-right: $dc-process__step--padding;

  &__step {
    position: relative;
    display: grid;
    grid-template-columns: $dc-process__date--width $dc-process__ball--width 1fr;

    margin-bottom: _space(1);

    &::after {
      content: '';
      display: block;

      position: absolute;
      top: 0;
      left: calc(#{$dc-process__date--width} + #{$dc-process__ball--width-half});
      z-index: 1;
      width: $dc-process__line--width;
      height: calc(100% + #{_space(1)});
      background: $grey-1;
    }
  }

  &__body {
    .dc-process__list {
      @apply list-inside;
      @apply list-disc;
      .dc-process__list {
        @apply list-[circle];
        @apply ml-4;
      }
    }
  }

  &__date {
    padding-top: _space(0.5);
    text-align: center;
  }

  &__ball {
    position: relative;
    z-index: 2;
    width: $dc-process__ball--width;
    height: $dc-process__ball--width;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-size: _space(1);

    background: $grey-1;
    border: 2px solid $white;
    color: $active;

    border-radius: 50%;
    overflow: hidden;
  }

  &__content {
    padding-top: _space(0.5);
    padding-left: _space(1);
  }

  &__title {
    font-size: 14px;
    font-weight: bold;
    line-height: $lh--medium;
    color: $text;
  }

  &__meta {
    //display: inline-block;
  }

  &__attachements {
    margin-top: _space(0.25);

    // each link on new line
    .ui-link {
      float: left;
      clear: left;
    }
  }
}

//
//  Variants
//
.dc-process__step {
  &.--FAILURE {
    background: $white;
    box-shadow: 0 0 60px 3px $danger;

    &::after {
      display: none;
    }

    .dc-process__ball {
      background: $danger;
      border-color: $danger;
      color: $white;
    }
  }

  &.--DISABLED {
    //...
  }

  &.--PROGRESS {
    //&::after { background: rgba($progress, .5); }

    .dc-process__ball {
      color: $white;
      background: $progress;
      border-color: $progress;
    }
  }

  &.--SUCCESS {
    //&::after { background: rgba($active, .5); }

    .dc-process__ball {
      color: $white;
      background: $active;
      border-color: $active;
    }
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}
