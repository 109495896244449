//
//  File list
//
//  FR > merge with version from TS
//
//  used in template:;
// /src/client/app/+daktela-archive/detail/detail.component.html:
//
.dc-list-documents {
  list-style-type: none;
  margin-left: 0;

  li {
    position: relative;
    @include _padding-left(0.85);
    margin-bottom: _space(0.25);

    &:hover {
      .dc-list-documents__file-name {
        border-bottom: 1px dotted $antracit;
      }
    }
  }

  &__bullet {
    position: absolute;
    top: 2px;
    left: 0;
    opacity: 0.7;
  }

  &__file-name {
    margin-right: _space(1);
    cursor: default;
  }

  .dc-btn {
    margin-right: _space(0.25);
  }
}

// todo: use webfont icon (now is inlined base64 SVG)
.dc-file-attachements {
  .missing-thumb--pdf {
    width: 80px;
    @include _margin-top(3);
    opacity: 0.5;
  }
}
