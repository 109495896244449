/*
---
name: _overlay()
category:
  - core/mixin
---
Overlay helper

### scss
```scss
//
// @param  hex  $hex
// @param  int  $opacity
//

.c-foo {
  @include _overlay(#fff, .5);
}
```
*/

@mixin _overlay($hex, $opacity: 1) {
  position: relative;

  &::before {
    @include _position(absolute, 0, 0, 0, 0);
    content: '';

    @if (1 == $opacity) {
      background-color: $hex;
    } @else {
      background-color: rgba($hex, $opacity);
    }
  }
}
