.dc-contact-details {
  &__list {
    margin-bottom: _space(1);
  }

  &__contact:not(:last-child) {
    border-top: 20px solid $concrete;
  }

  &__contract-type {
    .dc-icon {
      margin-right: 7px;
    }

    a {
      margin-left: 20px;
    }
  }

  &__legend {
    text-align: right;

    &-item {
      display: inline-block;
      padding: 3px 6px 2px;
      margin-left: _space(0.25);

      font-size: 10px;
      line-height: 1;
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: rgba($white, 0.9);

      border-radius: $radius--sm;

      &--active {
        background: $active;
      }
      &--pasive {
        background: $passive;
      }
      &--progress {
        background: $progress;
      }
    }
  }

  .dc-table .icon--koka {
    top: 2px;
    margin-right: 3px;
  }
}

// Ukončená smlouva
.dc-contact-details__item-status--TERMINATED,
.dc-contact-details__item-status--NONEXISTENT,
.dc-contact-details__item-status--CANCELED {
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}
