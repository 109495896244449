/*
---
name: _checkbox()
category:
  - core/abstract
  - core/abstract/form
---
Abstract checkbox component

### scss
```scss
.c-checkbox {
  @include _checkbox();
}
```

### html
```ejs
<label>
  <span class="c-checkbox">
    <input type="checkbox" class="c-checkbox__control">
    <span class="c-checkbox__label">...</span>
  </span>
</label>
```
*/

@mixin _checkbox() {
  display: inline-flex;
  align-items: center;

  &__control {
    @supports (appearance: none) or (-webkit-appearance: none) or (-moz-appearance: none) {
      appearance: none;
      position: relative;
      display: inline-block;
      cursor: pointer;
      @include _form-control-base-border();
      background-color: _var-color-white();
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .035);
      @include _square(16px);

      &::before {
        top: calc(50% - 5px);
        left: calc(50% - 3px);
        transform: rotate(45deg);
        margin: 0 !important;
        height: 8px;
        width: 6px;
        border-style: solid;
        border-color: _var-color-white();
        border-width: 0 2px 2px 0;
        visibility: hidden;
      }

      &:checked {
        background-color: #3a87fd;
        border-color: #3a87fd;

        &::before {
          position: absolute;
          content: '';
          display: block;
          visibility: visible;
        }
      }

      &:disabled {
        background-color: _var-lightest-color-gray();

        &::before {
          border-color: _var-dark-color-gray();
        }
      }
    }
  }
}
