.dummy-box {
  width: _space(7);
  padding: _space(1);

  text-align: center;
  color: $grey-5;

  background: $grey-1;
  border: 1px solid $grey-2;

  //  variants
  //
  &--1 {
    height: 7vh;
    margin-top: _space(0.5);
    padding: _space(1);

    text-align: center;
    background: $grey-1;
    border: 1px solid $grey-2;
    border-radius: $radius;
  }

  &--2 {
    padding: _space(1);
    text-align: center;
    background: $grey-1;
    border-radius: $radius;
  }

  &--image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vh;

    font-family: $ff--yummo;
    font-size: _space(1.5);
    color: $grey-2;

    background: $grey-1;
    border: 1px solid $grey-2;
    border-radius: $radius--sm;
  }

  &--grid-item,
  &--tile {
    padding: _space(1);

    line-height: 1;
    text-align: center;

    background: $grey-2;
    border: 1px solid $grey-3;
    border-radius: $radius--sm;
  }

  //  Tile
  //
  &--tile {
    //  dimensions / position / alignment
    //
    //...
    display: flex;
    justify-content: center;
    align-items: center;

    //  text
    //
    //...

    //  appearence
    //
    //...

    // behaviour
    //
    //@include transition();
    //&:hover,
    //&:focus {
    //  transform: scale(1.05);
    //}
  }

  &--border {
    &:not(:last-child) {
      margin-bottom: _space(1);
    }
    padding: _space(0.5);

    text-align: center;
    background: $grey-1;
  }
}

.dummy--col {
  padding-top: _space(0.25);
  padding-bottom: _space(0.25);

  text-align: center;

  background: $grey-2;
}
