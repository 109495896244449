//
//
//  INDEX:
//
//  * justify-content
//  * justify-self
//  * align-items
//  * align-content
//
//  * flex-direction
//  * flex-wrap
//  x flex-flow
//_____________________________

//  DEV NOTES:
//
//  * activate flexbox by adding class: d--flex

//
//  JUSTIFY CONTENT
//
// https://css-tricks.com/almanac/properties/j/justify-content/
.jc {
  &--start {
    justify-content: flex-start !important;
  }
  &--end {
    justify-content: flex-end !important;
  }
  &--center {
    justify-content: center !important;
  }
  &--space-between {
    justify-content: space-between !important;
  }
  &--space-around {
    justify-content: space-around !important;
  }
  &--space-evenly {
    justify-content: space-evenly !important;
  }
}

//
//  JUSTIFY SELF
//
// https://css-tricks.com/almanac/properties/j/justify-self/
.js {
  &--center {
    justify-self: center !important;
  }
}

//
//  ALIGN ITEMS
//
// https://css-tricks.com/almanac/properties/a/align-items/
.ai {
  &--flex-start {
    align-items: flex-start !important;
  }
  &--end {
    align-items: flex-end !important;
  }
  &--center {
    align-items: center !important;
  }
  &--baseline {
    align-items: baseline !important;
  }
  &--stretch {
    align-items: stretch !important;
  }
}

//
//  ALIGN CONTENT
//
// https://css-tricks.com/almanac/properties/a/align-content/
.ac {
  &--start {
    align-content: flex-start !important;
  }
  &--end {
    align-content: flex-end !important;
  }
  &--center {
    align-content: center !important;
  }
  &--space-between {
    align-content: space-between !important;
  }
  &--space-around {
    align-content: space-around !important;
  }
  &--stretch {
    align-content: stretch !important;
  }
}

//
//  DIRECTION
//
// https://css-tricks.com/almanac/properties/f/flex-direction/
.fd {
  &--row {
    flex-direction: row !important;
  }
  &--row-reverse {
    flex-direction: row-reverse !important;
  }
  &--column {
    flex-direction: column !important;
  }
  &--column-reverse {
    flex-direction: column-reverse !important;
  }
}

//
//  ALIGN SELF
//
.as {
  &--center {
    align-self: center !important;
  }
}

//
//  WRAP
//
// https://css-tricks.com/almanac/properties/f/flex-wrap/
.fw {
  &--nowrap {
    flex-wrap: nowrap !important;
  }
  &--wrap {
    flex-wrap: wrap !important;
  }
  &--wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}

//
//  FLOW
//
// It is a shorthand for flex-direction and flex-wrap
// https://css-tricks.com/almanac/properties/f/flex-flow/
//
//.ff {}

//
//  MISC
//
.flex--grow {
  flex-grow: 1;
}
.flex--shrink {
  flex-shrink: 1;
}

//
// flex-basis
//

.flex-basis {
  display: flex;
  @for $part from 0 through 20 {
    &--#{$part*5} {
      flex-basis: #{$part * 5%};
    }
  }
}

// special .flex-basis

.flex-basis {
  &--18 {
    flex-basis: 18%;
  }
  &--82 {
    flex-basis: 82%;
  }
}
