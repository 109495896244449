/*

  usage
  -----

  .element {
    z-index: z-index(modal);
  }

*/

@function z-index($key) {
  @return map-get($zindex, $key);
}
