//
// Základní barevná paleta
//
$black: #000;
$mirage: #18232f;
$white: #fff;
$silver: #bcbcbc;
$antracit: #5d646c; // dark grey mixed with $core blue
$thunder: #231f20;
$cornflower-blue: #0085ff;
$dodger-blue: #1b8ff9;
$rio-grande: #becd00;
$atlantis: #b2d236;

//  Sdílené
$core: $mirage;
$brand: $rio-grande;
$brand-alt: $atlantis;

//
// Core UI — system colors
// Jména barev podle https://chir.ag/projects/name-that-color
//
$core--red: #d40705; // indianred
$core--orange: orangered;
$core--yellow: #f6b840; // gold
$core--green: #24b595;
$core--blue: #247bb5;
$core--indigo: indigo;
$core--purple: purple;

$text: $core;
$text--button: $thunder;
$text--disabled: $silver;
$text--link: $cornflower-blue;

$pill: $dodger-blue;

$action: #477dca;
$highlight: #ffff00;
$focus: #009dbd; //  input border color on :focus

$active: #7eba35;
$passive: #92867d;
$progress: #ff8533;
$neutral: #535f6d;

$info: #003366;
$warning: #e28701;
$danger: #da2f37;
$success: #8ab92d;

// FR > rename $core-1 >> $core-1   ...   .bg--core-1 >> bg--core-1
$core-1: lighten($core, 30%);
$core-2: lighten($core, 20%);
$core-3: lighten($core, 10%);
$core-4: darken($core, 10%);
$core-5: darken($core, 20%); // > too dark => do not use!
$core-6: darken($core, 30%); // > too dark => do not use!

//
//  Grey
//

// lightest grey
//
$milk: lighten($black, 98%); // #fafafa
$concrete: lighten($black, 95%); // #f2f2f2

$grey-10: lighten($black, 90%); // #e6e6e6
$grey-20: lighten($black, 80%); // #cccccc
$grey-30: lighten($black, 70%); // #b3b3b3
$grey-40: lighten($black, 60%); // #999999
$grey-50: lighten($black, 50%); // #808080
$grey-60: lighten($black, 40%); // #666666
$grey-70: lighten($black, 30%); // #4d4d4d
$grey-80: lighten($black, 20%); // #333333
$grey-90: lighten($black, 10%); // #1a1a1a

//
// Doplňková barevná paleta
//
// same colors as $grey-N  in   theme--pojistovna.sass
$grey-1: #f0f0eb;
$grey-2: #d7d7c8;
$grey-3: #b9b9aa;
$grey-4: #9b9b8c;
$grey-5: #7d7b6e;
$grey-6: darken($grey-5, 10%);
$grey-7: darken($grey-5, 15%);
/* these are shades used in current Core
$grey-1:   #fafafa;     // > replace   $milk   by:  $grey-1
$grey-2:   $temp__sedivka-5;     // > in other SASS files: replace "$temp__sedivka-5" by:  $grey-2
$grey-3:   #ccc;        // > in other SASS files: replace "#cccccc" by:  $grey-3
$grey-4:   #999;
$grey-5:   #666;
*/

////////////////////////////////////
//
//  FR > below - refuck
//
////////////////////////////////////

$temp__sedivka-1: #474747;
$temp__sedivka-2: #d9d9d9;
$temp__sedivka-3: #2e2e2d;
$temp__sedivka-4: #717171;
$temp__sedivka-5: #ececec;
$temp__sedivka-6: #363636;
$temp__sedivka-7: #eee;
$temp__sedivka-8: #ddd;
$temp__sedivka-9: #fcfcfc;
$temp__sedivka-10: #282828;
$temp__sedivka-11: #999;

$temp__nejaka-modra: #85b7d9;
$temp__nejaka-modra-2: #1565c0;

// FR > unite purple colors
$temp__PURPLE-1: #49154c;
$temp__PURPLE-2: #5a2382;
$temp__PURPLE--light: #a554a5;
$temp__PURPLE: purple;

$temp__core-A-1: #324962;
