.gradient {
  background-image: linear-gradient(to right, $black, $white);
  &--1 {
    background-image: linear-gradient(to right, blue, red);
  }
  &--2 {
    background-image: linear-gradient(to right, $warning, $danger);
  }

  &--info {
    background-image: linear-gradient(to right, $info, rgba($info, 0.5));
  }
  &--warning {
    background-image: linear-gradient(to right, $warning, rgba($warning, 0.5));
  }
  &--danger {
    background-image: linear-gradient(to right, $danger, rgba($danger, 0.5));
  }
  &--success {
    background-image: linear-gradient(to right, $success, rgba($success, 0.5));
  }
}
