//
//  based on:  Balloon.css
//             https://kazzkiq.github.io/balloon.css/

button[data-tooltip] {
  overflow: visible;
}

[data-tooltip] {
  position: relative;
}

//
//  Text container
//
[data-tooltip]:after {
  content: attr(data-tooltip);

  position: absolute;
  z-index: z-index(heaven);

  padding: 5px 7px !important;

  pointer-events: none;

  font-family: $ff--arial;
  font-size: 12px !important;
  text-transform: none !important;
  font-weight: normal !important;
  font-style: normal !important;
  line-height: 1 !important;

  background: $black;
  border-radius: $radius--sm;

  white-space: nowrap;
  color: $white;

  opacity: 0;
  transition: all $speed--normal ease-out $speed--normal;
}

//
//  Bubble pointer
//
[data-tooltip]:before {
  @include pseudo();

  z-index: z-index(cooltip);

  pointer-events: none;

  background: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E')
    no-repeat;
  background-size: 100% auto;
  width: 18px;
  height: 6px;

  opacity: 0;
  transition: all $speed--normal ease-out $speed--normal;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip][data-tooltip-visible]:before,
[data-tooltip][data-tooltip-visible]:after {
  pointer-events: auto;
  opacity: 1;
}

//
//  ???
//
[data-tooltip][data-tooltip-break]:after {
  white-space: pre;
}
[data-tooltip][data-tooltip-blunt]:before,
[data-tooltip][data-tooltip-blunt]:after {
  transition: none;
}

//
//  POSITIONS ------------------------------------------
//

//
//  position -- up
//
[data-tooltip][data-tooltip-position='up']:after {
  bottom: 100%;
  left: 50%;
  margin-bottom: 11px;
  transform: translate(-50%, 10px);
  transform-origin: top;
}
[data-tooltip][data-tooltip-position='up']:before {
  bottom: 100%;
  left: 50%;
  margin-bottom: 5px;
  transform: translate(-50%, 10px);
  transform-origin: top;
}
[data-tooltip][data-tooltip-position='up']:hover:after,
[data-tooltip][data-tooltip-position='up'][data-tooltip-visible]:after {
  transform: translate(-50%, 0);
}

[data-tooltip][data-tooltip-position='up']:hover:before,
[data-tooltip][data-tooltip-position='up'][data-tooltip-visible]:before {
  transform: translate(-50%, 0);
}

//
//  position -- up left
//
[data-tooltip][data-tooltip-position='up-left']:after {
  bottom: 100%;
  left: 0;
  margin-bottom: 11px;
  transform: translate(0, 10px);
  transform-origin: top;
}
[data-tooltip][data-tooltip-position='up-left']:before {
  bottom: 100%;
  left: 5px;
  margin-bottom: 5px;
  transform: translate(0, 10px);
  transform-origin: top;
}

[data-tooltip][data-tooltip-position='up-left']:hover:after,
[data-tooltip][data-tooltip-position='up-left'][data-tooltip-visible]:after {
  transform: translate(0, 0);
}

[data-tooltip][data-tooltip-position='up-left']:hover:before,
[data-tooltip][data-tooltip-position='up-left'][data-tooltip-visible]:before {
  transform: translate(0, 0);
}

//
//  position -- up right
//
[data-tooltip][data-tooltip-position='up-right']:after {
  bottom: 100%;
  right: 0;
  margin-bottom: 11px;
  transform: translate(0, 10px);
  transform-origin: top;
}
[data-tooltip][data-tooltip-position='up-right']:before {
  bottom: 100%;
  right: 5px;
  margin-bottom: 5px;
  transform: translate(0, 10px);
  transform-origin: top;
}

[data-tooltip][data-tooltip-position='up-right']:hover:after,
[data-tooltip][data-tooltip-position='up-right'][data-tooltip-visible]:after {
  transform: translate(0, 0);
}

[data-tooltip][data-tooltip-position='up-right']:hover:before,
[data-tooltip][data-tooltip-position='up-right'][data-tooltip-visible]:before {
  transform: translate(0, 0);
}

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//
//  position -- down
//
[data-tooltip][data-tooltip-position='down']:after {
  left: 50%;
  margin-top: 11px;
  top: 100%;
  transform: translate(-50%, -10px);
}
[data-tooltip][data-tooltip-position='down']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  left: 50%;
  margin-top: 5px;
  top: 100%;
  transform: translate(-50%, -10px);
}
[data-tooltip][data-tooltip-position='down']:hover:after,
[data-tooltip][data-tooltip-position='down'][data-tooltip-visible]:after {
  transform: translate(-50%, 0);
}
[data-tooltip][data-tooltip-position='down']:hover:before,
[data-tooltip][data-tooltip-position='down'][data-tooltip-visible]:before {
  transform: translate(-50%, 0);
}

//
//  position -- down left
//
[data-tooltip][data-tooltip-position='down-left']:after {
  left: 0;
  margin-top: 11px;
  top: 100%;
  transform: translate(0, -10px);
}
[data-tooltip][data-tooltip-position='down-left']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  left: 5px;
  margin-top: 5px;
  top: 100%;
  transform: translate(0, -10px);
}
[data-tooltip][data-tooltip-position='down-left']:hover:after,
[data-tooltip][data-tooltip-position='down-left'][data-tooltip-visible]:after {
  transform: translate(0, 0);
}
[data-tooltip][data-tooltip-position='down-left']:hover:before,
[data-tooltip][data-tooltip-position='down-left'][data-tooltip-visible]:before {
  transform: translate(0, 0);
}
[data-tooltip][data-tooltip-position='down-right']:after {
  right: 0;
  margin-top: 11px;
  top: 100%;
  transform: translate(0, -10px);
}

//
//  position -- down right
//
[data-tooltip][data-tooltip-position='down-right']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  right: 5px;
  margin-top: 5px;
  top: 100%;
  transform: translate(0, -10px);
}
[data-tooltip][data-tooltip-position='down-right']:hover:after,
[data-tooltip][data-tooltip-position='down-right'][data-tooltip-visible]:after {
  transform: translate(0, 0);
}
[data-tooltip][data-tooltip-position='down-right']:hover:before,
[data-tooltip][data-tooltip-position='down-right'][data-tooltip-visible]:before {
  transform: translate(0, 0);
}

[data-tooltip][data-tooltip-position='left']:after {
  margin-right: 11px;
  right: 100%;
  top: 50%;
  transform: translate(10px, -50%);
}

//
//
//
//
//
//
//                      >>>> ADDITIONAL OPTIONS <<<<
//
//
//
//
//
//

[data-tooltip][data-tooltip-position='left']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(-90 18 18)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 6px;
  height: 18px;
  margin-right: 5px;
  right: 100%;
  top: 50%;
  transform: translate(10px, -50%);
}

[data-tooltip][data-tooltip-position='left']:hover:after,
[data-tooltip][data-tooltip-position='left'][data-tooltip-visible]:after {
  transform: translate(0, -50%);
}
[data-tooltip][data-tooltip-position='left']:hover:before,
[data-tooltip][data-tooltip-position='left'][data-tooltip-visible]:before {
  transform: translate(0, -50%);
}

[data-tooltip][data-tooltip-position='right']:after {
  left: 100%;
  margin-left: 11px;
  top: 50%;
  transform: translate(-10px, -50%);
}

[data-tooltip][data-tooltip-position='right']:before {
  background: no-repeat
    url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(90 6 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 6px;
  height: 18px;
  left: 100%;
  margin-left: 5px;
  top: 50%;
  transform: translate(-10px, -50%);
}
[data-tooltip][data-tooltip-position='right']:hover:after,
[data-tooltip][data-tooltip-position='right'][data-tooltip-visible]:after {
  transform: translate(0, -50%);
}
[data-tooltip][data-tooltip-position='right']:hover:before,
[data-tooltip][data-tooltip-position='right'][data-tooltip-visible]:before {
  transform: translate(0, -50%);
}
[data-tooltip][data-tooltip-length='small']:after {
  white-space: normal;
  width: 80px;
}
[data-tooltip][data-tooltip-length='medium']:after {
  white-space: normal;
  width: 150px;
}
[data-tooltip][data-tooltip-length='large']:after {
  white-space: normal;
  width: 260px;
}
[data-tooltip][data-tooltip-length='xlarge']:after {
  white-space: normal;
  width: 380px;
}
@media screen and (max-width: 768px) {
  [data-tooltip][data-tooltip-length='xlarge']:after {
    white-space: normal;
    width: 90vw;
  }
}
[data-tooltip][data-tooltip-length='fit']:after {
  white-space: normal;
  width: 100%;
}
