//
//  body
//
$body--background-color: $milk;

//
//  Header
//
$header--height: 70px;

$header--background-color: $core;
$header--text-color: $white;

//
//  Footer
//
$footer--height: 182px;

$footer--background-color: $core;
$footer--text-color: $concrete;

//
//  Misc
//
$breadcrumbs--height: 43px;
$toolbar--height: 76px;
$modal--max-width: 940px;
