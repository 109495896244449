.ui-link {
  display: inline-block;
  color: $action !important;
  cursor: pointer;
  text-decoration-skip-ink: auto;

  &:hover {
    text-decoration: underline;
  }

  &.dotted {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: $action;

    &:hover {
      text-decoration: none;
      color: $action;
    }
  }
}
