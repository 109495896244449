/*
---
name: _rem2px()
category:
  - core/function
---
Return px from rem

### scss
```scss
//
// @param   rem  $rem
// @param   px   $base-font-size-px
// @return  px
//

font-size: _rem2px(2rem);
```
*/

@function _rem2px($rem, $base-font-size-px: $_base-font-size-px) {
  @return _strip-unit($rem) * $base-font-size-px;
}
