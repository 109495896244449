/*


template
--------

  <ui-box-legacy variant="info | warning | danger | failure | success | muted">
      <i class="fa fa-fw fa-exclamation-triangle"></i>
      Požadavek se nezdařilo odeslat ke zpracování. Opakujte akci později.
  </ui-box-legacy>


*/

ui-box-legacy {
  position: relative;
  display: block;
  @include _margin(1, 0);
  @include _padding(0.75, 1, 0.75, 2);
  border-radius: $radius--md;

  &,
  a {
    color: $white;
  }

  a {
    text-decoration: underline;
  }

  .fa {
    position: absolute;
    top: 18px;
    left: 12px;
    font-size: 1.5rem;
  }

  &.--info {
    background-color: $info;
  }
  &.--warning {
    background-color: $warning;
  }
  &.--danger,
  &.--failure {
    background-color: $danger;
  }
  &.--success {
    background-color: $success;
  }
  &.--default {
    background-color: $grey-10;
    color: $grey-80;
  }

  &.--working-on {
    display: flex;
    justify-content: flex-start;
    max-width: 600px;
    @include push--auto();

    color: $text;
    background: $grey-2;
  }
}
