.tag {
  display: inline-block;
  @include _margin(0, 0.25, 0.25, 0);
  @include _padding(0.125, 0.25);

  line-height: $lh--medium;
  background: $grey-2;
  color: $grey-5;
  border-radius: $radius--md;

  // remove button
  //
  &__remove {
    pointer-events: auto;
    position: relative;

    margin-left: _space(0.33);
    padding-left: _space(0.5);
    padding-right: _space(0.125);

    font-style: normal;
    border-left: 1px dotted rgba($black, 0.3);
  }

  &.--removable {
    position: relative;
    transition: all 100ms ease-in-out;

    pointer-events: none;

    &:hover {
      cursor: pointer;
      background: $danger;
      color: $white;

      .tag__remove {
        border-color: transparent;
      }
    }
  }
}

//
//  Tags
//
// FR > MERGE with .tag
.dc-tag {
  display: inline-block;
  @include _margin(0, 0.25, 0.25, 0);
  @include _padding(0.125, 0.25);

  background: $grey-2;
  border-radius: $radius--sm;

  &__icon {
    margin-right: _space(0.125);
    color: $grey-5;
  }
  &__text {
  }
}
