.dc-table {
  width: 100%;
  overflow: hidden;

  border-collapse: collapse;
  border-radius: $radius--md;

  th,
  td {
    padding: _space(0.5);
    text-align: left;
  }

  th {
    padding-top: _space(0.25);
    padding-bottom: _space(0.25);
    vertical-align: bottom;

    font-size: 12px;
    font-weight: normal;
    line-height: $lh--small;
    text-transform: uppercase;
    color: $antracit;

    background: $grey-10;
    border-bottom: 2px solid $grey-4;
  }

  td {
    vertical-align: top;
    border-bottom: 1px solid $grey-1;
    background: $white;
  }

  // row
  //
  tr {
    &:last-child {
      td {
        border-bottom: 0;
      }
    }

    &:hover {
      td {
        background: $grey-1;
      }
    }
  }
}

//
//  E X T E N S I O N S
//
.dc-table {
  .cell--checkbox {
    //...
  }

  .dc-fake-checkbox {
    width: 16px;
    padding-right: 0;
  }

  tr:hover {
    .dc-fake-checkbox::before {
      background: $white;
      box-shadow: 0 0 0px 1px $white;
    }
  }
}

//
//  wide table
//
/*

template
-----------

  <div class="dc-table__wrap--scrollable">
      <table class="dc-table">
          ...
      </table>
  </div>

*/

.dc-table__wrap--scrollable {
  width: 100%;
  overflow-x: auto;

  border-radius: $radius--md;
  box-shadow: 0 0 0 1px $grey-2;

  .dc-table {
    border: 0;
    border-radius: 0;
  }
}

//
//  datatable css
//

.ngx-datatable {
  //width: 100% !important;
  overflow: hidden;

  border-collapse: collapse;
  border-radius: $radius--md;
  border: 1px solid $grey-2;

  input[type='checkbox'],
  input[type='radio'] {
    position: relative;
    opacity: 1;
    vertical-align: baseline;
  }
}

.datatable-header {
  height: 100% !important;
  //padding-top: _space(0.25);
  //padding-bottom: _space(0.25);
  padding: _space(0.25);
  vertical-align: bottom;

  font-size: 12px;
  font-weight: normal;
  line-height: $lh--small;
  text-transform: uppercase;
  color: $antracit;

  background: $grey-10;
  border-bottom: 2px solid $grey-4;
}

.datatable-body-row {
  //padding-top: _space(0.5);
  //padding-bottom: _space(0.5);
  padding: _space(0.5);
  text-align: left;
  vertical-align: top;
  border-bottom: 1px solid $grey-1;
  background: $white;

  &--inactive {
    color: $grey-4;
  }

  //&:last-child {
  //  td {
  //    border-bottom: 0;
  //  }
  //}

  &:hover {
    td {
      background: $grey-1;
    }
  }
}

.datatable-footer-inner {
  height: 100% !important;
  //padding-top: _space(0.25);
  //padding-bottom: _space(0.25);
  padding: _space(0.25) _space(0.5);
  //padding: _space(0.5);
  vertical-align: top;
  border-bottom: 1px solid $grey-1;
  color: $passive;

  ul.pager {
    li {
      width: 23px;
      height: 23px;
      text-align: center;
    }

    li.active {
      color: $action;
      border: 1px solid $action;
      border-radius: 50%;
    }

    a {
      color: $passive;
      &:hover {
        text-decoration: none;
        color: $action;
      }
    }
  }
}

// Prime table
.table-checkbox-column {
  width: 3rem;
}

// Prime table
.table-checkbox-column {
  width: 3rem;
}
