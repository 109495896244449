/*
---
name: .u-hidden
category:
  - object/utility
  - object/utility/hidden
  - object/utility/hidden/hidden
---
Hidden utility

### html
```html
<div class="u-hidden">
  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
</div>
```
```html
<div class="u-hidden-sm">
  u-hidden-sm
</div>
```
```html
<div class="u-hidden-md">
  u-hidden-md
</div>
```
```html
<div class="u-hidden-md-up">
  u-hidden-md-up
</div>
```
```html
<div class="u-hidden-lg">
  u-hidden-lg
</div>
```
```html
<div class="u-hidden-lg-up">
  u-hidden-lg-up
</div>
```
```html
<div class="u-hidden-xl">
  u-hidden-xl
</div>
```
*/

.#{$_prefix}u-hidden {
  @include _hidden();
}

$sizes-keys: map-keys($_sizes);
$min-key: nth($sizes-keys, 1);
$max-key: nth($sizes-keys, -1);
@each $size, $screen-min in $_sizes {
  @if $size != $min-key and $size != $max-key {
    .#{$_prefix}u-hidden-#{$size}-up {
      @include _media-min($size) {
        @include _hidden();
      }
    }
  }

  .#{$_prefix}u-hidden-#{$size} {
    @include _media-only($size) {
      @include _hidden();
    }
  }
}
