/*
---
name: .c-entries
category:
  - object/component
  - object/component/entries
---
Entries component

### html
```html
<ul class="c-entries">
  <li class="c-entries__item">
    ...
  </li>
</ul>
```
*/

.#{$_prefix}c-entries {
  @include _entries();
}
